<div class="goal" [ngClass]="{'navigable': isNavigable}">
    <div class="goal-body" (click)="goalDetailsClicked()">
        <div class="goal-icon-column {{'goal-background-' + goal.goal2030Id}} {{'goal-outline-' + goal.goal2030Id }} ">
            <img [src]="'/assets/images/home/goals/' + goal.name + '.svg'" alt="*"/>
        </div>
        <div class="goal-description-column">
            <h3>{{goal.name}}</h3>
    
            <p [innerHtml]="goal.target"></p>
            <p>
                <strong>How: </strong>
                {{goal.achievementPlan}}
            </p>
        </div>
        <div class="divider-column">
            <div class="divider"></div>
        </div>
        <div class="goal-metrics-column">
            <div class="goal-metrics-details-column">
                <div class="sub-theme-item" *ngFor="let subTheme of goal.subThemeValues; let j = index">    
                    <div class="header">
                        <div class="sub-theme-name" *ngIf="subTheme.themeSubArea">
                            <strong>{{subTheme.themeSubArea}}</strong>
                        </div>
                        <div class="target">
                            2030 Target: 
                            <strong>
                                {{subTheme.organizationalTargetValueDisplay}} 
                                <span [innerHtml]="subTheme.unitDisplay"></span>
                            </strong>
                        </div>
                    </div>
                    <div class="charts">
                        <div class="donut-chart-panel">
                            
                            <app-goal-donut-chart [goal]="goal" [goalIndex]="goal.goal2030Id" [subThemeIndex]="j">
                            </app-goal-donut-chart>

                            <div class="chart-title">
                                Progress Toward
                                <br />
                                2030 Target
                            </div>
                        </div>

                        <div class="bar-chart-panel">
                            <app-goal-bar-chart [goal]="goal" [subTheme]="subTheme"></app-goal-bar-chart>
                            <div class="chart-title bar-chart-title">
                                Progress by
                                <br />
                                TNC's Role
                            </div>
    
                        </div>
                    </div>        
                </div>    
            </div>
        </div>
    </div>
    <div class="goal-footer">
        <div *ngIf="isNavigable" class="metric-detail-link {{'goal-text-' + goal.goal2030Id }}" (click)="goalDetailsClicked()">
            To {{goal.name}} Metric Details Page
            <mat-icon style="transform: translateY(5px)">trending_flat</mat-icon>
        </div>
    </div>
</div>