import { Component, OnInit, Inject, inject } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { take } from "rxjs";
import { RecordCollectionService } from "../../../../../core/services/record-collection.service";
import { ToastService } from "../../../../../core/services/toast.service";
import { UserDataService } from "../../../../../core/services/user-data.service";
import { RecordCollectionModel } from "../../../../../../hub_schema/hubTypes";
import { AddEditCollectionDialogComponent } from "../../add-edit-collection-dialog/add-edit-collection-dialog.component";

@Component({
    selector: "app-add-project-to-collection-dialog",
    templateUrl: "./add-project-to-collection-dialog.component.html",
    styleUrls: ["./add-project-to-collection-dialog.component.scss"],
})
export class AddProjectToCollectionDialogComponent implements OnInit {
    constructor(
        @Inject(MAT_DIALOG_DATA) private data,
        private dialogRef: MatDialogRef<AddProjectToCollectionDialogComponent>,
        private dialogService: MatDialog,
        private recordCollectionsService: RecordCollectionService,
        private userDataService: UserDataService,
        private toastService: ToastService,
        private router: Router
    ) {}

    public projectId: number;
    public projectName: string;
    public addProjectToCollectionForm: FormGroup;
    public collections: RecordCollectionModel[];

    public ngOnInit(): void {
        this.projectId = this.data.projectId;
        this.projectName = this.data.projectName;

        this.addProjectToCollectionForm = new FormGroup({
            relatedProjectId: new FormControl(this.projectId),
            recordCollectionId: new FormControl(null, [Validators.required, this.collectionHasProjectValidator.bind(this)]),
        });

        const userId = this.userDataService.getUserId();

        this.recordCollectionsService
            .getRecordCollectionsByEmployee(userId)
            .pipe(take(1))
            .subscribe((collections: RecordCollectionModel[]) => {
                this.collections = collections;
            });
    }

    public getValidationErrorMessages(controlName: string): string {
        return "";
    }

    private collectionHasProjectValidator(control: FormControl) {
        if (this.collectionHasProject(control.value)) {
            return { projectAlreadyAdded: true };
        }
        return null;
    }

    public collectionHasProject(collection): boolean {
        const collectionProjectList = collection && collection.projects;
        return (
            collectionProjectList &&
            collectionProjectList.find((recordCollectionProject) => recordCollectionProject.project.relatedProjectId === +this.projectId)
        );
    }

    public openCreateRecordCollectionDialog(): void {
        const dialogRef: MatDialogRef<AddEditCollectionDialogComponent> = this.dialogService.open(AddEditCollectionDialogComponent, {
            data: {
                recordCollection: null,
            },
            height: "83%",
            width: "800px",
        });

        dialogRef
            .afterClosed()
            .pipe(take(1))
            .subscribe((newCollection: RecordCollectionModel) => {
                if (newCollection) {
                    this.collections.push(newCollection);
                    this.addProjectToCollectionForm.controls.recordCollectionId.setValue(newCollection.recordCollectionId);
                    this.save();
                }
            });
    }

    public save(): void {
        this.recordCollectionsService
            .addProjectToRecordCollection(this.addProjectToCollectionForm.value)
            .pipe(take(1))
            .subscribe(() => {
                this.toastService.show("Saved to Collections", "Go to Collections");

                this.toastService.gotoRequested.pipe(take(1)).subscribe(() => {
                    this.toastService.clear();
                    const stateObj = { state: {} };
                    stateObj.state["tab"] = "collections";
                    this.router.navigateByUrl("/user", stateObj);
                });

                this.close();
            });
    }

    public close(): void {
        this.dialogRef.close();
    }
}
