import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-authorization-failed',
    templateUrl: './authorization-failed.component.html',
    styleUrls: ['./authorization-failed.component.scss']
})
export class AuthorizationFailedComponent implements OnInit {
    title = 'Hub - Authorization Failed';
    public env = environment;

    constructor(private titleService: Title) { }

    ngOnInit() {
        this.titleService.setTitle(this.title);
    }
}
