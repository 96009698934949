import { Component, OnInit, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { OutputsEditService } from '../services/outputs-edit.service';
import { getValidationErrorMessages } from '../../../../shared/helpers/getValidationErrorMessages';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ProjectOutputModel, UserModel } from '../../../../../hub_schema/hubTypes';
import { Observable, catchError, debounceTime, of, switchMap, take } from 'rxjs';
import { TypeAheadService } from '../../../../core/services/type-ahead.service';

@Component({
  selector: 'app-create-update-output-dialog',
  templateUrl: './create-update-output-dialog.component.html',
  styleUrls: ['./create-update-output-dialog.component.scss']
})
export class CreateUpdateOutputDialogComponent implements OnInit {
    private outputsEditService: OutputsEditService = inject(OutputsEditService);
    private dialogService: MatDialog = inject(MatDialog);
    private dialogData: any = inject(MAT_DIALOG_DATA);
    private typeAheadService: TypeAheadService = inject(TypeAheadService);

    public outputOwnerTypeahead: FormControl;
    public filteredUsers$: Observable<any>;
    private dialogRef: MatDialogRef<CreateUpdateOutputDialogComponent> = inject(MatDialogRef<CreateUpdateOutputDialogComponent>);
    public existingOutput: ProjectOutputModel;
    private projectId: number;
    public outputForm: FormGroup;
    public isSaving: boolean;

    public getValidationErrorMessages = getValidationErrorMessages;

    public ngOnInit(): void {
        this.existingOutput = this.dialogData.existingOutput;
        this.projectId = this.dialogData.projectId;

        const dueDate =  this.existingOutput?.due ? new Date(this.existingOutput.due + 'T00:00:00') : null;

        this.outputForm = new FormGroup({
            projectOutputId: new FormControl({
                value: this.existingOutput ? this.existingOutput.projectOutputId : undefined,
                disabled: !this.existingOutput
            }),
            projectId: new FormControl(this.projectId),
            statement: new FormControl(this.existingOutput ? this.existingOutput.statement : '', Validators.required),
            lead: new FormControl(this.existingOutput ? this.existingOutput.lead : null, Validators.required),
            due: new FormControl(dueDate),
            isArchived: new FormControl(false)
        });

        this.outputOwnerTypeahead = new FormControl(this.existingOutput ? this.existingOutput.lead : null);

        this.filteredUsers$ = this.outputOwnerTypeahead.valueChanges.pipe(
            debounceTime(200),
            switchMap((term) => this.typeAheadService.getUserTypeAheadData(term)
                .pipe(
                    catchError(() => {
                        return of([]);
                    }),
                )
            ),
            take(25)

        );

    }

    // #region Output Owner Typeahead

    public displayUser(option: UserModel): string | undefined {
        if (option && option.userId) {
            return option.fullName!;
        }

        return undefined;
    }

    public userSelected(e): void {
        this.outputForm.controls.lead.setValue(e.option.value);
        this.outputForm.controls.lead.markAsDirty();
    }

    public cancel(): void {
        this.dialogRef.close();
    }

    public saveOutput(): void {
        this.isSaving = true;
        const output = this.outputForm.value;

        output.leadId = output.lead?.userId;

        if (this.existingOutput) {
            this.outputsEditService.updateOutput(output).pipe(take(1)).subscribe((savedOutput) => {
                this.isSaving = false;
                this.dialogRef.close(savedOutput);
            });
        }
        else {
            this.outputsEditService.createOutput(output).pipe(take(1)).subscribe((savedOutput) => {
                this.isSaving = false;
                this.dialogRef.close(savedOutput);
            });
        }
    }

}
