import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { NotificationsService } from '../../../../core/services/notifications.service';
import { UserDataService } from '../../../../core/services/user-data.service';
import { take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NotificationModel } from '../../../../../hub_schema/hubTypes';

@Component({
    selector: 'app-notifications-dialog',
    templateUrl: './notifications-dialog.component.html',
    styleUrls: ['./notifications-dialog.component.scss'],
})
export class NotificationsDialogComponent implements OnInit {
    public notifications: NotificationModel[];
    
    readonly HubItAdminsPreferredName = 'Hub IT Admins';

    constructor(
        private notificationsService: NotificationsService,
        private userDataService: UserDataService,
        private dialogRef: MatDialogRef<NotificationsDialogComponent>,
        private router: Router
    ) { }

    public ngOnInit(): void {
        this.notificationsService
            .getNotifications()
            .pipe(take(1))
            .subscribe((notifications: NotificationModel[]) => {
                this.notifications = notifications;
                this.notificationsService.markAllNotificationsAsRead().pipe(take(1)).subscribe();

                for (let notification of this.notifications) {
                    (notification.initiatorUser as any).avatarObservable = this.getPicture(notification.initiatorUser.email);
                }
            });
    }

    public getPicture(userEmail: string) {
        return this.userDataService.getUserProfilePicture(userEmail, null);
    }

    public deleteNotification(notification: NotificationModel): void {
        this.notificationsService.deleteNotification(notification).pipe(take(1)).subscribe();
    }

    public gotoNotificationsManagementPage(): void {
        this.dialogRef.close();
        this.router.navigateByUrl('/manage-notifications-and-emails');
    }

    public convertUTCtoLocal(datetime: string) {
        return datetime + 'Z';
    }
}
