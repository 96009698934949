<div class="row mt-2">
    <div class="col-md-9">
        <div class="d-md-flex justify-content-between">
            <h5 class="text-info-800">
                Insight Record Search Results<app-help-selector [selector]="1177"></app-help-selector>
            </h5>
            <div style="display: flex;">
                <div style="margin-right: 10px; margin-top: 6px; font-weight: bold;">
                    {{searchResultStats.getInfo()}} Insight Records
                </div>

                <!-- TODO: turn this into a component (aaron.edwards) -->

                <div class="pagination align-self-end">
                    <div class="page-item">
                        <button (click)="goToPrevPage($event)" [disabled]="prevPageIsDisabled()"
                            class="page-link legitRipple minWidthNextPrev">
                            ← &nbsp; Prev Set
                        </button>
                    </div>
                    <ul class="pagesContainer pagination align-self-end">
                        <li *ngFor="let page of getGroupsArr()" class="page-item"
                            [ngClass]="{active: page === hitPager.currentPageIndex}">
                            <button (click)="goToPage(page, $event)" class="page-link legitRipple">
                                {{page + 1}}
                            </button>
                        </li>
                    </ul>
                    <div class="page-item">
                        <button (click)="goToNextPage($event)" [disabled]="nextPageIsDisabled()"
                            class="page-link legitRipple minWidthNextPrev">
                            Next Set &nbsp; →
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <p class="text-muted" style="margin: 15px 0px;" *ngIf="isLobbyStrategy()">
            This is a Lobbying Strategy Record. An existing P-LOBBYING Insight Record must be selected.
        </p>
        <div class="hub-custom-scrollbar" style="height: calc(100vh - 555px); overflow-y: auto;">
            <ul class="section-list left ml-1 mr-2 sb-t" id="lstInsightSearchResults"
                style="margin-bottom: 0; margin-top: 0;">
                <li class="sb-b" *ngFor="let hit of currentPageOfHits; trackBy: hitsTrackByFunction">
                    <div class="mr-2" style="display: flex; flex-direction: column;">
                        <div class="btn btn-primary rounded-round btn-icon legitRipple mt-1"
                            [ngClass]="{'disabled': hit.isDisabled}">
                            <i class="icon-coins"></i>
                        </div>
                    </div>
                    <div class="media-body">
                        <div class="d-md-flex justify-content-between">
                            <div class="media-title">
                                <span
                                    class="text-primary font-weight-semibold">{{hit.InsightID ? hit.InsightID + ' - ' : '' }}{{hit.Name}}
                                </span>
                                <span *ngIf="hit.RelatedHubRecordId === project.projectId"
                                    class="text-muted" style="margin-left: 10px;">
                                    Already linked to {{project.name}}
                                </span>

                                <span class="d-block">
                                    <span *ngIf="!hit.multipleBus" class="text-muted">Owned by Business Unit:</span>
                                    <span *ngIf="hit.multipleBus" class="text-muted">Owned by Business Units:</span>
                                    {{hit.businessUnitNames}}
                                </span>
                                <span class="text-success d-block font-weight-bold">{{hit.RecordTypeCode}} <span
                                        class="text-dark font-weight-bold">({{hit.InsightStatus}})</span></span>
                            </div>
                            <div class="align-self-center ml-3">
                                <button *ngIf="!hit.isDisabled" (click)="selectInsightRecord(hit)">Select</button>

                                <span class="text-danger" *ngIf="hit.isDisabled">
                                    This record is not available to select as an anchor.
                                </span>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
    <div class="col-md-3 hub-custom-scrollbar" style="overflow-y: auto; height: calc(100vh - 515px);">
        <div class="facet-header">
            <h5 class="text-info-800">
                Search / Filter
                <app-help-selector [selector]="1178"></app-help-selector>
            </h5>
            <button class="hub-clear-filters-button" (click)="clearFilters()">Clear Filters</button>
        </div>
        <div class="pb-2">
            <div class="hub-search-textbox">
                <span class="search-icon">
                    <i class="icon-search4"></i>
                </span>
                <input type="text" id="searchTextInput" [(ngModel)]="algoliaApiViewModel.searchText"
                    placeholder="Free Text Search..." />
                <div [hidden]="!algoliaApiViewModel.searchText" (click)="clearSearchText()" class="clear-icon"
                    title="Reset">X</div>
            </div>
        </div>
        <div *ngFor="let facet of algoliaApiViewModel.visibleFacets; trackBy: algoliaApiViewModel.facetTrackByFunction">

            <mat-expansion-panel [expanded]="facet.isExpanded" (opened)="filterPanelOpened(facet)"
                (closed)="filterPanelClosed(facet)">
                <mat-expansion-panel-header collapsedHeight="48px" expandedHeight="48px">
                    <label class="d-block font-weight-bold font-size-lg">{{facet.title}}</label>
                    <div *ngIf="!facet.isHidden" class="hub-select-clear">
                        <span *ngIf="facet.isSelectAllAvailable()" class="text-size-sm text-info-700 cursor-pointer"
                            (click)="selectAllFacetFilters(facet, $event)">Select All</span>
                        <span *ngIf="facet.areSelectAllAndClearAllAvailable()"
                            class="hub-divider">&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                        <span *ngIf="facet.isClearAllAvailable()" class="text-size-sm text-info-700 cursor-pointer"
                            (click)="clearAllFacetFilters(facet, $event)">Clear All</span>
                    </div>
                </mat-expansion-panel-header>

                <div *ngIf="facet.filterType === 'checkbox' && !facet.isHidden">
                    <div class="hub-search-textbox facet-search" *ngIf="facet.isSearchable">
                        <span class="search-icon">
                            <i class="icon-search4"></i>
                        </span>
                        <input type="text" [(ngModel)]="facet.searchText" [placeholder]="facet.placeholderText" />
                    </div>

                    <div class="hub-facet-filter" *ngFor="let filter of facet.getFiltersSorted()">
                        <div class="hub-facet-filter-content" *ngIf="filter.numberOfHits">
                            <div>
                                <mat-checkbox [(ngModel)]="filter.isChecked" color="primary"
                                    (change)="filterChanged(facet)"></mat-checkbox>
                            </div>
                            <div class="filter-title">
                                {{filter.title}}&nbsp;<span
                                    class="hub-number-of-hits">{{filter.numberOfHits | number}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="hub-show-more" *ngIf="facet.isCondensible">
                        <div *ngIf="facet.isCondensed" class="text-size-sm text-info-700 cursor-pointer"
                            (click)="facetShowMore(facet)">
                            <i class="material-icons">arrow_drop_down</i>
                            <span>Show More</span>
                        </div>
                        <div *ngIf="!facet.isCondensed" class="text-size-sm text-info-700 cursor-pointer"
                            (click)="facetShowLess(facet)">
                            <i class="material-icons">arrow_drop_up</i>
                            <span>Show Less</span>
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>
        </div>
    </div>
</div>

<!-- <app-modal #confirmAnchorRecordSelectionModal [openModalFromOutside]="openConfirmAnchorRecordSelectionModal"
    windowClass="confirm-anchor-record-selection-modal">
    <div class="content">
        <div class="dialog-container">
            <div class="dialog-icon">!</div>

            <div class="dialog-content">
                <div class="dialog-header">
                    <div class="title" style="font-size: 22px;">
                        Confirm Anchor Record Selection
                    </div>
                </div>

                <div class="dialog-body hub-custom-scrollbar">
                    <p *ngIf="isFinanceContactMismatched">
                        Selected Insight Finance Contact does not match the Hub Finance Contact.
                    </p>
                    <p *ngIf="isBusinessUnitMismatched">
                        Selected Insight Business Unit does not match the Lead Business Unit of the Hub Record.
                    </p>
                </div>

                <div class="dialog-footer">
                    <button (click)="dismissAnchorSelectionConfirmationModal()" type="button"
                        class="btn cancel-button mr-1">
                        Cancel
                    </button>
                    <button class="btn btn-labeled legitRipple" (click)="processAnchorSelectionConfirmation()">
                        Confirm
                    </button>
                </div>
            </div>
        </div>
    </div>

</app-modal> -->
