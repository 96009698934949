import { Component, Inject, OnInit, inject } from '@angular/core';
import { BusinessRoleModel, TeamMemberModel, UserBusinessRoleModel, UserModel } from '../../../../../../hub_schema/hubTypes';
import { AbstractControl, FormArray, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TeamLovService } from '../services/team-lov.service';
import { Observable, Subscription, catchError, debounceTime, filter, forkJoin, map, of, startWith, switchMap, take, tap } from 'rxjs';
import { HubRecordEditorBase } from '../../../_hub_record_editor_base';
import { TypeAheadService } from '../../../../../core/services/type-ahead.service';

@Component({
  selector: 'app-team-member-dialog',
  templateUrl: './team-member-dialog.component.html',
  styleUrls: ['./team-member-dialog.component.scss']
})
export class TeamMemberDialogComponent extends HubRecordEditorBase implements OnInit {

    // #region Dependencies

    private teamLovService: TeamLovService = inject(TeamLovService);
    private typeAheadService: TypeAheadService = inject(TypeAheadService);
    private dialogRef: MatDialogRef<TeamMemberDialogComponent> = inject(MatDialogRef<TeamMemberDialogComponent>);
    private data: any = inject(MAT_DIALOG_DATA);

    // #endregion Dependencies

    public teamMember: TeamMemberModel;
    public editTeamMemberForm: FormGroup;
    public financeContacts: UserModel[];
    public financeContactUserIds: number[];
    public businessRoles: BusinessRoleModel[];
    public roleWarningText: string;
    public hasAnchorRecord: boolean;

    public get selectableBusinessRoles(): FormArray {
        return this.editTeamMemberForm.controls.selectedBusinessRoles as FormArray;
    }

    public ngOnInit(): void {
        forkJoin({
            businessRoles: this.teamLovService.getBusinessRoles(),
            financeContacts: this.teamLovService.getAllFinanceContacts()
        })
        .pipe(take(1)).subscribe((results: any) => {
            this.businessRoles = results.businessRoles;
            this.financeContacts = results.financeContacts;
            this.financeContactUserIds = this.financeContacts.map(fc => fc.userId!);
            this.teamMember = this.data.teamMember;

            this.editTeamMemberForm = new FormGroup({
                projectTeamId: new FormControl(this.teamMember ? this.teamMember.projectTeamId : undefined),
                projectId: new FormControl(this.project.projectId),
                employee: new FormControl(this.teamMember ? this.teamMember.user : undefined, Validators.required),
                selectedBusinessRoles: new FormArray<any>([], this.isSelectedBusinessRolesValid)
            });

            this.businessRoles.forEach((businessRole: BusinessRoleModel) => {
                const existingUserBusinessRole = this.teamMember?.userBusinessRoles.find(ubr => ubr.businessRole!.businessRoleId === businessRole.businessRoleId);

                const roleGroup = new FormGroup({
                    isSelected: new FormControl({
                        value: existingUserBusinessRole ? true : false,
                        disabled: businessRole.name === 'Finance Contact' && this.teamMember && !this.financeContactUserIds.includes(this.teamMember.userId)
                    }),
                    businessRole: new FormControl(businessRole),
                    userBusinessRoleId: new FormControl<number | undefined>({
                        value: existingUserBusinessRole ? existingUserBusinessRole.userBusinessRoleId : undefined,
                        disabled: false,
                    })
                });

                (this.editTeamMemberForm.controls.selectedBusinessRoles as FormArray).push(roleGroup);
            });

            this.hasAnchorRecord = this.project.insightRecords!.some(ar => ar.anchorRecordFlag);
            this.setupUserTypeahead();
        });
    }

    // #region Roles

    public isRoleDisabled(selectableRole: any): boolean {
        const selectedUser = this.editTeamMemberForm.controls.employee.value;

        if (!selectedUser) {
            return false;
        }

        if (selectableRole.businessRole.name === 'Finance Contact') {
            if (!this.financeContactUserIds || !this.financeContactUserIds.length) {
                return true;
            }

            if (!this.financeContactUserIds.includes(selectedUser.userId)) {
                return true;
            }
        }

        return false;
    }

    public getRoleTooltip(selectableRole: any): string {
        if (this.isRoleDisabled(selectableRole)) {
            const selectedEmployee = this.editTeamMemberForm.controls.employee.value;
            const isFinanceContact: boolean = this.financeContactUserIds.includes(selectedEmployee.userId);

            if (selectableRole.businessRole.name === 'Finance Contact' && !isFinanceContact) {
                return 'User ' + selectedEmployee.fullName + ' is not an approved Finance Contact.';
            }
            else {
                return '';
            }
        }
        return '';
    }

    private isSelectedBusinessRolesValid(rolesControl: AbstractControl) {
        const selectedRoles = rolesControl.value.filter(r => r.isSelected);

        if (!selectedRoles.length) {
            return { required: 'At least one role is required.' }
        }
        return null;
    }

    public getRolesValidationMessage(): string {
        if (!this.editTeamMemberForm.controls.selectedBusinessRoles.touched) {
            return '';
        }
        if (!this.editTeamMemberForm.controls.selectedBusinessRoles.valid) {
            return 'At least 1 Business Role is required.'
        }
        return '';
    }

    public getRoleWarningText(selectableRole: any): string {
        if (!selectableRole.isSelected) {
            return '';
        }

        if (!selectableRole.businessRole.isSingleUserPerProject) {
            return '';
        }

        const existingRoleMember: TeamMemberModel | null = this.getExistingTeamMemberForRole(selectableRole.businessRole);

        if (existingRoleMember && (!this.teamMember || existingRoleMember?.userId !== this.teamMember.userId)) {
            return `There is a maximum of 1 team member for this role. ${existingRoleMember.user!.fullName} will be replaced.`;
        }

        return '';
    }

    // #endregion Roles

    // #region Users

    public userTypeahead: FormControl = new FormControl('');
    public filteredUsers$: Observable<UserModel[]>;

    private setupUserTypeahead(): void {
        this.filteredUsers$ = this.userTypeahead.valueChanges.pipe(
            debounceTime(200),
            switchMap((term) => this.typeAheadService.getUserTypeAheadData(term)
                .pipe(
                    catchError(() => of([])), // empty list on error
                )
            ),
            take(25)
        );
    }

    public get selectedUser(): UserModel {
        return this.editTeamMemberForm.controls.employee.value;
    }

    public displayUser(option?: any): string {
        if (option && option.userId) {
            return option.fullName;
        }
        return '';
    }

    public isUserDisabled(user: UserModel): boolean {
        const teamMemberIds = this.project.team!.map(tm => tm.userId);

        if (teamMemberIds.includes(user.userId!)) {
            return true;
        }

        const financeContactRole = this.selectableBusinessRoles.value.find(sbr => sbr.businessRole.businessRoleId === 1001);
        const financeContactIds = this.financeContacts.map(fc => fc.userId);

        if (financeContactRole.isSelected && !financeContactIds.includes(user.userId)) {
            return true;
        }

        return false;
    }

    public user_clicked(e): void {
        this.selectUser(e.option.value);
    }

    private selectUser(user: UserModel): void {
        this.editTeamMemberForm.controls.employee.setValue(user);
        const financeContactRoleGroup = this.selectableBusinessRoles.controls.find(fg => fg.value.businessRole.name === 'Finance Contact');
        const isFinanceContactRoleSelectedControl = (financeContactRoleGroup as FormGroup).controls.isSelected;

        // enable/disable finance contact
        if (this.financeContactUserIds.includes(user.userId!)) {
            isFinanceContactRoleSelectedControl.enable();
        }
        else {
            isFinanceContactRoleSelectedControl.disable();
        }

        this.editTeamMemberForm.controls.employee.markAsDirty();
    }

    public getEmployeeValidationMessage(): string {
        if (!this.userTypeahead.touched) {
            return '';
        }
        if (!this.editTeamMemberForm.controls.employee.value) {
            return 'Required';
        }
        return '';
    }

    public isLead(): boolean {
        return this.editTeamMemberForm.controls.selectedBusinessRoles.value.some(subr => subr.isSelected && subr.businessRole.name === 'Lead');
    }

    private getExistingTeamMemberForRole(role: BusinessRoleModel): TeamMemberModel | null {
        const rolesTeamMembers = this.getTeamMembersByRole(role.businessRoleId);

        if (rolesTeamMembers.length) {
            return rolesTeamMembers[0];
        }

        return null;
    }

    public getTeamMembersByRole(roleId: number): TeamMemberModel[] {
        return this.project.team!
            .filter((i: TeamMemberModel) =>
                i.userBusinessRoles.map(ubr => ubr.businessRole!.businessRoleId).includes(roleId)
            );
    }

    // #endregion Users

    public cancel(): void {
        this.dialogRef.close();
    }

    public submitTeamMember(): void {
        const teamMemberFromForm: any = this.editTeamMemberForm.value;
        const teamMemberModel: TeamMemberModel = {
            projectId: teamMemberFromForm.projectId,
            userId: teamMemberFromForm.employee.userId,
            user: teamMemberFromForm.employee,
            userBusinessRoles: teamMemberFromForm.selectedBusinessRoles
                .filter(subr => subr.isSelected)
                .map((subr) => {
                    return {
                        userBusinessRoleId: subr.userBusinessRoleId,
                        businessRoleId: subr.businessRole.businessRoleId,
                        businessRole: subr.businessRole
                    };
                })
        };
        this.dialogRef.close(teamMemberModel);
    }
}
