import { EventEmitter, Injectable, inject } from '@angular/core';
import { HttpService } from '../../core/services/http.service';
import { Observable, of, tap } from 'rxjs';
import { ProjectModel, StatusModel } from '../../../hub_schema/hubTypes';
import { environment } from '../../../environments/environment';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';

enum projectStatus {
    active = 1000,
    completed = 1002,
    proposed = 1003,
    canceled = 1005
}

@Injectable({
    providedIn: 'root'
})
export class HubRecordEditService {
    private httpService: HttpService = inject(HttpService);
    public projectUpdated: EventEmitter<ProjectModel> = new EventEmitter<ProjectModel>();

    // #region Shared Data Properties

    public project: ProjectModel;
    public projectEditForm: FormGroup | undefined;
    public isProjectNameValid: boolean = true;

    // #endregion

    // #region Validation

    private get statusId(): number {
        return this.projectEditForm!.controls.statusId.value;
    }

    private requiresFullValidation(): boolean {
        return this.statusId === projectStatus.active || this.statusId === projectStatus.completed;
    }

    public get isSummaryValid(): boolean {
        return this.projectEditForm!.controls.name.valid
            && this.projectEditForm!.controls.briefDescription.valid
            && this.projectEditForm!.controls.localName.valid;
    }

    public get isLocationValid(): boolean {
        if (this.requiresFullValidation()) {
            return this.projectEditForm!.controls.countries.value.length;
        }
        return true;
    }

    public get isTeamValid(): boolean {
        if (this.requiresFullValidation()) {
            // team lead and finance contact are required
            const team = this.projectEditForm?.controls.team.value;

            const hasLead = team.some(tm => tm.userBusinessRoles.some(ubr => ubr.businessRole.name === 'Lead'));
            const hasFinanceContact = team.some(tm => tm.userBusinessRoles.some(ubr => ubr.businessRole.name === 'Finance Contact'));
            return hasLead && hasFinanceContact;
        }
        return true;
    }

    public isFinancialEditPanelValid: boolean = true;

    public get isFinancialsValid(): boolean {
        return this.isFinancialEditPanelValid && this.projectEditForm!.controls.financialNotes.valid;
    }

    public get isProjectValid(): boolean {
        return this.isSummaryValid && this.isLocationValid && this.isFinancialsValid && this.isTeamValid;
    }

    public get isProjectPristine(): boolean {
        return this.projectEditForm!.pristine;
    }

    // #endregion Validation

    public getProject(projectId: number): Observable<ProjectModel> {
        const url = environment.endpoints.base + '/projects/' + projectId + '/true';

        return this.httpService.get<ProjectModel>(url).pipe(tap((project: ProjectModel) => {
            this.project = project;

            if (!this.projectEditForm) {
                this.projectEditForm = new FormGroup({
                    projectId: new FormControl(0),
                    projectTypeId: new FormControl(0),
                    statusId: new FormControl(0, Validators.required),
                    name: new FormControl(''),
                    briefDescription: new FormControl('', Validators.required),
                    localName: new FormControl('', Validators.maxLength(240)),
                    goal: new FormControl(''),
                    isLobbyingRecord: new FormControl(false),
                    startDate: new FormControl<Date | null>(null),
                    completionDate: new FormControl(null),
                    completionDateActual: new FormControl(null),
                    longitude: new FormControl(null),
                    latitude: new FormControl(null),
                    projectBusinessUnits: new FormControl([]),
                    countries: new FormControl([]),
                    states: new FormControl([]),
                    projectSpatialExtents: new FormControl([]),
                    relations: new FormControl([]),
                    backwardRelations: new FormControl([]),
                    relationsNotes: new FormControl(''),
                    team: new FormControl([]),
                    projectPartners: new FormControl([]),
                    financialNotes: new FormControl(''),
                    projectFinancials: new FormControl([]),
                    projectAnchorRecordWorkflow: new FormControl(null),
                    projectDonorKeywords: new FormControl([]),
                    projectLevers: new FormControl([])
                });

                // hack: API dates come in as strings
                this.project.startDate = new Date(this.project.startDate + 'T00:00:00');
                this.project.completionDate = this.project.completionDate ? new Date(this.project.completionDate + 'T00:00:00') : undefined;
                this.project.completionDateActual = this.project.completionDateActual ? new Date(this.project.completionDateActual + 'T00:00:00') : undefined;
                this.projectEditForm.patchValue(project);
            }
        }));
    }

    public updateProject(): Observable<ProjectModel> {
        const project = this.projectEditForm?.value as ProjectModel;
        const url = environment.endpoints.base + '/projects/true';

        return this.httpService.patch<ProjectModel>(url, project).pipe(tap((savedProject: ProjectModel) => {
            savedProject.startDate = new Date(savedProject.startDate + 'T00:00:00');
            savedProject.completionDate = new Date(savedProject.completionDate + 'T00:00:00');
            savedProject.completionDateActual = new Date(savedProject.completionDateActual + 'T00:00:00');
            this.project = savedProject;

            this.projectEditForm!.patchValue(savedProject);
            this.projectUpdated.emit(savedProject);
            this.projectEditForm?.markAsPristine();
        }));

    }

    public deleteProject(): Observable<boolean> {
        const url = environment.endpoints.base + '/projects/' + this.projectEditForm.value.projectId;
        return this.httpService.delete(url);
    }
}
