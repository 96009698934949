import { AlgoliaApiViewModel } from '../../shared/types/algolia-api/algolia-api-view-model';
import { Facet } from '../../shared/types/algolia-api/facet';
import { Filter } from '../../shared/types/algolia-api/filter';
import { Breadcrumb } from '../../shared/types/algolia-api/breadcrumb';
import { Outcome2030GoalsMetricFacet } from './outcome-metric-facet';

export class PortfolioViewModel extends AlgoliaApiViewModel {
    public get basicFacets(): Facet[] {
        return this.facets.filter((f) => f.type === 'basic');
    }

    public get advancedFacets(): Facet[] {
        return this.facets.filter((f) => f.type === 'advanced');
    }

    public stringify(): string {
        const activeFacets = this.getFacetsWithFilters();
        const facets = activeFacets.map((facet) => {

            return {
                key: facet.key,
                activeFilters: facet.getActiveFilters().map((filter) => {
                    return {
                        title: filter.title,
                        value: filter.isActive
                    };
                }),
            };
        });

        const result = {
            searchText: this.searchText,
            facets,
        };
        return JSON.stringify(result);
    }

    // maintain a separate list of active filters, rather than the one in the view model, so we can show them in the order they are added.
    public breadcrumbs: Breadcrumb[] = [];

    public addBreadcrumb(breadcrumb: Breadcrumb) {
        this.breadcrumbs.push(breadcrumb);
    }

    public removeBreadcrumb(breadcrumb: Breadcrumb) {
        const existingBreadcrumb = this.breadcrumbs.find(bc => bc.facet.title === breadcrumb.facet.title && bc.filter.title === breadcrumb.filter.title);
        var index = this.breadcrumbs.findIndex(bc => bc === existingBreadcrumb);

        if (index >= 0) {
            this.breadcrumbs.splice(index, 1);
        }
    }

    public removeAllBreadcrumbsForFacet(facet: Facet) {
        const breadcrumbsToRemove = [];

        for (let filter of facet.filters) {
            const existingBreadcrumb = this.breadcrumbs.find(bc => bc.facet === facet && bc.filter === filter);

            if (existingBreadcrumb) {
                breadcrumbsToRemove.push(existingBreadcrumb);
            }
        }

        this.breadcrumbs = this.breadcrumbs.filter(bc => !breadcrumbsToRemove.includes(bc));
    }

    public override clearAllFilters() {
        super.clearAllFilters();
        this.breadcrumbs = [];
    }

    public override loadSavedFilter(savedFilterConfig): void {
        super.loadSavedFilter(savedFilterConfig);
        this.breadcrumbs = [];

        for (let facet of savedFilterConfig.facets) {
            const existingFacet = this.facets.find((f) => f.key === facet.key);

            for (let filter of facet.activeFilters) {
                const existingFilter = existingFacet.filters.find(f => f.title === filter.title);

                if (existingFacet instanceof Outcome2030GoalsMetricFacet) {
                    (existingFilter as any).name = existingFilter.title.split(' > ')[1];
                }

                const breadcrumb = new Breadcrumb(existingFacet, existingFilter);
                this.addBreadcrumb(breadcrumb);
            }
        }
    }

}
