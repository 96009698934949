import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-goal-bar-chart',
  templateUrl: './goal-bar-chart.component.html',
  styleUrls: ['./goal-bar-chart.component.scss']
})
export class GoalBarChartComponent {

    @Input()
    public goal: any;

    @Input()
    public subTheme: any;

    public getTncContributionHeight(percent: number) {
        if (percent < 2) {
            return 2;
        }
        return percent;
    }

    public getTncContributionText(contribution: number): string {
        if (contribution < 1) {
            return '<1%';
        }
        return contribution.toFixed(0) + '%';
    }

}
