import { Component, OnInit, inject } from '@angular/core';
import { HubRecordEditorBase } from '../_hub_record_editor_base';
import { OutcomeDetailsModel, OutcomeProgressModel, OutcomeTargetModel, ProjectOutcomeModel } from '../../../../hub_schema/hubTypes';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { take } from 'rxjs';
import { OutcomesEditService } from './services/outcomes-edit.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DeleteConfirmationDialogComponent } from '../../../shared/components/delete-confirmation-dialog/delete-confirmation-dialog.component';
import { ErrorService } from '../../../core/services/error.service';
import { CreateOutcomeDialogComponent } from './create-outcome-dialog/create-outcome-dialog.component';
import { EditOutcomeDialogComponent } from './edit-outcome-dialog/edit-outcome-dialog.component';

@Component({
  selector: 'app-outcomes-edit-container',
  templateUrl: './outcomes-edit-container.component.html',
  styleUrls: ['./outcomes-edit-container.component.scss']
})
export class OutcomesEditContainerComponent extends HubRecordEditorBase implements OnInit {
    private outcomesEditService: OutcomesEditService = inject(OutcomesEditService);
    private errorService: ErrorService = inject(ErrorService);
    private dialogService: MatDialog = inject(MatDialog);

    public outcomes: ProjectOutcomeModel[];
    public customOutcomes: ProjectOutcomeModel[] ;
    public selectedProjectOutcome: ProjectOutcomeModel | undefined;

    public ngOnInit(): void {
        this.initialize();
    }

    private resetProjectOutcomeLists(): void {
        this.outcomes = this.project.projectOutcomes!.filter(po => po.outcome);
        this.customOutcomes = this.project.projectOutcomes!.filter(po => po.customOutcome);
    }

    private initialize(): void {
        this.resetProjectOutcomeLists();
        this.selectFirstProjectOutcome();
    }

    private selectFirstProjectOutcome() {
        if (this.outcomes.length) {
            this.selectProjectOutcome(this.outcomes[0]);
        }
        else if (this.customOutcomes.length) {
            this.selectProjectOutcome(this.customOutcomes[0]);
        }
        else {
            this.selectedProjectOutcome = undefined;
        }
    }

    public selectProjectOutcome(projectOutcome: ProjectOutcomeModel): void {
        this.selectedProjectOutcome = projectOutcome;

        // selecting a projectOutcome will fetch its details if they're not already present
        // null means not fetched yet, empty means fetched, but none present
        if (this.selectedProjectOutcome.targets == null || this.selectedProjectOutcome.progress == null) {
            this.outcomesEditService.getProjectOutcomeDetails(projectOutcome.projectOutcomeId!)
                .pipe(take(1)).subscribe((outcomeDetails: OutcomeDetailsModel) => {
                    // hack: API dates come back as stings. We convert them here
                    outcomeDetails.targets.forEach((target: OutcomeTargetModel) => {
                        target.targetDate = new Date(target.targetDate + 'T00:00:00');
                    });
                    outcomeDetails.progress.forEach((progress: OutcomeProgressModel) => {
                        progress.progressDate = new Date(progress.progressDate + 'T00:00:00');
                    });

                    this.selectedProjectOutcome!.targets = outcomeDetails.targets;
                    this.selectedProjectOutcome!.progress = outcomeDetails.progress;
                });
        }
    }

    // #region Create/Edid/Delete

    public openCreateOutcomeDialog(): void {
        const dialogRef = this.dialogService.open(CreateOutcomeDialogComponent, {
            width: '800px',
            data: {
                isStrategy: this.isStrategy,
                projectId: this.project.projectId
            }
        });

        dialogRef.afterClosed().pipe(take(1)).subscribe((newProjectOutcome: ProjectOutcomeModel) => {
            if (newProjectOutcome) {
                this.project.projectOutcomes?.push(newProjectOutcome);
                this.resetProjectOutcomeLists();
                this.selectProjectOutcome(newProjectOutcome);
            }
        });
    }

    public openEditOutcomeDialog(projectOutcome: ProjectOutcomeModel): void {
        const dialogRef = this.dialogService.open(EditOutcomeDialogComponent, {
            width: '800px',
            data: {
                projectOutcome: projectOutcome,
                isStrategy: this.isStrategy
            }
        });

        dialogRef.afterClosed().pipe(take(1)).subscribe((savedProjectOutcome) => {
            if (savedProjectOutcome) {
                const indexToReplace = this.project.projectOutcomes!.findIndex(po => po.projectOutcomeId === savedProjectOutcome.projectOutcomeId)!;
                this.project.projectOutcomes![indexToReplace] = savedProjectOutcome;
                this.resetProjectOutcomeLists();
                this.selectProjectOutcome(savedProjectOutcome);
            }
        });
    }

    public deleteProjectOutcomeRequested(projectOutcome: ProjectOutcomeModel): void {
        const dialogRef: MatDialogRef<DeleteConfirmationDialogComponent> = this.dialogService.open(DeleteConfirmationDialogComponent, {
            data: {
                warningMessage: 'You will not be able to undo deleting this outcome.'
            }
        });

        const deleteConfirmationDialog: DeleteConfirmationDialogComponent = dialogRef.componentInstance;

        deleteConfirmationDialog.actionConfirmed.pipe(take(1))
            .subscribe(() => {
                deleteConfirmationDialog.isBusy = true;

                this.outcomesEditService.deleteProjectOutcome(projectOutcome).pipe(take(1))
                    .subscribe({
                        next: () => {
                            const indexToRemove = this.project.projectOutcomes!.findIndex(po => po.projectOutcomeId ===  projectOutcome.projectOutcomeId);
                            this.project.projectOutcomes!.splice(indexToRemove, 1);
                            this.initialize();
                            dialogRef.close();
                        },
                        error: (err) => {
                            this.errorService.addError(err, true);
                            dialogRef.close();
                        }
                    });
            });
    }

    // #endregion Create/Edid/Delete

    // #region Reordering

    public outcomeReordered(event: CdkDragDrop<ProjectOutcomeModel>): void {
        moveItemInArray(this.outcomes, event.previousIndex, event.currentIndex);
        this.persistOutcomesSortOrder();
    }

    public customOutcomeReordered(event: CdkDragDrop<ProjectOutcomeModel>): void {
        moveItemInArray(this.customOutcomes, event.previousIndex, event.currentIndex);
        this.persistOutcomesSortOrder();
    }

    private persistOutcomesSortOrder(): void {
        const orderedIds = [...this.outcomes, ...this.customOutcomes]
            .map((r) => r.projectOutcomeId!);

        this.outcomesEditService.updateOutcomeOrder(this.project.projectId, orderedIds).pipe(take(1))
            .subscribe({
                next: (successful: boolean) => {
                    this.project.projectOutcomes = [...this.outcomes, ...this.customOutcomes];
                },
                error: (err) => {
                    this.errorService.addError(err, true);
                }
            });
    }

    // #endregion Reordering

}
