export enum GoalColors {
    'goal_1000' = '#FF004D',
    'goal_1001' = '#381300',
    'goal_1002' = '#01002A',
    'goal_1003' = '#49A942',
    'goal_1004' = '#01C1E3',
    'goal_1006' = '#FFC700'
}

export enum GoalSecondaryColors {
    'goal_1000' = '#AB92CA',
    'goal_1001' = '#F8CFA9',
    'goal_1002' = '#B8DCED',
    'goal_1003' = '#C3E0C3',
    'goal_1004' = '#C1DBE7',
    'goal_1006' = '#F0C3DE'
}
