<div class="outcome" *ngIf="projectOutcome">
    <div class="row">
        <div class="col-md-12">
            <div class="number">
                <span class="btn hub-round-button rounded-round btn-icon btn-sm legitRipple mr-3">
                    <span class="letter-icon">{{indexOffset + outcomeIndex}}</span>
                </span>
            </div>
            <div class="title">
                <h5 class="non-sca-outcome-metric">
                    <span class="font-weight-bold">
                        Outcome -
                    </span> {{projectOutcome.customOutcome.indicatorName}} <span class="text-muted font-size-sm">({{projectOutcome.customOutcome.unit}})</span>
                </h5>
            </div>
        </div>
    </div>
    <div class="row mb-2">
        <div class="col-md-7">

            <div *ngIf="projectOutcome.isPolicyLeverageOutcome" class="ml-5 mt-1">
                <span class="font-weight-bold" style="color: #018899;">Leverage Outcome: </span>
                <span class="text-muted">Policy</span>
            </div>


            <p class="card-description ml-5">{{projectOutcome.statement}}</p>

            <div *ngIf="projectOutcome.customOutcome.parentcustomOutcome">
                <div class="text-indigo-300 ml-5"><i class="align-baseline mr-1 icon-tree7"></i>
                    <span class="font-weight-bold">Linked to: </span>{{projectOutcome.customOutcome.parentcustomOutcomeProjectName}}
                </div>
            </div>
        </div>

        <div class="col-md-5 pr-4">
            <div *ngIf="projectOutcome.outcomeStatistics.hasTargets || projectOutcome.outcomeStatistics.hasProgress"
                class="d-flex flex-nowrap mt-2 pr-4"
                style="justify-content: flex-start;">

                <mat-checkbox [(ngModel)]="areDetailsShowing" color="primary">Show Details</mat-checkbox>
            </div>

            <!-- PROGRESS BAR -->
            <div *ngIf="projectOutcome.outcomeStatistics.hasTargets">

                <div class="progress mt-1 mb-2" style="height: 0.5rem;">
                    <div class="progress-bar bg-success"
                        [style.width.%]="projectOutcome.outcomeStatistics.totalProgressPercent * 100">
                    </div>
                </div>

                <div *ngIf="projectOutcome.outcomeStatistics.lastTargetValue !== 0">
                    <span class="font-weight-bold">
                        {{projectOutcome.outcomeStatistics.totalProgressPercent | percent}}</span> achieved towards target of
                    <span class="font-weight-bold">{{projectOutcome.outcomeStatistics.lastTargetValue | number: '0.0-0'}}
                        {{projectOutcome.customOutcome.unit}}</span>
                </div>

            </div>

            <div style="margin-top: 10px;" [innerHtml]="getCurrentPeriodProgressInfo(projectOutcome)">
            </div>

        </div>
    </div>

    <!-- OUTCOME TABLE -->
    <!--Metric Values-->
    <div class="row outcomeTarget pl-5 pr-3" *ngIf="areDetailsShowing" #detailsPanel>
        <div class="col-md-12" *ngIf="!detailsLoading">
            <h5 class="text-success">Outcome Start, Progress and Target Values</h5>
            <div class="table-responsive mb-2">
                <table class="table table-bordered table-border-dashed metric-table">
                    <thead>
                        <tr class="table-border-double">
                            <th width="20%" class="text-right">Value Type</th>
                            <th width="30%" class="text-right">Date</th>
                            <th class="text-right">Value</th>
                        </tr>
                    </thead>

                    <tbody *ngFor="let target of projectOutcome.targets">
                        <tr>
                            <td class="text-right">
                                <span class="date-label text-success">Target</span>
                            </td>
                            <td class="text-right">
                                <span class="d-block">
                                    {{target.targetDate | date}}
                                </span>
                            </td>
                            <td class="text-right">
                                <span class="value">
                                    {{target.targetValue | number: '1.0-3'}}
                                </span>
                                <span class="unit">
                                    {{projectOutcome.customOutcome.unit}}
                                </span>
                            </td>
                        </tr>
                    </tbody>

                    <tbody *ngFor="let outcomeProgress of cummulativeProgress">

                        <tr *ngIf="!outcomeProgress.isStart">
                            <td class="text-right">
                                <span class="date-label text-dark">Progress</span>
                            </td>
                            <td class="text-right">
                                <span class="d-block">
                                    {{outcomeProgress.progressDate | date}}
                                </span>
                            </td>
                            <td class="text-right">
                                <span class="value">
                                    {{outcomeProgress.progressValue | number: '1.0-3'}}
                                </span>
                                <span class="unit">
                                    {{projectOutcome.customOutcome.unit}}
                                </span>

                                <div *ngIf="isStrategy" class="mt-1">
                                    <a class="badge-primary badge font-size-lg"
                                        [routerLink]="'/portfolio/' + outcomeProgress.childProjectId">{{outcomeProgress.childProjectName}}</a>
                                </div>

                                <div *ngIf="!isStrategy">
                                    <div class="attribution">
                                        <span>{{outcomeProgress.attributionLevel?.name}}</span>
                                    </div>
                                    <div class="attribution-text">
                                        {{outcomeProgress.attributionDescription}}
                                    </div>
                                </div>

                            </td>
                        </tr>

                        <tr *ngIf="outcomeProgress.isStart">
                            <td class="text-right">
                                <span class="date-label text-primary">Start</span>
                            </td>
                            <td class="text-right">
                                <span class="d-block">
                                    {{outcomeProgress.progressDate | date}}
                                </span>
                            </td>
                            <td class="text-right">
                                <span class="value">
                                    {{outcomeProgress.progressValue | number: '1.0-3'}}
                                </span>
                                <span class="unit">
                                    {{projectOutcome.customOutcome.unit}}
                                </span>

                                <div *ngIf="isStrategy" class="mt-1">
                                    <a class="badge-primary badge font-size-lg"
                                        [routerLink]="'/portfolio/' + outcomeProgress.projectId">{{outcomeProgress.projectName}}</a>
                                </div>

                                <div *ngIf="!isStrategy">
                                    <div class="attribution">
                                        <span>{{outcomeProgress.attributionLevel?.name}}</span>
                                    </div>
                                    <div class="attribution-text">
                                        {{outcomeProgress.attributionDescription}}
                                    </div>
                                </div>


                            </td>
                        </tr>

                    </tbody>


                </table>
            </div>
        </div>

        <app-modal-spinner *ngIf="detailsLoading"></app-modal-spinner>
    </div>

</div>
