import { Component, ElementRef, EventEmitter, Input, Output, ViewChild, inject } from '@angular/core';
import { formatNumber } from '@angular/common';
import { take } from 'rxjs/operators';
import { OutcomeLovService } from '../../../../core/services/outcome-lov-service.service';
import { OutcomeProgressAttributeModel, OutcomeProgressAttributeOptionModel, OutcomeProgressModel, OutcomeTargetModel, ProjectOutcomeModel, OutcomeDetailsModel, UnitConversionModel, ChildOutcomeProgressModel } from '../../../../../hub_schema/hubTypes';
import { OutcomeDetailService } from '../services/outcome-detail.service';

@Component({
    selector: 'app-outcome',
    templateUrl: './outcome.component.html',
    styleUrls: ['./outcome.component.scss']
})
export class OutcomeComponent {
    constructor(
        private outcomeDetailService: OutcomeDetailService,
        private outcomeLovService: OutcomeLovService,
    ) { }

    // #region Members

    private outcomeLovTables: any;

    @Input()
    public projectOutcome: ProjectOutcomeModel;

    @Input()
    public isStrategy: boolean;

    @Input()
    public projectId: number;

    @Input()
    public indexOffset: number = 0;

    @ViewChild('detailsPanel')
    public detailsPanel: ElementRef;

    @Input()
    outcomeIndex: number;

    @Output()
    projectDataAreLoading = new EventEmitter<boolean>();

    public cummulativeProgress: OutcomeProgressModel[];
    public unitConversions: UnitConversionModel[];

    // #endregion Members

    // #region outcome details

    public detailsLoading: boolean = false;
    private detailsLoadingTimeout: any;
    private _areDetailsShowing: boolean = false;

    public get areDetailsShowing(): boolean {
        return this._areDetailsShowing;
    }

    public set areDetailsShowing(value: boolean) {
        this._areDetailsShowing = value;

        if (this._areDetailsShowing) {

            // for progress and targets, null means we haven't fetched them yet, empty means we fetched them and there aren't any
            if (this.cummulativeProgress === undefined) {

                // only show busy indicator if service takes more than 100ms to load
                this.detailsLoadingTimeout = setTimeout(() => {
                    this.detailsLoading = true;
                }, 100);

                this.outcomeDetailService.getOutcomeDetails(this.projectOutcome.projectOutcomeId!, this.isStrategy)
                    .pipe(take(1)).subscribe((outcomeDetails: OutcomeDetailsModel) => {
                        this.projectOutcome.targets = outcomeDetails.targets;
                        this.cummulativeProgress = this.isStrategy ? outcomeDetails.childProgress! : outcomeDetails.progress;
                        this.unitConversions = outcomeDetails.unitConversions!;
                        this.detailsLoading = false;
                        clearTimeout(this.detailsLoadingTimeout);
                });

            }

            this.scrollDetailsIntoView();
        }
    }


    private scrollDetailsIntoView(): void {
        setTimeout(() => {
            const container = document.querySelector('#MainContentSection');
            const sectionTop = this.detailsPanel.nativeElement.offsetTop;
            container!.scroll({ top: sectionTop, left: 0, behavior: 'smooth' });
        });
    }

    // #region Outcome Characteristics (Attributes)

    public get isShowOutcomeCharacteristicsVisible(): boolean {
        if (!this.projectOutcome || !this.projectOutcome.progress || !this.projectOutcome.progress.length) {
            return false;
        }

        if (this.projectOutcome.progress[0].outcomeProgressAttributes.length > 0) {
            return true;
        }

        return false;
    }

    public areOutcomeCharacteristicsShowing: boolean = false;

    public getAttributeLabel(attributeListId: number): string {
        return this.outcomeLovTables.data.scaOutcomeLists.find((l) => l.scaOutcomeListId === attributeListId).name;
    }

    public getAttributeValue(outcomeProgressAttribute: OutcomeProgressAttributeModel): string {
        if (outcomeProgressAttribute.userInput != null) {
            return outcomeProgressAttribute.userInput;
        }
        const names = outcomeProgressAttribute.outcomeProgressAttributeOptions.map((o: OutcomeProgressAttributeOptionModel) => o.outcomeListOption.name);
        return names.join(', ');
    }

    // #endregion Outcome Characteristics

    // #endregion outcome details

    // Todo (ACE 2/2/2023): this method exists in multiple places.  Combine them.
    public getCurrentPeriodProgressInfo(outcome: ProjectOutcomeModel): string {
        let result: string;

        if (outcome.outcomeStatistics!.currentPeriodProgress) {
            if (outcome.outcomeStatistics!.lastTargetValue !== 0) {
                const currentPeriodProgressPercent = (outcome.outcomeStatistics!.currentPeriodProgress / outcome.outcomeStatistics!.lastTargetValue) * 100;
                const currentPeriodProgressPercentString = `<b>${formatNumber(currentPeriodProgressPercent, 'en-US', '0.0-0')}%</b> toward target`;
                result = `In the last year, progress of <b>${formatNumber(outcome.outcomeStatistics!.currentPeriodProgress, 'en-US', '0.0-0')} ${outcome.outcome!.outcomeUnit!.name} </b> achieved ${currentPeriodProgressPercentString}`;
            }
            else {
                result = `In the last year, progress of <b>${formatNumber(outcome.outcomeStatistics!.currentPeriodProgress, 'en-US', '0.0-0')} ${outcome.outcome!.outcomeUnit!.name} </b> achieved`;
            }
        }
        else {
            result = 'No Progress Reported in the Last Year';
        }

        return result;
    }

    public doUnitsMatch(parentOutcome: ProjectOutcomeModel, outcomeProgress: ChildOutcomeProgressModel): boolean {
        return parentOutcome.outcome!.outcomeUnitId === outcomeProgress.outcomeUnit!.outcomeUnitId;
    }

    public convertToParentUnit(outcomeProgress: ChildOutcomeProgressModel): number {
        const conversionRecord = this.unitConversions
            .find(c => c.targetUnitId === this.projectOutcome.outcome!.outcomeUnitId
                && c.sourceUnitId === outcomeProgress.outcomeUnit!.outcomeUnitId);

        return outcomeProgress.progressValue * conversionRecord!.conversion;
    }




}
