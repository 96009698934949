import { Component, OnInit, inject } from "@angular/core";
import { Location } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { RecordCollectionService } from "../../../../core/services/record-collection.service";
import { Goal2030Model, RecordCollectionModel } from "../../../../../hub_schema/hubTypes";
import { take } from "rxjs";
import { Title } from "@angular/platform-browser";
import { AuthService } from "../../../../core/services/auth/auth.service";
import { UserDataService } from "../../../../core/services/user-data.service";
import { MatDialog } from "@angular/material/dialog";
import { DeleteConfirmationDialogComponent } from "../../../../shared/components/delete-confirmation-dialog/delete-confirmation-dialog.component";
import { AddEditCollectionDialogComponent } from "../../../../shared/components/record-collections/add-edit-collection-dialog/add-edit-collection-dialog.component";
import { GoalsService } from "../../../../home/services/goals.service";

@Component({
    selector: "app-record-collection-detail",
    templateUrl: "./record-collection-detail.component.html",
    styleUrls: ["./record-collection-detail.component.scss"],
})
export class RecordCollectionDetailComponent implements OnInit {
    // dependencies
    private authService: AuthService = inject(AuthService);
    private userDataService: UserDataService = inject(UserDataService);
    private recordCollectionService: RecordCollectionService = inject(RecordCollectionService);
    private route: ActivatedRoute = inject(ActivatedRoute);
    private location: Location = inject(Location);
    private titleService: Title = inject(Title);
    private dialogService: MatDialog = inject(MatDialog);
    private goalsService: GoalsService = inject(GoalsService);

    private recordCollectionId: number;
    public recordCollection: RecordCollectionModel;
    public goals: Goal2030Model[];

    public ngOnInit(): void {
        this.goalsService
            .get2030Goals()
            .pipe(take(1))
            .subscribe((goals: Goal2030Model[]) => {
                this.goals = goals;
            });
        this.route.params.subscribe(async (params) => {
            this.recordCollectionId = params["id"];

            this.recordCollectionService
                .getRecordCollectionDetail(this.recordCollectionId)
                .pipe(take(1))
                .subscribe((recordCollection: RecordCollectionModel) => {
                    this.recordCollection = recordCollection;
                });
        });
    }

    public canUserEdit(): boolean {
        return this.recordCollection
            ? this.recordCollection.recordCollectionUsers.some((rcu) => {
                  return rcu.userId === this.userDataService.getUserId();
              }) ||
                  this.authService.userIsITAdmin() ||
                  this.authService.userIsAdmin()
            : false;
    }

    public openEditCollectionDialog(): void {
        const dialogRef = this.dialogService.open(AddEditCollectionDialogComponent, {
            width: "800px",
            height: "83%",
            data: {
                existingCollection: this.recordCollection,
            },
        });

        dialogRef
            .afterClosed()
            .pipe(take(1))
            .subscribe((savedCollection: RecordCollectionModel) => {
                if (savedCollection) {
                    this.recordCollection = savedCollection;
                }
            });
    }

    public deleteProjectCollectionRequested(): void {
        const dialogRef = this.dialogService.open(DeleteConfirmationDialogComponent, {
            data: {
                warningMessage: "You will not be able to recover this collection.",
            },
        });

        const deleteConfirmationDialog: DeleteConfirmationDialogComponent = dialogRef.componentInstance;

        deleteConfirmationDialog.actionConfirmed.pipe(take(1)).subscribe(() => {
            deleteConfirmationDialog.isBusy = true;

            this.recordCollectionService
                .deleteRecordCollection(this.recordCollection)
                .pipe(take(1))
                .subscribe(() => {
                    this.location.back();
                    dialogRef.close();
                });
        });
    }

    public recordCollectionProjectDeleted(recordCollectionProjectId): void {
        const indexToRemove = this.recordCollection.recordCollectionProjects!.findIndex((rcp) => rcp.recordCollectionProjectId === recordCollectionProjectId);

        if (indexToRemove >= 0) {
            this.recordCollection.recordCollectionProjects!.splice(indexToRemove, 1);
        }
    }
}
