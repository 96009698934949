import { Component, OnInit, inject, Output, EventEmitter } from '@angular/core';
import { style, animate, transition, trigger, query as q } from '@angular/animations';
import { HubRecordEditorBase } from '../../_hub_record_editor_base';
import { TeamMemberModel, UserBusinessRoleModel } from '../../../../../hub_schema/hubTypes';
import { TeamLovService } from './services/team-lov.service';
import { take } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { TeamMemberDialogComponent } from './team-member-dialog/team-member-dialog.component';
import { AvataredUserModel } from '../../../../../hub_schema/model-extensions/avatared-user-model';
import { UserDataService } from '../../../../core/services/user-data.service';

@Component({
  selector: 'app-team-panel',
  templateUrl: './team-panel.component.html',
  styleUrls: ['./team-panel.component.scss'],
  animations: [
    trigger('list', [
        transition(':enter', [
        ]),
    ]),
    trigger('team-members', [
        transition(':enter', [
            style({ transform: 'scale(0.5)', opacity: 0 }),  // initial
            animate('1s cubic-bezier(.8, -0.6, 0.2, 1.5)',
                style({ transform: 'scale(1)', opacity: 1 }))  // final
        ]),
        transition(':leave', [
            style({ transform: 'scale(1)', opacity: 1, height: '*' }),
            animate('1s cubic-bezier(.8, -0.6, 0.2, 1.5)',
                style({
                    transform: 'scale(0.5)', opacity: 0,
                    height: '0px', margin: '0px'
                }))
        ])
    ])
  ]})
export class TeamPanelComponent extends HubRecordEditorBase implements OnInit {
    private dialogService: MatDialog = inject(MatDialog);
    private userDataService: UserDataService = inject(UserDataService);

    public anyTeamPresent(): boolean {
        return this.projectEditForm?.controls.team.value.length > 0;
    }

    private get team(): TeamMemberModel[] {
        return this.projectEditForm!.controls.team.value;
    }

    public displayTeam: TeamMemberModel[];

    public ngOnInit(): void {
        this.refreshTeam();
    }

    private refreshTeam(): void {
        this.displayTeam = this.team.map((teamMember: TeamMemberModel) => {
            const aUser: AvataredUserModel = teamMember.user! as AvataredUserModel;

            if (!aUser.avatarObservable) {
                aUser.avatarObservable = this.getPicture(aUser.email);
            }

            teamMember.userBusinessRoles = teamMember.userBusinessRoles.sort((ubr1: UserBusinessRoleModel, ubr2: UserBusinessRoleModel) => {
                return ubr1.businessRole!.displayOrder - ubr2.businessRole!.displayOrder;
            });

            return teamMember;
        })
        .sort((t1: TeamMemberModel, t2: TeamMemberModel) => {
            const team1RoleDisplayOrders = t1.userBusinessRoles!.map(ubr => ubr.businessRole!.displayOrder);
            const minBusinessRole1 = Math.min(...team1RoleDisplayOrders);

            const team2RoleDisplayOrders = t2.userBusinessRoles!.map(ubr => ubr.businessRole!.displayOrder);
            const minBusinessRole2 = Math.min(...team2RoleDisplayOrders);

            return minBusinessRole1 - minBusinessRole2;
        });

    }

    public getPicture(userEmail: string) {
        return this.userDataService.getUserProfilePicture(userEmail, null);
    }

    public removeTeamMember(teamMember: TeamMemberModel): void {
        const indexToRemove = this.team.findIndex(t => t === teamMember);

        if (indexToRemove >= 0) {
            this.team.splice(indexToRemove, 1);
        }

        this.projectEditForm!.controls.team.markAsDirty();
        this.refreshTeam();
    }

    public openTeamMemberDialog(existingTeamMember: TeamMemberModel | null): void {
        const dialogRef = this.dialogService.open(TeamMemberDialogComponent, {
            width: '800px',
            data: {
                teamMember: existingTeamMember,
                projectId: this.project.projectId
            }
        });

        dialogRef.afterClosed().pipe(take(1)).subscribe((result: TeamMemberModel) => {
            if (result) {
                result.userBusinessRoles.forEach((ubRole: UserBusinessRoleModel) => {
                    if (ubRole.businessRole!.isSingleUserPerProject) {

                        // remove this role from all other users, if a member gets its last role removed, remove the member
                        const allOtherTeamMembers = this.team.filter((teamMember => teamMember.userId !== result.userId));
                        const teamMembersToRemove: TeamMemberModel[] = [];

                        for (let i = 0; i < allOtherTeamMembers.length; i++) {
                            const teamMember = allOtherTeamMembers[i];
                            const roleIndexToRemove = teamMember.userBusinessRoles.findIndex(ubr => ubr.businessRole?.businessRoleId === ubRole.businessRole!.businessRoleId);

                            if (roleIndexToRemove >= 0) {
                                teamMember.userBusinessRoles.splice(roleIndexToRemove, 1);
                            }

                            if (!teamMember.userBusinessRoles.length) {
                                teamMembersToRemove.push(teamMember);
                            }
                        }

                        for (let teamMember of teamMembersToRemove) {
                            const indexToRemove = this.team.findIndex(tm => tm.userId === teamMember.userId);
                            this.team.splice(indexToRemove, 1);
                        }
                    }
                });

                if (existingTeamMember) {
                    const teamMember = this.team.find(t => t.userId === existingTeamMember.userId)!;
                    teamMember.userBusinessRoles = result.userBusinessRoles;
                }
                else {
                    this.team.push(result);
                }

                this.refreshTeam();
                this.projectEditForm?.controls.team.markAsDirty();
            }
        });
    }

    public trackByTeamMember(index: number, teamMember): number {
        return teamMember.userId;
    }

    public trackByBusinessRole(index: number, userBusinessRole): number {
        return userBusinessRole.businessRoleId;
    }
}
