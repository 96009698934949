import { Component, OnInit, Inject, inject } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { RecordCollectionModel, RecordCollectionTypeModel, RecordCollectionUserModel, UserModel } from "../../../../../hub_schema/hubTypes";
import { RecordCollectionService } from "../../../../core/services/record-collection.service";
import { catchError, debounceTime, map, switchMap, take, tap } from "rxjs/operators";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { HubLovService } from "../../../../core/services/hub-lov.service";
import { Observable, of } from "rxjs";
import { TypeAheadService } from "src/app/core/services/type-ahead.service";
import { UserDataService } from "src/app/core/services/user-data.service";

@Component({
    selector: "app-add-edit-collection-dialog",
    templateUrl: "./add-edit-collection-dialog.component.html",
    styleUrls: ["./add-edit-collection-dialog.component.scss"],
})
export class AddEditCollectionDialogComponent implements OnInit {
    public collectionForm: FormGroup;
    public recordCollectionTypes: RecordCollectionTypeModel[];
    public existingCollection: RecordCollectionModel;
    public isSaving: boolean = false;
    public filteredUsers$: Observable<UserModel[]>;
    public userTypeAhead: FormControl;
    public hasRemovedSelf: boolean = false;
    public existingCollectionCopy: RecordCollectionModel;

    constructor(
        private recordCollectionService: RecordCollectionService,
        private dialogRef: MatDialogRef<AddEditCollectionDialogComponent>,
        private hubLovService: HubLovService,
        private typeAheadService: TypeAheadService = inject(TypeAheadService),
        private userDataService: UserDataService = inject(UserDataService),
        @Inject(MAT_DIALOG_DATA) private data
    ) {}

    public ngOnInit(): void {
        this.hubLovService
            .getAllRecordCollectionTypes()
            .pipe(take(1))
            .subscribe((recordCollectionTypes: RecordCollectionTypeModel[]) => {
                this.recordCollectionTypes = recordCollectionTypes;
            });

        this.existingCollection = this.data.existingCollection || null;

        this.userTypeAhead = new FormControl("");

        this.collectionForm = new FormGroup({
            recordCollectionId: new FormControl({
                value: undefined,
                disabled: !this.existingCollection,
            }),
            recordCollectionTypeId: new FormControl(null, Validators.required),
            name: new FormControl("", [Validators.required, Validators.maxLength(150)]),
            description: new FormControl("", Validators.maxLength(500)),
            isPrivate: new FormControl(false),
            recordCollectionUsers: new FormControl([]),
        });

        if (this.existingCollection) {
            this.existingCollectionCopy = JSON.parse(JSON.stringify(this.existingCollection));
            this.collectionForm.patchValue(this.existingCollectionCopy);
            if (this.existingCollectionCopy.recordCollectionUsers.length > 1) {
                this.collectionForm.controls.isPrivate.disable();
            }

            if (!!this.existingCollection && this.existingCollection.isPrivate) {
                this.userTypeAhead.disable();
            }
        }

        this.setupUserTypeahead();
    }

    public getValidationErrorMessages(formControlName: string): string {
        const formControl = this.collectionForm.get(formControlName)!;

        if (!formControl.touched) {
            return "";
        }

        const formErrors = formControl.errors;

        if (formErrors) {
            const errorMessages: string[] = [];

            for (const key of Object.keys(formErrors)) {
                if (key === "required") {
                    errorMessages.push(key);
                } else if (key === "maxlength") {
                    errorMessages.push(`Max length of ${formErrors[key].requiredLength} characters exceeded.`);
                } else {
                    errorMessages.push(formErrors[key]);
                }
            }

            return errorMessages.join("<br />");
        }
        return "";
    }

    private setupUserTypeahead(): void {
        this.filteredUsers$ = this.userTypeAhead.valueChanges.pipe(
            debounceTime(200),
            switchMap((term) =>
                this.typeAheadService.getUserTypeAheadData(term).pipe(
                    map((users) => {
                        let current = this.collectionForm.controls.recordCollectionUsers.value;
                        let res = users.filter((item) => {
                            return (
                                !current.find((user) => user.userId === item.userId) &&
                                !(!this.existingCollection && this.userDataService.getUserId() === item.userId)
                            );
                        });

                        return res;
                    }),
                    catchError(() => of([])) // empty list on error
                )
            )
        );
    }

    public get isPrivate(): boolean {
        if (this.collectionForm) {
            return this.collectionForm.controls.isPrivate.value;
        }
        return false;
    }

    public enableDisableShared(event: any) {
        if (event.checked) {
            this.userTypeAhead.disable();
        } else {
            this.userTypeAhead.enable();
        }
    }

    public unselectUser(user: UserModel) {
        this.hasRemovedSelf = user.userId === this.userDataService.getUserId() ? true : false;
        let tempUser = this.collectionForm.controls.recordCollectionUsers.value;
        let indexToRemove = (tempUser as Array<any>).findIndex((controlUser) => user.userId === controlUser.userId);
        if (indexToRemove !== -1) {
            (tempUser as Array<any>).splice(indexToRemove, 1);
            this.collectionForm.controls.recordCollectionUsers.setValue(tempUser);
            this.collectionForm.controls.recordCollectionUsers.markAsDirty();
        }
        // M.B. Jan 17th 2024 Handles orphaned collections
        if (
            this.collectionForm.controls.isPrivate.disabled &&
            ((this.collectionForm.controls.recordCollectionUsers.value.length === 0 && !this.existingCollection) ||
                (!!this.existingCollection && this.collectionForm.controls.recordCollectionUsers.value.length === 1))
        ) {
            this.collectionForm.controls.isPrivate.enable();
        }
    }

    public getUserTooltip(user: UserModel): string {
        if (
            this.collectionForm.controls.recordCollectionUsers.value.some((rcu) => {
                return rcu.userId === user.userId;
            })
        ) {
            return "User " + user.fullName + " is already assigned to this role";
        }
        return "";
    }

    public displayUser(option?: any): string {
        if (option && option.userId) {
            return option.fullName;
        }
        return "";
    }

    public user_clicked(e): void {
        this.selectUser(e.option.value);
    }

    private selectUser(user: UserModel): void {
        let newRecordCollectionUser = { userId: user.userId, user: user };

        if (this.existingCollection) {
            newRecordCollectionUser["recordCollectionId"] = this.existingCollection.recordCollectionId;
        } else {
            newRecordCollectionUser["recordCollectionId"] = 0;
        }

        this.collectionForm.controls.recordCollectionUsers.value.push(newRecordCollectionUser);
        this.userTypeAhead.setValue("");

        if (this.collectionForm.controls.isPrivate.enabled) {
            this.collectionForm.controls.isPrivate.disable();
        }
    }

    public save(): void {
        let sendingCollectionForm = JSON.parse(JSON.stringify(this.collectionForm.value));
        this.isSaving = true;
        if (!this.existingCollection) {
            sendingCollectionForm.recordCollectionUsers.push({ userId: this.userDataService.getUserId(), recordCollectionId: 0 });
            this.recordCollectionService
                .createRecordCollection(sendingCollectionForm)
                .pipe(take(1))
                .subscribe((recordCollection: RecordCollectionModel) => {
                    this.isSaving = false;
                    this.dialogRef.close(recordCollection);
                });
        } else {
            this.recordCollectionService
                .updateRecordCollection(this.collectionForm.value)
                .pipe(take(1))
                .subscribe((recordCollection: RecordCollectionModel) => {
                    this.isSaving = false;
                    this.dialogRef.close(recordCollection);
                });
        }
    }

    public close(): void {
        this.dialogRef.close();
    }
}
