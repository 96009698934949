import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { PartnersLovService } from './services/partners-lov.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PartnerCategoryModel, PartnerModel, PartnerRoleModel, ProjectPartnerModel } from '../../../../../../hub_schema/hubTypes';
import { escapeQuotesAndEnters } from '../../../../../shared/helpers/escape';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-partner-dialog',
  templateUrl: './partner-dialog.component.html',
  styleUrls: ['./partner-dialog.component.scss']
})
export class PartnerDialogComponent implements OnInit, OnDestroy {
    // dependencies
    private partnersLovService: PartnersLovService = inject(PartnersLovService);
    private data: any = inject(MAT_DIALOG_DATA);
    private dialogRef: MatDialogRef<PartnerDialogComponent> = inject(MatDialogRef<PartnerDialogComponent>);

    // HubLOVs
    public partners: PartnerModel[];
    public partnerCategories: PartnerCategoryModel[];
    public partnerRoles: PartnerRoleModel[];

    // page properties
    public projectPartner: ProjectPartnerModel;
    private projectId: number;
    public isOrganizationNameTextboxVisible: boolean = true;
    public defaultOrganizationNameLabel: string = 'Organization Name';
    public organizationNameLabel: string = this.defaultOrganizationNameLabel;
    public filteredPartners: PartnerModel[];

    // Form Group
    public editPartnerForm: FormGroup;
    private categoryIdSubscription: Subscription;

    public ngOnInit(): void {

        this.projectPartner = this.data.projectPartner;
        this.projectId = this.data.projectId;

        this.partnersLovService.getPartnerLovs().subscribe((partnerLovs: any) => {
            this.partners = partnerLovs.partners;
            this.partnerCategories = partnerLovs.partnerCategories;
            this.partnerRoles = partnerLovs.partnerRoles;

            this.editPartnerForm = new FormGroup({
                projectPartnerId: new FormControl({
                    value: this.projectPartner ? this.projectPartner.projectPartnerId : undefined,
                    disabled: !this.projectPartner
                }),
                projectId: new FormControl(this.projectId),
                partnerRoleId: new FormControl(this.projectPartner?.partnerRoleId || null, Validators.required),
                partnerCategoryId: new FormControl(this.projectPartner?.partnerCategoryId || null, Validators.required),
                name: new FormControl(this.projectPartner?.name || '', [Validators.required, Validators.maxLength(255)]),
                contactInfo: new FormControl(this.projectPartner?.contactInfo || '', Validators.maxLength(255)),
                email: new FormControl(this.projectPartner?.email || '', {
                    validators: [Validators.maxLength(255), Validators.email],
                    updateOn: 'blur'})
            });

            this.setTextboxOrSelectBoxVisibility(this.projectPartner ? this.projectPartner.partnerCategory.partnerCategoryId : null);

            this.categoryIdSubscription = this.editPartnerForm.controls.partnerCategoryId.valueChanges
                .subscribe((val) => {
                    this.setTextboxOrSelectBoxVisibility(val);
                });
        });
    }

    private setTextboxOrSelectBoxVisibility(categoryId): void {
        const selectedCategory = this.partnerCategories.find((pc) => pc.partnerCategoryId === categoryId);

        if (!selectedCategory) {
            this.organizationNameLabel = this.defaultOrganizationNameLabel;
            this.isOrganizationNameTextboxVisible = true;
            return;
        }

        if (selectedCategory.name.toLowerCase() === 'federal - us') {
            this.organizationNameLabel = 'Federal US Partner';
            this.isOrganizationNameTextboxVisible = false;
            this.filteredPartners = this.partners.filter((p) => p.partnerCategoryId === categoryId);
            this.clearPartnerNameIfNecessary();
        }
        else if (selectedCategory.name.toLowerCase() === 'global institutions') {
            this.organizationNameLabel = 'Global Institution';
            this.isOrganizationNameTextboxVisible = false;
            this.filteredPartners = this.partners.filter(p => p.partnerCategoryId === categoryId);
            this.clearPartnerNameIfNecessary();
        }
        else {
            this.organizationNameLabel = this.defaultOrganizationNameLabel;
            this.isOrganizationNameTextboxVisible = true;
        }
    }

    private clearPartnerNameIfNecessary(): void {
        const currentPartnerName = escapeQuotesAndEnters(this.editPartnerForm.controls.name.value);

        // does currently entered partner exist in the list?
        const currentPartnerExistsInList = this.filteredPartners.some(p => p.name === currentPartnerName);

        if (!currentPartnerExistsInList) {
            this.editPartnerForm.controls.name.patchValue('');
        }

    }

    public get isEmailErrorMessageVisible(): boolean {
        return this.editPartnerForm.controls.email.touched && this.editPartnerForm.controls.email.invalid;
    }

    public getValidationErrorMessages(formControlName: string): string {
        const formControl = this.editPartnerForm.get(formControlName);

        if (!formControl) {
            return '';
        }

        if (!formControl.touched) {
            return '';
        }

        const formErrors = formControl.errors;

        if (formErrors) {
            const errorMessages: string[] = [];

            for (const key of Object.keys(formErrors)) {
                if (key === 'required') {
                    errorMessages.push(key);
                }
                else if (key === 'email') {
                    errorMessages.push('invalid email');
                }
                else {
                    errorMessages.push(formErrors[key]);
                }
            }

            return errorMessages.join('<br />');
        }

        return '';
    }

    public cancel(): void {
        this.dialogRef.close();
    }

    public submitPartner(): void {
        const projectPartner: ProjectPartnerModel = this.editPartnerForm.value;

        // we need to attach the catetory and role for display purposes (before they have saved)
        projectPartner.partnerCategory = this.partnerCategories.find(c => c.partnerCategoryId === projectPartner.partnerCategoryId)!;
        projectPartner.partnerRole = this.partnerRoles.find(r => r.partnerRoleId === projectPartner.partnerRoleId)!;
        this.dialogRef.close(projectPartner);
    }

    public ngOnDestroy(): void {
        if (this.categoryIdSubscription) {
            this.categoryIdSubscription.unsubscribe();
        }
    }

}
