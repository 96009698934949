<div class="create-outcome-wizard-container" *ngIf="outcomeState.isReady">

    <div mat-dialog-title class="hub-modal-header-text" style="margin-bottom: 10px;">
        <h5 class="modal-title text-success">Create a New Outcome</h5>
    </div>

    <mat-dialog-content class="hub-custom-scrollbar">

        <app-hub-stepper #hubStepper linear [formGroup]="outcomeState.outcomeWizardForm" [(selectedIndex)]="currentStepNumber" [ngClass]="{'ncs': isNcsTabShowing}">
            <cdk-step label="Outcome Type">
                <app-outcome-type-tab *ngIf="currentStepNumber === 0"
                    (goals2020OutcomeTypeSelected)="goals2020OutcomeTypeSelected()"
                    (customOutcomeTypeSelected)="customOutcomeTypeSelected()">
                </app-outcome-type-tab>
            </cdk-step>

            <cdk-step label="Outcome Details" [completed]="isOutcomeDetailsStepCompleted()">
                <ng-container *ngIf="currentStepNumber === 1">
                    <app-outcome-details-tnc-goals-panel *ngIf="outcomeState.is2030Goals" [outcomeState]="outcomeState">
                    </app-outcome-details-tnc-goals-panel>

                    <app-outcome-details-custom-panel *ngIf="!outcomeState.is2030Goals" [outcomeState]="outcomeState">
                    </app-outcome-details-custom-panel>

                    <div class="hub-step-action-buttons">
                        <button class="hub-icon-button" matStepperPrevious>
                            <b><i class="icon-arrow-left13 mr-2"></i></b>
                            <span>Previous</span>
                        </button>
                        <button [disabled]="!isOutcomeDetailsStepCompleted()" class="hub-icon-button mirrored" matStepperNext>
                            <span>Next</span>
                            <b><i class="icon-arrow-right14 ml-2"></i></b>
                        </button>
                    </div>
                </ng-container>
            </cdk-step>

            <cdk-step label="Location and <br /> Spatial Extent" *ngIf="isNcsTabShowing" [completed]="isLocationAndSpatialExtentStepCompleted()" >
                <ng-container *ngIf="currentStepNumber === 2">
                    <app-location-and-spatial-extent-panel></app-location-and-spatial-extent-panel>

                    <div class="hub-step-action-buttons">
                        <button class="hub-icon-button" matStepperPrevious>
                            <b><i class="icon-arrow-left13 mr-2"></i></b>
                            <span>Previous</span>
                        </button>
                        <button [disabled]="!isLocationAndSpatialExtentStepCompleted()" class="hub-icon-button mirrored" matStepperNext>
                            <span>Next</span>
                            <b><i class="icon-arrow-right14 ml-2"></i></b>
                        </button>
                    </div>

                </ng-container>

            </cdk-step>

            <cdk-step [label]="isNcsTabShowing ? 'Estimates and <br /> Target Data' : 'Target Data'" [completed]="isTargetStepCompleted()">

                <ng-container *ngIf="isNcsTabShowing ? currentStepNumber === 3 : currentStepNumber === 2">
                    <app-outcome-target-panel
                        [outcomeState]="outcomeState"
                        [ncsExtentControl]="ncsExtentControl"
                        [showSpatialExtent]="false"
                        [showOutcomeStatement]="false"
                        [projectOutcome]="outcomeState.outcomeWizardForm.value"
                        [is2030Goals]="outcomeState.is2030Goals"
                        [editTargetForm]="targetForm"
                        (pendingNcsRecalculationChanged)="pendingNcsRecalculationChanged($event)"
                        >
                    </app-outcome-target-panel>

                    <div class="hub-step-action-buttons">
                        <button class="hub-icon-button" matStepperPrevious>
                            <b><i class="icon-arrow-left13 mr-2"></i></b>
                            <span>Previous</span>
                        </button>

                        <button type="button" class="hub-icon-button SaveButton" [ngClass]="{'saving': isSaving}"
                            [disabled]="isSaveDisabled() || isSaving" (click)="createOutcome()">
                            <b>
                                <i class="icon-floppy-disk"></i>
                                <i class="icon-spinner4"></i>
                            </b>
                            Create Outcome
                        </button>
                    </div>
                </ng-container>

            </cdk-step>
        </app-hub-stepper>


    </mat-dialog-content>

    <div class="hub-modal-footer-text">
        <button (click)="cancel()" type="button" class="cancel-button">Cancel</button>
    </div>

</div>


