import { Component, Inject, OnInit, inject } from '@angular/core';
import { FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { getValidationErrorMessages } from '../../../../../shared/helpers/getValidationErrorMessages';
import { OutputProgressModel, OutputProgressValueModel, OutputUpdateStatusModel } from '../../../../../../hub_schema/hubTypes';
import { OutputsEditService } from '../../services/outputs-edit.service';
import { HubLovService } from '../../../../../core/services/hub-lov.service';
import { forkJoin } from 'rxjs';

interface DateNumber {
    year: number;
    month: number;
    day: number
}

@Component({
  selector: 'app-create-update-output-progress-dialog',
  templateUrl: './create-update-output-progress-dialog.component.html',
  styleUrls: ['./create-update-output-progress-dialog.component.scss']
})
export class CreateUpdateOutputProgressDialogComponent implements OnInit {
    private dialogRef: MatDialogRef<CreateUpdateOutputProgressDialogComponent> = inject(MatDialogRef<CreateUpdateOutputProgressDialogComponent>);
    private dialogData: any = inject(MAT_DIALOG_DATA);
    private outputsEditService: OutputsEditService = inject(OutputsEditService);
    private hubLovService: HubLovService = inject(HubLovService);

    public outputProgressValues: OutputProgressValueModel[];
    public outputUpdateStatuses: OutputUpdateStatusModel[];

    private existingProgressReport: OutputProgressModel;
    private projectOutputId: number;
    public progressForm: FormGroup;
    public isSaving: boolean = false;

    public getValidationErrorMessages = getValidationErrorMessages;

    public ngOnInit(): void {
        forkJoin({
            progressValues: this.hubLovService.getOutputProgressValues(),
            updateStatuses: this.hubLovService.getOutputUpdateStatuses()
        }).pipe(take(1)).subscribe((results: any) => {
            this.outputProgressValues = results.progressValues;
            this.outputUpdateStatuses = results.updateStatuses;
        });

        this.existingProgressReport = this.dialogData.existingProgressReport;
        this.projectOutputId = this.dialogData.projectOutputId;

        this.progressForm = new FormGroup({
            outputProgressId: new FormControl({
                value: this.existingProgressReport ? this.existingProgressReport.outputProgressId : undefined,
                disabled: !this.existingProgressReport
            }),
            projectOutputId: new FormControl(this.projectOutputId),
            progressDate: new FormControl(this.existingProgressReport ? this.existingProgressReport.progressDate : null, [Validators.required, this.validateProgressDate]),
            progressId: new FormControl(this.existingProgressReport ? this.existingProgressReport.progressId : null, Validators.required),
            statusId: new FormControl(this.existingProgressReport ? this.existingProgressReport.status!.outputUpdateStatusId : null, [Validators.required]),
            description: new FormControl(this.existingProgressReport ? this.existingProgressReport.description : null)
        });
    }

    private validateProgressDate(control: FormControl): ValidationErrors | null {
        if (control.pristine) {
            return null;
        }

        const selectedDate: Date = control.value as Date;
        const today = new Date();

        if (selectedDate.getTime() > today.getTime()) {
            return {
                invalid: 'Progress date must be on or before today.'
            };
        }

        return null;
    }

    public cancel():void {
        this.dialogRef.close();
    }

    public saveProgressReport(): void {
        this.isSaving = true;
        const progressReport = this.progressForm.value;

        if (this.existingProgressReport) {
            this.outputsEditService.updateOutputProgress(progressReport).pipe(take(1)).subscribe((savedProgress) => {
                this.isSaving = false;
                this.dialogRef.close(savedProgress);
            });
        }
        else {
            this.outputsEditService.createOutputProgress(progressReport).pipe(take(1)).subscribe((savedProgress) => {
                this.isSaving = false;
                this.dialogRef.close(savedProgress);
            });
        }
    }
}
