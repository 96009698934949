import { Component, Input, OnInit, inject } from '@angular/core';
import { BulkEditService } from '../../../../core/services/bulk-edit.service';
import { EmployeeService } from '../../../../core/services/employee.service';
import { HubLovService } from '../../../../core/services/hub-lov.service';
import { AuthService } from '../../../../core/services/auth/auth.service';
import { TypeAheadService } from '../../../../core/services/type-ahead.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, catchError, debounceTime, distinctUntilChanged, map, of, startWith, switchMap, take, tap } from 'rxjs';
import { BusinessRoleModel, BusinessUnitModel, FinancialContactTypeAheadModel, UserModel } from '../../../../../hub_schema/hubTypes';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DeleteConfirmationDialogComponent } from '../../../../shared/components/delete-confirmation-dialog/delete-confirmation-dialog.component';

@Component({
    selector: 'app-user-bulk-edit-tab',
    templateUrl: './user-bulk-edit-tab.component.html',
    styleUrls: ['./user-bulk-edit-tab.component.scss']
})
export class UserBulkEditTabComponent implements OnInit {
    // dependencies
    private bulkEditService: BulkEditService = inject(BulkEditService);
    private hubLovService: HubLovService = inject(HubLovService);
    private employeeService: EmployeeService = inject(EmployeeService);
    private typeAheadService: TypeAheadService = inject(TypeAheadService);
    private authService: AuthService = inject(AuthService);
    private dialogService: MatDialog = inject(MatDialog);

    public isBusy = false;
    public dataSource = [];
    public displayedColumns = ['date', 'user', 'update'];
    public bulkEditForm: FormGroup;
    public businessUnits: BusinessUnitModel[] = [];
    public filteredBusinessUnits: Observable<BusinessUnitModel[]>;
    public roles: BusinessRoleModel[] = [];
    public rolesQuery: BusinessRoleModel[] = [];
    public businessUnitQuery: BusinessUnitModel[] = [];

    public isDisabled: boolean = true;
    public isFinancialEditSelected: boolean = false;
    public showAuditDetails: boolean = true;
    public showingYourFiltersPanel: boolean = false;
    public editorPermission = '';

    public newFilteredUsers: Observable<UserModel[]>;
    public newUserFilteredFinance: Observable<UserModel[]>;

    @Input()
    public isAdmin: boolean = false;

    public currentFilteredUsers: Observable<UserModel[]>;

    public ngOnInit(): void {
        this.bulkEditForm = new FormGroup({
            currentUserTypeInput: new FormControl(''),
            selectedCurrentUser: new FormControl(''),
            rolesSelect: new FormControl([], Validators.required),
            newUserTypeInput: new FormControl('', Validators.required),
            selectedNewUser: new FormControl('', Validators.required),
            businessUnitSelect: new FormControl([]),
            businessUnitTypeAheadInput: new FormControl(''),
            newUserFinanceTypeInput: new FormControl('', Validators.required)
        });

        this.toggleAuditDetails();

        this.hubLovService.getBusinessUnits().pipe(take(1)).subscribe((result) => {
            this.businessUnitQuery = result;
            this.filterAvailableUserBus();

            this.filteredBusinessUnits = this.bulkEditForm.controls.businessUnitTypeAheadInput.valueChanges.pipe(
                distinctUntilChanged(),
                startWith(''),
                map((value) => this.filterBUs(value)),
            );

        });

        this.hubLovService.getBusinessRoles().pipe(take(1)).subscribe((result) => {
            this.rolesQuery = result;
            this.roles = this.rolesQuery;
        });

        this.editorPermission = this.authService.getEditorPermissions();

        this.currentFilteredUsers = this.bulkEditForm.controls.currentUserTypeInput.valueChanges
            .pipe(
                debounceTime(200),
                distinctUntilChanged(),
                switchMap((term) => {
                    if (term.fullName) {
                        //this means that the user has already been selected, no need to do anything
                        return of([]);
                    }

                    return this.typeAheadService.getBulkEditUserTypeAheadData(term, true, this.editorPermission !== "Administrator" ? this.editorPermission : undefined)
                        .pipe(
                            catchError(() => of([])), // empty list on error
                            tap((result) => {
                                if (!term || result && result.length > 1) {
                                    // they cleared their input and thus must reset roles
                                    this.bulkEditForm.controls.selectedCurrentUser.setValue(null);
                                    this.roles = this.rolesQuery;
                                    this.bulkEditForm.controls.rolesSelect.setValue([]);
                                }

                                return;
                            }),
                        )
                }),
                take(25)
            );

        this.newFilteredUsers = this.bulkEditForm.controls.newUserTypeInput.valueChanges
            .pipe(
                debounceTime(200),
                switchMap((term) => {
                    if (term.fullName) {
                        //this means that the user has already been selected, no need to do anything
                        return of([]);
                    }

                    return this.typeAheadService.getBulkEditUserTypeAheadData(term, false, this.editorPermission !== "Administrator" ? this.editorPermission : undefined)
                        .pipe(
                            catchError(() => of([])), // empty list on error
                            tap((result) => {
                                //Unit tests never hit this code, should be removed M.B. 10/06/22
                                //    if (term.fullName) {
                                //        // this means that a single result came back, and it's stored in term
                                //        return;
                                //    }

                                if (!term || result && result.length > 1) {
                                    // they cleared their input
                                    this.bulkEditForm.controls.selectedNewUser.setValue(null);

                                    // simplifying code
                                    //    return;
                                }
                                return;
                            }),
                        )
                }),
                take(25)
            );

        this.newUserFilteredFinance = this.bulkEditForm.controls.newUserFinanceTypeInput.valueChanges
            .pipe(
                debounceTime(200),
                switchMap((term) => {

                    if (term.fullName) {
                        //this means that the user has already been selected, no need to do anything
                        return of([]);
                    }
                    return this.typeAheadService.getBulkEditFinanceContacts(term, !!this.isAdmin ? { editorPermission: "Administrator" } : this.adminPageEditorLevelFinance()
                    )
                        .pipe(
                            catchError(() => of([])), // empty list on error
                            tap((result) => {
                                //Unit tests never hit this code, should be removed M.B. 11/01/22
                                // if (term.fullName) {
                                //     // this means that a single result came back, and it's stored in term
                                //     return;
                                // }

                                if (!term || result && result.length > 1) {
                                    // they cleared their input
                                    this.bulkEditForm.controls.selectedNewUser.setValue(null);
                                }
                                return;

                            }),
                        )
                }),
                take(25)
            );

    }

    public addBu(e: MatAutocompleteSelectedEvent, bu: HTMLOrSVGElement) {
        this.bulkEditForm.controls.businessUnitSelect.value.push(e.option.value);
        this.bulkEditForm.controls.businessUnitSelect.markAsDirty();
        this.bulkEditForm.controls.businessUnitTypeAheadInput.setValue('');
        bu.blur();
    }

    //  M.B. 08/01/2022 The finance version of the adminPageEditorLevel method should stay.  It needs the business units in the graphql query so this method will remain
    public adminPageEditorLevelFinance(): FinancialContactTypeAheadModel {
        var result;

        if (!this.authService.userIsRegionEditor && !this.authService.userIsDivisionEditor() && this.editorPermission !== "Administrator") {
            result = { editorPermission: this.editorPermission, businessUnitIds: this.authService.getClaimDataValue('BuEditorFor') };
        } else {
            let permissionsStringSplit = this.editorPermission.split(':');
            result = { editorPermission: permissionsStringSplit[0], businessUnitIds: [parseInt(permissionsStringSplit[1])] };
        }

        return result;
    }

    public cancel() {
        this.bulkEditForm.controls.selectedCurrentUser.setValue(null);
        this.bulkEditForm.controls.selectedNewUser.setValue(null);
        this.bulkEditForm.controls.currentUserTypeInput.setValue('');
        this.bulkEditForm.controls.newUserTypeInput.setValue('');
        this.bulkEditForm.controls.newUserFinanceTypeInput.setValue('');
        this.bulkEditForm.controls.businessUnitTypeAheadInput.setValue('');
        this.bulkEditForm.controls.businessUnitSelect.setValue([]);
        this.bulkEditForm.controls.rolesSelect.setValue([]);

        this.bulkEditForm.controls.selectedCurrentUser.markAsUntouched();
        this.bulkEditForm.controls.selectedNewUser.markAsUntouched();
        this.bulkEditForm.controls.currentUserTypeInput.markAsUntouched();
        this.bulkEditForm.controls.newUserTypeInput.markAsUntouched();
        this.bulkEditForm.controls.newUserFinanceTypeInput.markAsUntouched();
        this.bulkEditForm.controls.businessUnitTypeAheadInput.markAsUntouched();
        this.bulkEditForm.controls.businessUnitSelect.markAsUntouched();
        this.bulkEditForm.controls.rolesSelect.markAsUntouched();
    }

    public createMessage(roles: any[], newSelectedUser: any, businessUnit: any[], currentSelectedUser?: any): Observable<string> {
        let message = '';

        return this.getAffectedCounts(roles, newSelectedUser, businessUnit, currentSelectedUser).pipe(take(1), map(result => {

            if (roles.length > 0 && businessUnit.length > 0 &&
                newSelectedUser?.userId && currentSelectedUser?.userId) {
                message = `${newSelectedUser?.preferredName ? newSelectedUser.preferredName : newSelectedUser.fullName} will replace ${currentSelectedUser?.preferredName ? currentSelectedUser.preferredName : currentSelectedUser.fullName} as ${roles.map((roles) => roles.name).join(', ')} on all Active and Proposed Hub Records in ${businessUnit.map((bus) => bus.name).join(', ')}. This will affect ${result} records.`;

            } else if (roles.length > 0 && newSelectedUser?.userId &&
                currentSelectedUser?.userId && businessUnit.length === 0) {
                message = `${newSelectedUser?.preferredName ? newSelectedUser.preferredName : newSelectedUser.fullName} will replace ${currentSelectedUser?.preferredName ? currentSelectedUser.preferredName : currentSelectedUser.fullName} as ${roles.map((roles) => roles.name).join(', ')} on all Active and Proposed Hub Records. This will affect ${result} records.`;

            } else if (roles.length > 0 && newSelectedUser?.userId &&
                !currentSelectedUser?.userId && businessUnit.length > 0) {
                message = `${newSelectedUser?.preferredName ? newSelectedUser.preferredName : newSelectedUser.fullName} will replace ${roles.map((roles) => roles.name).join(', ')} on all Active and Proposed Hub Records in ${businessUnit.map((bus) => bus.name).join(', ')}. This will affect ${result} records.`;
            }

            return message;
        }));
    }

    public currentUserSelected(e: MatAutocompleteSelectedEvent) {
        this.bulkEditForm.controls.selectedCurrentUser.setValue(e.option.value);
        this.bulkEditForm.controls.rolesSelect.setValue([]);
        if (!!this.bulkEditForm.controls.selectedCurrentUser.value?.userId) {
            this.employeeService.getUserBusinessRoles(this.bulkEditForm.controls.selectedCurrentUser.value.userId).subscribe((result) => {
                //remove duplicates and assign to roles
                // const assignedBusinessRoles = result.filter((element, index, self) =>
                //     index === self.findIndex((searchElement) => (
                //         searchElement.businessRoleId === element.businessRoleId
                //     ))
                // );

                const test = result.filter((element, index, self) =>
                    index === self.findIndex((searchElement) => (
                        searchElement.businessRoleId === element.businessRoleId
                    ))
                ).map(ubr => { return this.rolesQuery.find(rq => rq.businessRoleId == ubr.businessRoleId) })
                this.bulkEditForm.controls.rolesSelect.setValue([]);
                this.roles = test;
            });
        }
    }

    public displayBU(businessUnit?: BusinessUnitModel) {
        if (businessUnit && businessUnit.businessUnitId) {
            return businessUnit.name;
        }
        return undefined;
    }

    public displayUser(user?: any) {
        if (user && user.userId) {
            if (user.isActive) {
                return user.fullName;
            } else {
                return user.fullName + " (Inactive)"
            }
        }
        return undefined;
    }

    public filterAvailableUserBus(): void {
        if (this.isAdmin) {
            this.businessUnits = this.businessUnitQuery;
        } else if (this.authService.userIsRegionEditor()) {

            const regionBus = this.businessUnitQuery.filter((bu) => {
                return bu.regionId === parseInt(this.authService.getClaimDataValue('RegionId'));
            })

            this.businessUnits = regionBus;
        } else if (this.authService.userIsDivisionEditor()) {
            const divisionBus = this.businessUnitQuery.filter((bu) => {
                return bu.divisionId === parseInt(this.authService.getClaimDataValue('DivisionId'));
            })
            this.businessUnits = divisionBus;
        } else if (this.authService.userIsBusinessUnitEditor()) {
            const businessUnitBus = this.businessUnitQuery.filter((bu) => {
                return this.authService.getClaimDataValue('BuEditorFor').map((buId) => parseInt(buId)).includes(bu.businessUnitId);
            })
            this.businessUnits = businessUnitBus;
        }
    }

    public filterBUs(value: string | BusinessUnitModel): BusinessUnitModel[] {
        return this.businessUnits.filter((bu) => {
            if (typeof value === "string") {
                return bu.name.toLowerCase().includes(value.toLowerCase())
            }
        }).sort((a, b) => {
            const nameA = a?.name.toUpperCase();
            const nameB = b?.name.toUpperCase();

            if (nameA < nameB) {
                return -1;
            }

            if (nameA > nameB) {
                return 1;
            }

            return 0;
        });
    };

    public getAffectedCounts(roles: BusinessRoleModel[], newSelectedUser: any, businessUnit: BusinessUnitModel[], currentSelectedUser?: UserModel): Observable<number> {
        const businessUnitIds = businessUnit.map(bu => bu.businessUnitId)
        const roleIds = roles.map(r => r.businessRoleId)

        if (!!currentSelectedUser) {
            return this.bulkEditService.GetAffectedProjectCountByUserId(businessUnitIds, roleIds, currentSelectedUser.userId);
        } else {
            return this.bulkEditService.GetAffectedBulkEditProjects(businessUnitIds, roleIds);
        }
    }

    public getAuditLog(): void {
        this.bulkEditService.GetAuditBulkLog().pipe(take(1)).subscribe((result) => {
            let auditData = result.map(record => {
                return {
                    date: new Date(record.actionDate).toLocaleString(),
                    initiatedBy: record.user,
                    message: record.action
                }
            });
            this.dataSource = auditData;
        })
    }

    public getValidationErrorMessages(formControlName: string): string {
        const formControl = this.bulkEditForm.get(formControlName);

        if (formControlName === 'selectedNewUser' && (this.bulkEditForm.controls.newUserFinanceTypeInput.touched || this.bulkEditForm.controls.newUserTypeInput.touched)) {
            this.bulkEditForm.controls.selectedNewUser.markAsTouched();
            if (this.bulkEditForm.controls.selectedNewUser.errors && !this.isFinancialEditSelected) {
                this.bulkEditForm.controls.newUserTypeInput.setErrors(this.bulkEditForm.controls.selectedNewUser.errors)
            }

            if (this.bulkEditForm.controls.selectedNewUser.errors && this.isFinancialEditSelected) {
                this.bulkEditForm.controls.newUserFinanceTypeInput.setErrors(this.bulkEditForm.controls.selectedNewUser.errors)
            }
        }
        if ((formControlName === "selectedNewUser" || formControlName === "currentUserTypeInput") && this.isCurrentAndNewUserTheSame()) {
            return 'The Current User and New User should not be the same';
        }

        const formErrors = formControl.errors;

        if (formControl.touched && !!formErrors) {
            const errorMessages = [];
            for (const key of Object?.keys(formErrors)) {
                let errorMessage = key;
                if (key !== 'required') {
                    errorMessage = formErrors[key];
                }
                errorMessages.push(errorMessage);
            }

            return errorMessages.join('<br />');
        }

        if (!!formErrors || !this.isValidated() || this.isCurrentAndNewUserTheSame()) {
            this.isDisabled = true;
        } else {
            this.isDisabled = false;
        }
        return '';
    }

    public isCurrentAndNewUserTheSame(): boolean {
        const currentSelectedUserFC = this.bulkEditForm.get("selectedCurrentUser");
        const newSelectedUserFC = this.bulkEditForm.get("selectedNewUser");
        if (!newSelectedUserFC.value?.userId || !currentSelectedUserFC.value?.userId) {
            return false;
        };
        return newSelectedUserFC.value.userId === currentSelectedUserFC.value.userId
    }

    public isParticipatingBuDisabled(bu: BusinessUnitModel): boolean {
        const existingParticipatingBUs = this.bulkEditForm.controls.businessUnitSelect.value;
        const existingBU = existingParticipatingBUs.find((exBu) => exBu.businessUnitId === bu.businessUnitId);
        return existingBU != null;
    }

    public isValidated(): boolean {
        let result = false;
        const currentSelectedUserFC = this.bulkEditForm.get("selectedCurrentUser");
        const newSelectedUserFC = this.bulkEditForm.get("selectedNewUser");
        const rolesSelectedFC = this.bulkEditForm.get("rolesSelect");
        const businessUnitSelectFC = this.bulkEditForm.get("businessUnitSelect");
        if (rolesSelectedFC.value.length > 0 && !!newSelectedUserFC.value?.userId && (!!currentSelectedUserFC.value?.userId || businessUnitSelectFC.value.length > 0)) {
            result = true;
        }
        return result;

    }

    public newUserSelected(e: MatAutocompleteSelectedEvent) {
        this.bulkEditForm.controls.selectedNewUser.setValue(e.option.value);
    }

    public onSubmit(e: Event) {
        const currentSelectedUserFC = this.bulkEditForm.get("selectedCurrentUser");
        const newSelectedUserFC = this.bulkEditForm.get("selectedNewUser");
        const rolesSelectedFC = this.bulkEditForm.get("rolesSelect");
        const businessUnitSelectFC = this.bulkEditForm.get("businessUnitSelect");

        this.createMessage(rolesSelectedFC.value, newSelectedUserFC.value, businessUnitSelectFC.value, currentSelectedUserFC.value).pipe(take(1)).subscribe(result => {
            this.isBusy = true;
            const dialogRef: MatDialogRef<DeleteConfirmationDialogComponent> = this.dialogService.open(DeleteConfirmationDialogComponent, {
                data: {
                    warningMessage: result,
                    confirmButtonText: "CONFIRM"
                },
                disableClose: true
            });
            const deleteConfirmationDialog: DeleteConfirmationDialogComponent = dialogRef.componentInstance;

            deleteConfirmationDialog.actionConfirmed.pipe(take(1)).subscribe((res) => {
                dialogRef.close();

                this.bulkEditService.addUpdateUserToBulkEditQueue(
                    {
                        rolesId: rolesSelectedFC.value.map((roles) => roles.businessRoleId).join(','),
                        businessUnitsId: businessUnitSelectFC.value.length > 0 ? businessUnitSelectFC.value.map((bus) => bus.businessUnitId).join(',') : null,
                        newUserId: newSelectedUserFC.value.userId,
                        currentUserId: currentSelectedUserFC.value?.userId ? currentSelectedUserFC.value.userId : null
                    }).pipe(take(1), tap((result) => {
                        if (result === true) {
                            this.isBusy = false;
                            this.cancel();
                        } else {
                            console.error("Failed to up to queue");
                        }
                    }),).subscribe();
            });

            deleteConfirmationDialog.actionCanceled.pipe(take(1)).subscribe(() => {
                this.isBusy = false;
            })
        });
    }

    public rolesSelectChange(e) {
        const selectedRoles = this.bulkEditForm.controls.rolesSelect.value;
        if (selectedRoles.some((role) => { return role.name === "Finance Contact" })) {
            //If Financial Contact is selected, and the isFinancialEditSelected boolean is not false, set it to false and reset the controls, otherwise leave it alone
            if (!this.isFinancialEditSelected) {
                this.isFinancialEditSelected = true;
                //setting the input controls and selected controls to empty/null when Finance Control is selected prevents user from selecting a non-finance editor employee
                this.bulkEditForm.controls.selectedNewUser.setValue(null);
                this.bulkEditForm.controls.newUserTypeInput.setValue('');
                this.bulkEditForm.controls.newUserFinanceTypeInput.setValue('');
            }
        } else {
            //If Financial Contact is selected, and the isFinancialEditSelected boolean is not false, set it to false and reset the controls, otherwise leave it alone
            if (this.isFinancialEditSelected) {
                //setting the input controls and selected controls to empty/null when Finance Control is selected prevents user from selecting a non-finance editor employee
                this.isFinancialEditSelected = false;
                this.bulkEditForm.controls.selectedNewUser.setValue(null);
                this.bulkEditForm.controls.newUserFinanceTypeInput.setValue('');
                this.bulkEditForm.controls.newUserTypeInput.setValue('');
            }
        }
    }

    public toggleAuditDetails(): void {
        this.showAuditDetails = !this.showAuditDetails;

        if (this.showAuditDetails) {
            this.getAuditLog();
        }
    }

    public unselectBu(bu: BusinessUnitModel): void {
        let indexToRemove = (this.bulkEditForm.controls.businessUnitSelect.value as Array<BusinessUnitModel>).findIndex(controlBu => bu.businessUnitId === controlBu.businessUnitId);
        if (indexToRemove !== -1) {
            (this.bulkEditForm.controls.businessUnitSelect.value as Array<BusinessUnitModel>).splice(indexToRemove, 1);
            this.bulkEditForm.controls.businessUnitSelect.markAsDirty();
        }
    }

}
