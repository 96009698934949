<div class="view-section" style="margin-bottom: 0 !important;" *ngIf="team">
    <h3 class="view-section-header">{{ project.projectType.name }} Team <span class="text-muted">({{team?.length}})</span></h3>
    <div class="details pl-0">
        <div class="media-list people">
            <div class="media" *ngFor="let teamMember of team">
                <div class="mr-3">

                    <a *ngIf="(teamMember.user.avatarObservable | async) === null || (teamMember.user.avatarObservable | async) === undefined"
                        [routerLink]="'/user/' + teamMember.user.userId"
                        class="btn btn-success rounded-round btn-icon legitRipple">
                        {{teamMember.user.initials}}
                    </a>

                    <a *ngIf="teamMember.user.avatarObservable | async" [routerLink]="'/user/' + teamMember.user.userId"
                        class="rounded-round btn-icon legitRipple">
                        <img [src]="teamMember.user.avatarObservable | async" class="rounded-circle" alt="image"
                            style="height: 35px; width: 35px" />
                    </a>
                </div>

                <div class="media-body">
                    <div class="media-title font-weight-semibold">
                        <a class="team-member-name" [routerLink]="'/user/' + teamMember.user.userId ">
                            {{teamMember.user.fullName}}
                            <span class="inactive" *ngIf="!teamMember.user.isActive"> (Inactive)</span>
                        </a>
                    </div>

                    <div>{{teamMember.user.businessUnit?.name || teamMember.user.businessUnitName}}</div>
                    <div class="text-muted">{{teamMember.user.office}}</div>

                    <ul>
                        <li *ngFor="let userBusinessRole of teamMember.userBusinessRoles"
                            class="user-business-role"
                            [ngClass]="{
                                'is-lead': userBusinessRole.businessRoleId === 1000,
                                'is-hub-data-coordinator': userBusinessRole.businessRoleId === 1011,
                                'is-poc': userBusinessRole.businessRoleId == 1012
                            }">
                            {{userBusinessRole.businessRole.name}}
                        </li>
                    </ul>
                </div>

                <div class="align-self-center ml-3 pr-1">
                    <div class="list-icons">

                        <div ngbDropdown placement="bottom-right">

                            <a id="dropdownBasic1" ngbDropdownToggle class="caret-0">
                                <i class="icon-menu9"></i>
                            </a>

                            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                                <a href="mailto:{{teamMember.user.email}}" class="dropdown-item"><em
                                        class="icon-envelope"></em>
                                    Send an e-mail</a>
                                <a href="zoommtg://zoom.us/start" class="dropdown-item"><em
                                        class="icon-phone-plus2"></em>
                                    Start a Zoom Session</a>
                                <a [href]="teamService.generateTeamsUri(teamMember.user.email)" class="dropdown-item"
                                    target="chat"><em class="icon-comment"></em>
                                    Start a Teams Chat</a>
                                <a href="https://nam.delve.office.com/?p={{teamMember.user.email}}&v=work"
                                    class="dropdown-item" target="_blank">
                                    <em class="icon-hyperlink"></em>
                                    Visit Profile
                                </a>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
