import { Component, OnInit, inject, Output, EventEmitter } from '@angular/core';
import { OutputDetailsModel, OutputLinkModel, OutputProgressModel, ProjectOutputModel } from '../../../../../hub_schema/hubTypes';
import { HubRecordEditorBase } from '../../_hub_record_editor_base';
import { AuthService } from '../../../../core/services/auth/auth.service';
import { OutputsEditService } from '../services/outputs-edit.service';
import { take } from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DeleteConfirmationDialogComponent } from '../../../../shared/components/delete-confirmation-dialog/delete-confirmation-dialog.component';
import { CreateUpdateOutputDialogComponent } from '../create-update-output-dialog/create-update-output-dialog.component';
import { OutputDetailService } from '../../../../core/services/output-detail.service';

export enum tabs {
    active = 1,
    archived
}

@Component({
  selector: 'app-output-list',
  templateUrl: './output-list.component.html',
  styleUrls: ['./output-list.component.scss']
})
export class OutputListComponent extends HubRecordEditorBase implements OnInit {
    private authService: AuthService = inject(AuthService);
    private outputDetailService: OutputDetailService = inject(OutputDetailService);
    private outputsEditService: OutputsEditService = inject(OutputsEditService);
    private dialogService: MatDialog = inject(MatDialog);

    public activeOutputs: ProjectOutputModel[];
    public archivedOutputs: ProjectOutputModel[];
    public selectedOutput: ProjectOutputModel | undefined;
    public outputSelected: EventEmitter<ProjectOutputModel> = new EventEmitter<ProjectOutputModel>();

    public tabs = tabs;
    public activeTab: tabs = tabs.active;

    public setTabTo(tab: tabs, e: Event): void {
        this.activeTab = tab;
        this.selectTopOutput();
        e.preventDefault();
    }

    public ngOnInit(): void {
        this.populateOutputLists();
        this.selectTopOutput();
    }

    private populateOutputLists(): void {
        this.activeOutputs = this.project.outputs?.filter(o => !o.isArchived) || [];
        this.archivedOutputs = this.project.outputs?.filter(o => o.isArchived) || [];
    }

    private selectTopOutput(): void {
        if (this.activeTab === tabs.active) {
            if (this.activeOutputs.length) {
                this.selectOutput(this.activeOutputs[0]);
            }
            else {
                this.selectedOutput = undefined;
            }
        }
        else {
            if (this.archivedOutputs.length) {
                this.selectOutput(this.archivedOutputs[0]);
            }
            else {
                this.selectedOutput = undefined;
            }
        }
    }

    public selectOutput(output: ProjectOutputModel) {
        this.selectedOutput = output;

        // selecting a project Output will fetch its details if they're not already present
        // null means not fetched yet, empty means fetched, but none present
        if (this.selectedOutput.outputLinks == null || this.selectedOutput.outputProgress == null) {
            this.outputDetailService.getProjectOutputDetails(output.projectOutputId!)
                .pipe(take(1)).subscribe((outputDetails: OutputDetailsModel) => {
                    // hack: API dates come back as stings. We convert them here
                    outputDetails.progress.forEach((progress: OutputProgressModel) => {
                        progress.progressDate = new Date(progress.progressDate + 'T00:00:00');
                    });

                    this.selectedOutput!.outputLinks = outputDetails.links;
                    this.selectedOutput!.outputProgress = outputDetails.progress;
                });
        }

    }

    public isAllowedToArchive(): boolean {
        const project = this.project;
        this.authService.userIsBusinessUnitEditorFor(project) || this.authService.userIsDivisionEditorFor(project) || this.authService.userIsRegionEditorFor(project) || this.authService.userIsAdmin() || this.authService.userIsITAdmin() || this.authService.userIsFinanceAdmin() || this.authService.isAllowedToEditAsRecordLead(project);
        return false;
    }

    public archiveOutputRequested(output: ProjectOutputModel) {
        const dialogRef: MatDialogRef<DeleteConfirmationDialogComponent> = this.dialogService.open(DeleteConfirmationDialogComponent, {
            data: {
                confirmButtonText: "Continue",
                warningMessage: 'Moving an output to the archive folder cannot be undone.\n Do you want to continue?',
            }
        });

        const deleteConfirmationDialog: DeleteConfirmationDialogComponent = dialogRef.componentInstance;

        deleteConfirmationDialog.actionConfirmed
            .pipe(take(1)).subscribe(() => {
                deleteConfirmationDialog.isBusy = true;
                output.isArchived = true;
                this.outputsEditService.updateOutput(output)
                    .subscribe({
                        next: () => {
                            this.populateOutputLists();
                            this.selectTopOutput();
                            dialogRef.close();
                        },
                        error: (err) => {
                            dialogRef.close();
                        }
                    });
            });

    }

    public deleteOutputRequested(output: ProjectOutputModel): void {
        const dialogRef = this.dialogService.open(DeleteConfirmationDialogComponent, {
            data: {
                warningMessage: 'You will not be able to undo this action.'
            }
        });

        const deleteConfirmationDialog: DeleteConfirmationDialogComponent = dialogRef.componentInstance;

        deleteConfirmationDialog.actionConfirmed
            .pipe(take(1)).subscribe(() => {
                deleteConfirmationDialog.isBusy = true;

                this.outputsEditService.deleteOutput(output.projectOutputId!).pipe(take(1)).subscribe(() => {
                    this.project.outputs = this.project.outputs?.filter(po => po.projectOutputId !== output.projectOutputId);
                    this.populateOutputLists();
                    dialogRef.close();
                });
            });
    }

    public openCreateUpdateOutputDialog(output: ProjectOutputModel | null) {
        const dialogRef = this.dialogService.open(CreateUpdateOutputDialogComponent, {
            width: '800px',
            data: {
                existingOutput: output,
                projectId: this.project.projectId
            }
        });

        dialogRef.afterClosed().pipe(take(1)).subscribe((savedOutput: ProjectOutputModel) => {
            if (savedOutput) {
                if (!output) {
                    this.project.outputs?.push(savedOutput);
                    this.populateOutputLists();
                }
                else {
                    // replace in project
                    let index = this.project.outputs!.findIndex(o => o.projectOutputId === output.projectOutputId);
                    this.project.outputs![index] = savedOutput;

                    // then in filtered list
                    if (!output.isArchived) {
                        index = this.activeOutputs.findIndex(o => o.projectOutputId === output.projectOutputId);
                        this.activeOutputs[index] = savedOutput;
                    }
                    else {
                        index = this.archivedOutputs.findIndex(o => o.projectOutputId === output.projectOutputId);
                        this.archivedOutputs[index] = savedOutput;
                    }
                }
                this.selectOutput(savedOutput);
            }
        });
    }
}
