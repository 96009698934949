<div class="hub-home-container hub-custom-scrollbar">

    <img class="goals-header" src="assets/images/home/2030Goals.png">

    <div class="home-blurb">
        <p>
            The Nature Conservancy is committed to our 2030 goals to address biodiversity loss and climate change. 
            Learn more about our targets below, and our progress toward them. 
        </p>
        <p>
            Click on a goal to view additional metric details. Learn more about TNC's Role <a href="https://tnc.box.com/s/g30iw1vx9vz8m8xw02gn0rx29uze593y" target="_blank">here</a>.
        </p>
    </div>

    <div class="goals-panel" *ngIf="goals">

        <app-goal-display *ngFor="let goal of goals" [goal]="goal" (gotoGoalDetailsRequested)="gotoGoalDetails($event)">
        </app-goal-display>

    </div>

    <div class="more-about-goals-panel">
        <h2>Learn More about 2030 Goals</h2>

        <div class="links">
            <a class="accesibility-focus" target="_blank"
                href="https://thenatureconservancy462.sharepoint.com/sites/Conservation/SitePages/Shared-Conservation-Agenda-&-2030-Goals.aspx">
                <h3>2030 Goals on CONNECT</h3>
            </a>
            <a class="accesibility-focus" target="_blank"
                href="https://tnc.app.box.com/s/s74n8d6p6rixj2iv1n4i7olmzavu76wa">
                <h3>Metric Guidance</h3>
            </a>
            <a class="accesibility-focus" target="_blank"
                href="https://thenatureconservancy462.sharepoint.com/:w:/r/sites/Conservation/_layouts/15/Doc.aspx?sourcedoc=%7BFBE1F431-3196-4FB3-AF87-E0E7D67641C6%7D&file=2030%20Goals%20FAQ.docx&action=default&mobileredirect=true&cid=43a3e063-aff8-4876-9138-e6bbcf3f03c9">
                <h3>2030 Goals FAQ</h3>
            </a>
        </div>
    </div>

    <div class="hub-actions-panel">
        <div class="hub-action">
            <div class="top-part">
                <h3>Dashboards</h3>

                <img src="/assets/images/home/dashboards.jpg" alt="View Dashboards">

                <p>
                    Conservation leaders at all levels
                    can make more informed decisions based on evidence and data
                </p>

            </div>

            <div class="button-panel button-focus">
                <button [routerLink]="'/dashboards/'" class="accesibility-focus">
                    Access Dashboards
                </button>
            </div>

        </div>

        <div class="hub-action">
            <div class="top-part">
                <h3>Search & Explore</h3>
                <img src="/assets/images/home/search.jpg" alt="search and explore">
                <p>
                    Staff can find opportunities for
                    collaboration and better mobilize support for our work
                </p>
            </div>
            <div class="button-panel button-focus">
                <button [routerLink]="'/portfolio/'" class="button accesibility-focus">
                    Explore the Portfolio
                </button>
            </div>
        </div>

        <div class="hub-action">
            <div class="top-part">
                <h3>Create a Hub Record</h3>
                <img src="/assets/images/home/create.jpg" alt="Create New Record">
                <p>
                    Teams can easily create and
                    maintain critical conservation data that simplifies reporting and info sharing processes
                </p>
            </div>
            <div class="button-panel button-focus">
                <button (click)="openCreateRecordDialog($event)" class="button accesibility-focus">
                    Create a Hub Record
                </button>
            </div>
        </div>
    </div>
</div>
