<div class="collection-dialog-container" *ngIf="collectionForm">
    <div class="hub-modal-header-text">
        <h5 *ngIf="!existingCollection" class="modal-title text-success">Create a new Collection</h5>
        <h5 *ngIf="existingCollection" class="modal-title text-success">Edit Collection</h5>
    </div>

    <div class="content">
        <form [formGroup]="collectionForm">
            <div class="row mb-2">
                <div class="col-md-12">
                    <span class="select-label text-info-800 d-block">Type<app-help-selector [selector]="1155">
                        </app-help-selector></span>

                    <div class="hub-select-container">
                        <mat-form-field>
                            <mat-select placeholder="Select a Collection Type" disableOptionCentering
                                formControlName="recordCollectionTypeId">
                                <mat-option *ngFor="let ct of recordCollectionTypes"
                                    [value]="ct.recordCollectionTypeId">
                                    {{ct.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="hub-validation-error-message">
                        {{getValidationErrorMessages("recordCollectionTypeId")}}
                    </div>
                </div>
            </div>

            <div class="row mb-2">
                <div class="col-md-12">
                    <span class="select-label text-info-800 d-block">Name<app-help-selector [selector]="1156">
                        </app-help-selector></span>
                    <input type="text" class="form-control mb-2" formControlName="name" placeholder="Collection Name">
                    <div class="hub-validation-error-message">
                        {{getValidationErrorMessages("name")}}
                    </div>
                </div>
            </div>

            <div class="row mb-2">
                <div class="col-md-12">
                    <span class="select-label text-info-800 d-block">Description<app-help-selector [selector]="1157">
                        </app-help-selector></span>
                    <textarea rows="3" cols="3" class="form-control mb-2"
                        placeholder='Enter a description for your Collection' formControlName="description">
                    </textarea>
                    <div class="hub-validation-error-message">
                        {{getValidationErrorMessages("description")}}
                    </div>
                </div>
            </div>

            <div class="row mb-2">
                <div class="col-md-12">
                    <span class="select-label text-info-800 d-block">Privacy<app-help-selector [selector]="1158">
                        </app-help-selector></span>
                    <div>
                        <mat-checkbox color="primary" id="isPrivate" formControlName="isPrivate"
                            (change)="enableDisableShared($event)">Private Collection</mat-checkbox>
                    </div>
                </div>
            </div>
            <div class="row mb-2">
                <div class="col-md-12">
                    <span class="select-label text-info-800 d-block">Sharing<app-help-selector [selector]="1212">
                        </app-help-selector></span>
                    <input type="text" matInput class="form-control mb-2" [formControl]="userTypeAhead"
                        placeholder="Type and select a name to share" [matAutocomplete]="auto">
                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayUser"
                        (optionSelected)="user_clicked($event)">
                        <mat-option *ngFor="let user of filteredUsers$ | async" [value]="user">
                            {{user.fullName}}
                        </mat-option>
                    </mat-autocomplete>
                </div>
            </div>
            <div class="row mb-2">
                <div class="col-md-12">
                    <span class="select-label text-info-800 d-block">Shared With</span>
                    <div class="dark-grey-text shared-with-padding "
                        *ngIf="(collectionForm.controls.recordCollectionUsers.value.length < 2 && !!existingCollection) || (collectionForm.controls.recordCollectionUsers.value.length === 0 && !existingCollection)">
                        This collection has
                        not been shared yet
                    </div>
                    <div class="shared-with-padding  tokenfield form-control hub-custom-scrollbar height-20vh">
                        <div class="height-100">
                            <div class="token" *ngFor="let rcu of collectionForm.controls.recordCollectionUsers.value">
                                <span class="token-label">
                                    {{rcu.user.firstName}} {{rcu.user.lastName}} {{rcu.user.userId ===
                                    userDataService.getUserId() ? '(Self)' : ''}} {{rcu.user.isActive === false ?
                                    '(Inactive)' : ''}}</span>
                                <span class="token-label">
                                    {{rcu.user.businessUnit?.name}}
                                </span>
                                <span class="token-label">
                                    {{rcu.user.office}}
                                </span>
                                <span
                                    *ngIf="collectionForm.controls.recordCollectionUsers.value.length > 1 || !existingCollection"
                                    class="close" (click)="unselectUser(rcu.user)" aria-hidden="true">×</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>

    <div class="footer">
        <button (click)="close()" class="cancel-button">Cancel</button>

        <button type="button" class="hub-icon-button SaveButton" [ngClass]="{'saving': isSaving}"
            [disabled]="collectionForm.invalid || isSaving" (click)="save()">
            <b>
                <i class="icon-floppy-disk"></i>
                <i class="icon-spinner4"></i>
            </b>
            {{existingCollection ? 'Save' : 'Add'}}
        </button>

    </div>

</div>
