import { Component, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { take } from 'rxjs/operators';
import { CustomOutcomeModel, OutcomeDetailsModel, OutcomeProgressModel, ProjectOutcomeModel } from '../../../../../hub_schema/hubTypes';
import { OutcomeDetailService } from '../services/outcome-detail.service';
import { formatNumber } from '@angular/common';

@Component({
    selector: 'app-custom-outcome',
    templateUrl: './custom-outcome.component.html',
    styleUrls: ['./custom-outcome.component.scss']
})
export class CustomOutcomeComponent {

    constructor(
        private outcomeDetailService: OutcomeDetailService
    ) { }

    // #region Members

    @Input()
    public projectOutcome: ProjectOutcomeModel;

    @Input()
    public projectId: number;

    @Input()
    public isStrategy: boolean;

    @Input()
    public indexOffset: number = 0;

    @Input()
    public outcomeIndex: number;

    @ViewChild('detailsPanel')
    public detailsPanel: ElementRef;

    @Output()
    projectDataAreLoading = new EventEmitter<boolean>();

    public cummulativeProgress: OutcomeProgressModel[];

    // #endregion Members

    // #region Details

    public detailsLoading: boolean = false;
    private detailsLoadingTimeout: any;

    private _areDetailsShowing: boolean = false;

    public get areDetailsShowing(): boolean {
        return this._areDetailsShowing;
    }

    public set areDetailsShowing(value: boolean) {
        this._areDetailsShowing = value;

        if (this._areDetailsShowing) {

            // for progress and targets, null means we haven't fetched them yet, empty means we fetched them and there aren't any
            if (this.cummulativeProgress === undefined) {

                // only show busy indicator if service takes more than 100ms to load
                this.detailsLoadingTimeout = setTimeout(() => {
                    this.detailsLoading = true;
                }, 100);

                this.outcomeDetailService.getOutcomeDetails(this.projectOutcome.projectOutcomeId!, this.isStrategy)
                    .pipe(take(1)).subscribe((outcomeDetails: OutcomeDetailsModel) => {
                        this.projectOutcome.targets = outcomeDetails.targets;
                        this.cummulativeProgress = this.isStrategy ? outcomeDetails.childProgress! : outcomeDetails.progress;
                        this.detailsLoading = false;
                        clearTimeout(this.detailsLoadingTimeout);
                });
            }

            this.scrollDetailsIntoView();
        }
    }

    private scrollDetailsIntoView(): void {
        setTimeout(() => {
            const container = document.querySelector("#MainContentSection");
            const sectionTop = this.detailsPanel.nativeElement.offsetTop;
            container!.scroll({ top: sectionTop, left: 0, behavior: 'smooth' });
        });
    }

    // #endregion Details

    // Todo (ACE 2/2/2023): this method exists in multiple places.  Combine them.
    public getCurrentPeriodProgressInfo(projectOutcome: ProjectOutcomeModel): string {
        let result: string;

        if (projectOutcome.outcomeStatistics!.currentPeriodProgress) {
            if (projectOutcome.outcomeStatistics!.lastTargetValue) {
                const currentPeriodProgressPercent = (projectOutcome.outcomeStatistics!.currentPeriodProgress / projectOutcome.outcomeStatistics!.lastTargetValue) * 100;
                const currentPeriodProgressPercentString = `<b>${formatNumber(currentPeriodProgressPercent, 'en-US', '0.0-0')}%</b> toward target.`;
                result = `In the last year, progress of <b>${formatNumber(projectOutcome.outcomeStatistics!.currentPeriodProgress, 'en-US', '0.0-0')} ${projectOutcome.customOutcome!.unit} </b> achieved ${currentPeriodProgressPercentString}`;
            }
            else {
                result = `In the last year, progress of <b>${formatNumber(projectOutcome.outcomeStatistics!.currentPeriodProgress, 'en-US', '0.0-0')} ${projectOutcome.customOutcome!.unit} </b> achieved`;
            }
        }
        else {
            result = 'No Progress Reported in the Last Year';
        }

        return result;
    }

}
