import { Component, ElementRef, HostListener, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, finalize, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { AlgoliaApiService } from '../../core/services/algolia-api.service';
import { AlgoliaKeyService } from '../../core/services/algolia-key.service';
import { AlgoliaAuthorizationService } from '../../core/services/auth/authorization/algolia-authorization.service';
import { EmployeeService } from '../../core/services/employee.service';
import { LocalStorageService } from '../../core/services/local-storage.service';
import { UserDataService } from '../../core/services/user-data.service';
import { MapContainerComponent } from '../../shared/components/map-container/map-container.component';
import { Facet } from '../../shared/types/algolia-api/facet';
import { RadioButtonFilter } from '../../shared/types/algolia-api/filter-radio-button';
import { HitPager } from '../../shared/types/algolia-api/hit-pager';
import { LastModifiedDateFacet } from '../../shared/types/algolia-api/last-modified-date-facet';
import { SearchResultStatistics } from '../../shared/types/algolia-api/search-result-statistics';
import { PortfolioService } from '../services/portfolio.service';
import { Outcome2030GoalsMetricFacet } from '../types/outcome-metric-facet';
import { PortfolioViewModel } from '../types/portfolio-view-model';
import { ViewModeKeys, ViewModes } from '../types/view-modes';
import { SaveFiltersDialogComponent } from './save-filters-dialog/save-filters-dialog.component';
import { Filter } from '../../shared/types/algolia-api/filter';
import { CheckboxFilter } from '../../shared/types/algolia-api/filter-checkbox';
import { Breadcrumb } from '../../shared/types/algolia-api/breadcrumb';
import { UsageService } from '../../core/services/usage.service';
import { BusinessUnitModel, OutcomeMetricModel, UserModel, UserPortfolioFilterModel } from '../../../hub_schema/hubTypes';
import { ErrorService } from '../../core/services/error.service';

enum filterTabs {
    basic = 1,
    advanced
}

@Component({
    selector: 'app-portfolio',
    styleUrls: ['./portfolio.component.scss'],
    templateUrl: './portfolio.component.html'
})

export class PortfolioComponent implements OnInit, OnDestroy {
    private titleService: Title = inject(Title);
    private route: ActivatedRoute = inject(ActivatedRoute);
    private router: Router = inject(Router);
    private usageService: UsageService = inject(UsageService);
    private portfolioService: PortfolioService = inject(PortfolioService);
    private algoliaApiService: AlgoliaApiService = inject(AlgoliaApiService);
    private algoliaKeyService: AlgoliaKeyService = inject(AlgoliaKeyService);
    private userDataService: UserDataService = inject(UserDataService);
    private employeeService: EmployeeService = inject(EmployeeService);
    private algoliaAuthorizationService: AlgoliaAuthorizationService = inject(AlgoliaAuthorizationService);
    private dialogService: MatDialog = inject(MatDialog);
    private errorService: ErrorService = inject(ErrorService);

    public filterTabs = filterTabs;
    public activeTab: filterTabs = filterTabs.basic;

    public setTabTo(tab: filterTabs, e): void {
        this.activeTab = tab;
        e.preventDefault();
    }

    // #region Search Spinner

    public isAlgoliaSearching: boolean = true;
    private searchInitiatedSubscription: Subscription;
    private searchCompletedSubscription: Subscription;

    // #endregion Search Spinner

    // #region View Settings

    public ViewModes = ViewModes;
    public viewMode: ViewModes = ViewModes.cards;
    public ViewModeKeys = ViewModeKeys;
    public areViewButtonsDisabled = false;
    public showCardDetails = true;
    public showFinanceDetails = true;
    public showFilterDetails = true;
    public showListView = true;

    // #endregion View Settings

    // #region Search Properties

    public portfolioViewModel: PortfolioViewModel;
    public searchResultStats: SearchResultStatistics = new SearchResultStatistics();

    @ViewChild('searchTextInput') searchTextInputLink;
    public searchTextInput: ElementRef;

    private typeAheadSubscription: Subscription;

    // #endregion Search Properties

    // #region Map Properties

    @ViewChild(MapContainerComponent)
    private mapContainer;

    // #endregion Map Properties

    // #region Saved Filter Properties

    public createSavedFilterForm = new FormGroup({
        name: new FormControl('', Validators.required),
    });

    public savedFilters: UserPortfolioFilterModel[] = [];
    public userBusinessUnit: BusinessUnitModel;

    // #endregion Saved Filter Properties

    private outcomeMetrics: OutcomeMetricModel[];

    // #region Initialization

    public ngOnInit(): void {
        this.searchInitiatedSubscription = this.algoliaApiService.searchInitiated.subscribe(() => {
            this.isAlgoliaSearching = true;
        });

        this.searchCompletedSubscription = this.algoliaApiService.searchCompleted.subscribe(() => {
            this.isAlgoliaSearching = false;
        });

        const title = 'Hub - Search Portfolio';
        this.titleService.setTitle(title);
        this.usageService.logHubUsage('Search Portfolio', 'n/a', 'n/a', title);

        this.portfolioViewModel = new PortfolioViewModel();

        this.portfolioService.getScaOrganizationalMetrics().subscribe((metrics) => {
            this.outcomeMetrics = metrics;
            this.initializeFilters();

            this.userDataService.getPortfolioSavedFilters().pipe(take(1)).subscribe((savedFilters: UserPortfolioFilterModel[]) => {
                this.savedFilters = savedFilters;

                this.route.queryParams.subscribe((params) => {
                    if (params['savedFilterI'] !== undefined && this.savedFilters && this.savedFilters.length) {
                        this.loadSavedFilter(this.savedFilters[+params['savedFilterI']]);
                        this.router.navigateByUrl('/portfolio');
                    }
                });

            });

            this.algoliaApiService.initialize(this.algoliaKeyService.indexVocabularyKeys.portfolioSearch, () => {
                if (LocalStorageService.getItem('lastAppliedPortfolioFilter')) {
                    this.loadSavedFilter({ uri: LocalStorageService.getItem('lastAppliedPortfolioFilter') });
                }
                else {
                    this.loadDefaultFilters();
                }

            });
        });

        this.loadViewPreferences();
        this.setupTypeAhead();

        const userId = this.userDataService.getUserId();

        this.employeeService.getEmployeeDetails(userId).pipe(take(1)).subscribe((emp: UserModel) => {
            this.userBusinessUnit = emp.businessUnit!;
        });
    }

    public hitsTrackByFunction(index: number, hit) {
        return hit.objectID;
    }

    public savedFiltersTrackByFunction(index: number, savedFilter) {
        return savedFilter.portfolioSearchFilterUserId;
    }

    @HostListener('window:beforeunload', ['$event'])
    public onLeavePortfolio(event?: any) {
        const encodedJsonString = encodeURI(this.portfolioViewModel.stringify());
        LocalStorageService.setItem('lastAppliedPortfolioFilter', encodedJsonString);
    }

    private loadViewPreferences(): void {
        if (LocalStorageService.getItem(ViewModeKeys.showCardDetails) !== null) {
            this.showCardDetails = LocalStorageService.getItem(ViewModeKeys.showCardDetails) === 'true';
        }
        if (LocalStorageService.getItem(ViewModeKeys.showFinanceDetails) !== null) {
            this.showFinanceDetails = LocalStorageService.getItem(ViewModeKeys.showFinanceDetails) === 'true';
        }
        if (LocalStorageService.getItem(ViewModeKeys.showListView) !== null) {
            this.showListView = LocalStorageService.getItem(ViewModeKeys.showListView) === 'true';
        }
        if (LocalStorageService.getItem(ViewModeKeys.viewMode) !== null) {
            this.viewMode = LocalStorageService.getItem(ViewModeKeys.viewMode) as ViewModes;
        }
        if (LocalStorageService.getItem('showFilterDetails') !== null) {
            this.showFilterDetails = LocalStorageService.getItem('showFilterDetails') === 'true';
        }
    }

    private initializeFilters(): void {
        // Basic filters
        this.portfolioViewModel.facets.push(new Facet('basic', 'leadBusinessUnit', 'Lead Business Unit', 'checkbox', this.getFacetExpandedState('leadBusinessUnit', true), this.getFacetCondensedState('leadBusinessUnit', true)));
        this.portfolioViewModel.facets.push(new Facet('basic', 'divisions', 'Division', 'checkbox', this.getFacetExpandedState('divisions', true), this.getFacetCondensedState('divisions', true)));
        this.portfolioViewModel.facets.push(new Facet('basic', 'regions', 'Region', 'checkbox', this.getFacetExpandedState('regions', true), this.getFacetCondensedState('regions', true)));
        this.portfolioViewModel.facets.push(new Facet('basic', 'countries', 'Country', 'checkbox', this.getFacetExpandedState('countries', true), this.getFacetCondensedState('countries', true)));
        this.portfolioViewModel.facets.push(new Facet('basic', 'recordType', 'Record Types', 'checkbox', this.getFacetExpandedState('recordType', true), this.getFacetCondensedState('recordType', true)));
        this.portfolioViewModel.facets.push(new Facet('basic', 'status', 'Record Status', 'checkbox', this.getFacetExpandedState('status', true), this.getFacetCondensedState('status', true)));

        // Advanced Filters
        this.portfolioViewModel.facets.push(new Facet('advanced', 'hasScaOutcomes', 'Has 2030 Goals Outcomes', 'checkbox', this.getFacetExpandedState('hasScaOutcomes', true), false, true));
        this.portfolioViewModel.facets.push(new Facet('advanced', 'hasIntermediateResults', 'Has Intermediate Results', 'checkbox', this.getFacetExpandedState('hasIntermediateResults', true), false, true));
        this.portfolioViewModel.facets.push(new Facet('advanced', 'intermediateResultLeverageIndicators', 'Intermediate Result Leverage Indicator', 'checkbox', this.getFacetExpandedState('intermediateResultLeverageIndicators', true), false, false));
        this.portfolioViewModel.facets.push(new Facet('advanced', 'filesDocumentTypes', 'Associated Files', 'checkbox', this.getFacetExpandedState('filesDocumentTypes', true), false, false));
        this.portfolioViewModel.facets.push(new Facet('advanced', 'hasOutputs', 'Has Outputs', 'checkbox', this.getFacetExpandedState('hasOutputs', true), false, true));
        this.portfolioViewModel.facets.push(new Outcome2030GoalsMetricFacet(this.getFacetExpandedState('organizationalScaMetrics.lvl1', true), this.outcomeMetrics));

        // last edited filter requires custom logic because it doesn't 'fit' the normal list type refinements.
        const lastEditedFacet = new LastModifiedDateFacet('advanced', 'Last Edited', this.getFacetExpandedState('modifiedOnTimestamp', true));
        lastEditedFacet.selection = null;

        const last30DaysFilter = new RadioButtonFilter();
        last30DaysFilter.title = 'Within last 30 days';
        last30DaysFilter.isChecked = false;
        lastEditedFacet.filters.push(last30DaysFilter);

        const last3MonthsFilter = new RadioButtonFilter();
        last3MonthsFilter.title = 'Within last 3 months';
        last3MonthsFilter.isChecked = false;
        lastEditedFacet.filters.push(last3MonthsFilter);

        const last6MonthsFilter = new RadioButtonFilter();
        last6MonthsFilter.title = 'Within last 6 months';
        last6MonthsFilter.isChecked = false;
        lastEditedFacet.filters.push(last6MonthsFilter);

        const lastYearFilter = new RadioButtonFilter();
        lastYearFilter.title = 'Within last year';
        lastYearFilter.isChecked = false;
        lastEditedFacet.filters.push(lastYearFilter);

        this.portfolioViewModel.facets.push(lastEditedFacet);
    }

    // #endregion

    // #region Instance Methods

    // #region Changing View Modes

    public toggleFilterDetails() {
        this.showFilterDetails = !this.showFilterDetails;
        LocalStorageService.setItem('showFilterDetails', this.showCardDetails);
        LocalStorageService.setItem('showFilterDetails', this.showFilterDetails);
    }

    public toggleViewModeKeys(key: ViewModeKeys) {
        if (key === ViewModeKeys.showFinanceDetails && !this[ViewModeKeys.showFinanceDetails] && !this[ViewModeKeys.showCardDetails]) {
            this.toggleViewModeKeys(ViewModeKeys.showCardDetails);
        }
        this[key] = !this[key];
        LocalStorageService.setItem(key, this[key]);
    }

    public setViewMode(newViewMode: ViewModes): void {
        this.viewMode = newViewMode;
        LocalStorageService.setItem(ViewModeKeys.viewMode, this.viewMode);

        if (this.viewMode !== ViewModes.cards) {
            // keeps user from switching back to cards while the map is still loading.
            this.areViewButtonsDisabled = true;
        }

        this.getAlgoliaData(this.portfolioViewModel.facets).pipe(take(1)).subscribe();
    }

    public mapLoaded(): void {
        this.areViewButtonsDisabled = false;
    }

    // #endregion Changing View Modes

    // #region Paging

    private readonly hitsPerPage: number = 15;
    public hits: any[];
    public hitPager: HitPager = new HitPager();

    public goToPrevSet(e: Event): void {
        const prevGroupIndex = this.hitPager.currentGroupIndex - 1;

        if (this.isPrevSetDisabled()) {
            return;
        }

        this.hitPager.currentGroupIndex = prevGroupIndex;
        this.hitPager.currentPageIndex = this.hitPager.currentGroupIndex * this.hitPager.pagesPerGroup;
        this.updateCurrentPage();
        e.preventDefault();
        e.stopPropagation();
    }

    public isPrevSetDisabled() {
        const prevGroupIndex = this.hitPager.currentGroupIndex - 1;
        return prevGroupIndex < 0;
    }

    public isNextSetDisabled() {
        if (!this.hitPager.pages) {
            return true;
        }
        const nextGroupIndex = this.hitPager.currentGroupIndex + 1;
        return (nextGroupIndex * this.hitPager.pagesPerGroup) >= this.hitPager.pages.length;
    }

    public goToNextSet(e: Event): void {
        const nextGroupIndex = this.hitPager.currentGroupIndex + 1;
        if (this.isNextSetDisabled()) {
            return;
        }
        this.hitPager.currentGroupIndex = nextGroupIndex;
        this.hitPager.currentPageIndex = this.hitPager.currentGroupIndex * this.hitPager.pagesPerGroup;
        this.updateCurrentPage();
        e.preventDefault();
        e.stopPropagation();
    }

    public goToPage(page: number, e: Event) {
        this.hitPager.currentPageIndex = page;
        this.updateCurrentPage();
        e.preventDefault();
        e.stopPropagation();
    }

    public getGroupsArray(): number[] {
        if (!this.hitPager.pages) {
            return [];
        }
        return this.hitPager.pages.slice(this.hitPager.currentGroupIndex * this.hitPager.pagesPerGroup,
            (this.hitPager.currentGroupIndex * this.hitPager.pagesPerGroup + this.hitPager.pagesPerGroup));
    }

    private updateCurrentPage() {
        const searchTerm = (this.searchTextInputLink.nativeElement.value);
        this.getAlgoliaData(this.portfolioViewModel.facets, this.hitPager.currentPageIndex).pipe(take(1)).subscribe();
    }

    // #endregion Paging

    // #region Searches

    // Todo (ACE 4/11/2022): let's use a Reactive Form, and subscribe to valueChanges instead.
    private setupTypeAhead() {
        this.typeAheadSubscription = fromEvent(document.getElementById('searchTextInput'), 'keyup')
            .pipe(
                debounceTime(200),
                map((e: any) => e.target.value),
                distinctUntilChanged(),
                switchMap((searchTerm) => this.getAlgoliaData()),
            ).subscribe();
    }

    public filterChanged(facet: Facet, filter: Filter): void {
        if (facet instanceof LastModifiedDateFacet) {
            this.portfolioViewModel.removeAllBreadcrumbsForFacet(facet);
            const selectedFilter = facet.filters.find(f => f.title === (facet as LastModifiedDateFacet).selection);
            const breadcrumb = new Breadcrumb(facet, selectedFilter!);

            if (filter) {
                if ((filter as RadioButtonFilter).isChecked) {
                    this.portfolioViewModel.addBreadcrumb(breadcrumb);
                }
                else {
                    this.portfolioViewModel.removeBreadcrumb(breadcrumb);
                }
            }
            else {
                this.portfolioViewModel.addBreadcrumb(breadcrumb);
            }
        }
        else if (facet instanceof Outcome2030GoalsMetricFacet) {
            const breadcrumb = new Breadcrumb(facet, filter);

            if ((filter as any).isChecked) {
                this.portfolioViewModel.addBreadcrumb(breadcrumb);
            }
            else {
                this.portfolioViewModel.removeBreadcrumb(breadcrumb);
            }
        }
        else if (filter instanceof CheckboxFilter) {
            const breadcrumb = new Breadcrumb(facet, filter);
            const checkboxFilter = filter as CheckboxFilter;

            if (checkboxFilter.isChecked) {
                this.portfolioViewModel.addBreadcrumb(breadcrumb);
            }
            else {
                this.portfolioViewModel.removeBreadcrumb(breadcrumb);
            }
        }

        this.getAlgoliaData([facet]).pipe(take(1)).subscribe();
    }

    public organizationalMetricFilterChanged(breadcrumb: Breadcrumb) {
        this.filterChanged(breadcrumb.facet, breadcrumb.filter);
    }

    public removeBreadcrumb(breadcrumb: Breadcrumb): void {
        if (breadcrumb.facet instanceof LastModifiedDateFacet) {
            this.portfolioViewModel.removeAllBreadcrumbsForFacet(breadcrumb.facet);
            (breadcrumb.filter as RadioButtonFilter).isChecked = false;
            breadcrumb.facet.selection = null;
        }
        else if (breadcrumb.filter instanceof CheckboxFilter) {
            (breadcrumb.filter as CheckboxFilter).isChecked = false;
            this.portfolioViewModel.removeBreadcrumb(breadcrumb);
        }
        else {
            // Outcome Metric filter
            const filter = breadcrumb.facet.filters.find(f => f.title === (breadcrumb.filter as any).subArea.name + ' > ' + (breadcrumb.filter as any).name);
            (filter as CheckboxFilter).isChecked = false;
            this.portfolioViewModel.removeBreadcrumb(breadcrumb);
            (breadcrumb.facet as Outcome2030GoalsMetricFacet).clearMetric(breadcrumb.filter);
        }
        this.getAlgoliaData(this.searchTextInputLink.nativeElement.value).pipe(take(1)).subscribe();
    }

    private getAlgoliaData(facetsToPreserve: Facet[] | null = null, page: number = 0): Observable<any> {
        const request: any = {
            searchTerm: this.portfolioViewModel.searchText,
            parameters: {
                facets: this.portfolioViewModel.getFacetNames(),
            }
        };

        if (this.viewMode === ViewModes.cards) {
            request.parameters.hitsPerPage = 15;
            request.parameters.page = page;
        }
        else {
            const wholeEarth = [
                -90, // p1Lat
                -180, // p1Lng
                90, // p2Lat
                180 // p2Lng
            ];

            request.parameters.insideBoundingBox = [wholeEarth];
        }

        const filterString = this.portfolioViewModel.getFilterString();

        if (filterString) {
            request.parameters.filters = filterString;
        }

        return this.algoliaApiService.searchAlgolia(this.algoliaKeyService.indexVocabularyKeys.portfolioSearch, request).pipe(
            tap((result: any) => {
                this.searchResultStats.update(result);
                this.hits = result.hits;
                this.hitPager.update(this.searchResultStats);
                this.portfolioViewModel.synchronize(result.facets, facetsToPreserve);
                this.assignEditPermissions();

                if (this.viewMode === ViewModes.map) {
                    setTimeout(() => {
                        this.mapContainer.refreshMarkers();
                    });
                }
            })
        );
    }

    // #endregion Searches

    // #region Edit Authorization

    private assignEditPermissions(hitOffset: number = 0) {
        for (let i = 0 + hitOffset; i < this.hits.length; i++) {
            const hit = this.hits[i];
            hit.isAllowedToEdit = this.algoliaAuthorizationService.isAllowedToEdit(hit);
        }
    }

    // #endregion Edit Authorization

    // #region Filters

    public getHierarchicalTitle(hierarchicalTitle) {
        return hierarchicalTitle.split('>')[1].trim();
    }

    private getFacetExpandedState(facetKey: string, defaultState: boolean): boolean {
        const localStorageKey = `facet_${facetKey}_expanded`;
        const localStorageValue = LocalStorageService.getItem(localStorageKey);

        if (localStorageValue != null) {
            return localStorageValue === 'true';
        }
        else {
            LocalStorageService.setItem(localStorageKey, defaultState);
            return defaultState;
        }
    }

    private getFacetCondensedState(facetKey: string, defaultState: boolean): boolean {
        const localStorageKey = `facet_${facetKey}_condensed`;
        const localStorageValue = LocalStorageService.getItem(localStorageKey);

        if (localStorageValue != null) {
            return localStorageValue === 'true';
        }
        else {
            LocalStorageService.setItem(localStorageKey, defaultState);
            return defaultState;
        }
    }

    public filterPanelClosed(facet) {
        const localStorageKey = `facet_${facet.key}_expanded`;
        LocalStorageService.setItem(localStorageKey, false);
    }

    public filterPanelOpened(facet) {
        const localStorageKey = `facet_${facet.key}_expanded`;
        LocalStorageService.setItem(localStorageKey, true);
    }

    public facetShowMore(facet) {
        facet.isCondensed = false;
        const localStorageKey = `facet_${facet.key}_condensed`;
        LocalStorageService.setItem(localStorageKey, false);
    }

    public facetShowLess(facet) {
        facet.isCondensed = true;
        const localStorageKey = `facet_${facet.key}_condensed`;
        LocalStorageService.setItem(localStorageKey, true);
    }

    public clearSearchText() {
        this.portfolioViewModel.clearSearchText();
        this.getAlgoliaData().pipe(take(1)).subscribe();
        const searchElement = (this.searchTextInputLink.nativeElement as HTMLElement);
        searchElement.focus();
    }

    public clearFilters() {
        this.portfolioViewModel.clearAllFilters();
        LocalStorageService.removeItem('lastAppliedPortfolioFilter');
        this.getAlgoliaData().pipe(take(1)).subscribe();
    }

    public selectAllFacetFilters(facet: Facet, e: Event): void {
        facet.selectAll();
        e.stopPropagation();
        this.getAlgoliaData(this.portfolioViewModel.facets).pipe(take(1)).subscribe();

        for (let filter of facet.filters) {
            const breadcrumb = new Breadcrumb(facet, filter);
            this.portfolioViewModel.addBreadcrumb(breadcrumb);
        }
    }

    public clearAllFacetFilters(facet: Facet, e: Event): void {
        facet.clearAll();
        e.stopPropagation();
        this.getAlgoliaData(this.portfolioViewModel.facets).pipe(take(1)).subscribe();
        this.portfolioViewModel.removeAllBreadcrumbsForFacet(facet);
    }

    public saveCurrentFilters(): void {
        const dialogRef = this.dialogService.open(SaveFiltersDialogComponent, {
            width: '500px',
            data: this.portfolioViewModel
        });

        dialogRef.afterClosed().pipe(take(1)).subscribe((savedFilter) => {
            if (savedFilter) {
                this.savedFilters.push(savedFilter);
            }
        });
    }

    loadSavedFilter(savedFilter: any) {
        const encodedUri = savedFilter.uri;
        const jsonText = decodeURI(encodedUri);
        const savedFilterConfig = JSON.parse(jsonText);
        this.portfolioViewModel.searchText = savedFilterConfig.searchText;

        // we have to get the data before we can populate filters
        this.getAlgoliaData().pipe(take(1)).subscribe(() => {
            this.portfolioViewModel.loadSavedFilter(savedFilterConfig);
            this.getAlgoliaData(this.portfolioViewModel.facets).pipe(take(1)).subscribe();
        });

        this.showingYourFiltersPanel = false;
    }

    private loadDefaultFilters(): void {
        const defaultConfig = {
            facets: [
                {
                    key: 'status',
                    activeFilters: [
                        { title: 'Active' },
                        { title: 'Proposed' },
                    ],
                },
            ],
        };
        this.portfolioViewModel.loadSavedFilter(defaultConfig);
        this.getAlgoliaData(this.portfolioViewModel.facets).pipe(take(1)).subscribe();
    }

    public deletePortfolioFilter(savedFilter: UserPortfolioFilterModel, e: Event) {
        this.userDataService.deleteSavedPortfolioFilter(savedFilter.userPortfolioFilterId!).pipe(take(1)).subscribe({
            next: () => {
                const indexToRemove = this.savedFilters.findIndex(f => f.userPortfolioFilterId === savedFilter.userPortfolioFilterId );
                this.savedFilters.splice(indexToRemove, 1);
            },
            error: (err) => {
                this.errorService.addError(err, true);
            }
        });
        e.stopPropagation();
    }

    // #region pre-built filters

    public showingYourFiltersPanel: boolean = false;

    @ViewChild("savedFilterDropdown")
    public savedFilterDropdown: ElementRef;

    public toggleYourFiltersPanel(e: Event): void {
        this.showingYourFiltersPanel = !this.showingYourFiltersPanel;
        e.stopPropagation();
    }

    @HostListener('document:click', ['$event'])
    private userClickedOutsideOfPanel(event) {
        if (!this.savedFilterDropdown) {
            // this event can be raised when switching to the Portfolio, and before it is fully initialized
            return;
        }
        if (!this.savedFilterDropdown.nativeElement.contains(event.target) && this.showingYourFiltersPanel) {
            this.showingYourFiltersPanel = false;
        }
    }

    public filterForMyWork(e: Event): void {
        this.portfolioViewModel.clearAllFilters();
        this.portfolioViewModel.searchText = this.userDataService.getUserFullName();
        this.getAlgoliaData(this.portfolioViewModel.facets).pipe(take(1)).subscribe();
        this.showingYourFiltersPanel = false;
        e.preventDefault();
    }

    public isFilterForMyBuDisabled(): boolean {
        if (!this.userBusinessUnit) {
            return true;
        }
        const leadBuFacet = this.portfolioViewModel.facets.find((f) => f.key === 'leadBusinessUnit');
        const myBuFilter = leadBuFacet.filters.find((f) => f.title === this.userBusinessUnit.name);
        return !myBuFilter;
    }

    public filterForMyBU(e: Event): void {
        this.portfolioViewModel.clearAllFilters();
        const leadBuFacet = this.portfolioViewModel.facets.find((f) => f.key === 'leadBusinessUnit');
        const myBuFilter = leadBuFacet.filters.find((f) => f.title === this.userBusinessUnit.name);

        if (myBuFilter) {
            myBuFilter.makeActive();
        }

        const breadcrumb = new Breadcrumb(leadBuFacet, myBuFilter);
        this.portfolioViewModel.addBreadcrumb(breadcrumb);
        this.getAlgoliaData(this.portfolioViewModel.facets).pipe(take(1)).subscribe();
        this.showingYourFiltersPanel = false;
        e.preventDefault();
    }

    public filterForActivePriorityStrategies(e: Event): void {
        this.portfolioViewModel.clearAllFilters();

        const recordTypeFacet = this.portfolioViewModel.facets.find((f) => f.key === 'recordType');
        recordTypeFacet.filters.find((f) => f.title === 'Strategy').makeActive();

        const statusFacet = this.portfolioViewModel.facets.find((f) => f.key === 'status');
        statusFacet.filters.find((f) => f.title === 'Active').makeActive();

        this.getAlgoliaData().pipe(take(1)).subscribe();

        this.showingYourFiltersPanel = false;
        e.preventDefault();
    }

    // #endregion pre-built filters

    // #endregion Filters

    ngOnDestroy() {
        if (this.typeAheadSubscription) {
            this.typeAheadSubscription.unsubscribe();
        }

        if (this.searchInitiatedSubscription) {
            this.searchInitiatedSubscription.unsubscribe();
        }

        if (this.searchCompletedSubscription) {
            this.searchCompletedSubscription.unsubscribe();
        }
    }

    // #endregion Instance Methods
}
