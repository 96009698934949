<div class="hub-header-panel" [ngClass]="{'show-banner': showBanner}">
    <div class="banner" [ngClass]="{'show-banner': showBanner}">
        <div class="non-production-warning-panel">
            <img src="/assets/images/header/StopSign.SVG" />
            <a class="non-production-warning" href="https://hub.tnc.org/" target="_blank">
                Data entered anywhere on this test site will not be permanently saved.
                Please go to <span style="text-decoration: underline;">hub.tnc.org</span> to access our
                production site.
            </a>
        </div>
    </div>

    <div class="hub-header" [ngClass]="{'collapsed-sidebar': isSidebarCollapsed}" >
        <div class="logo-panel accesibility-focus" tabindex="0">
            <a (click)="goHome()" class="logo-link">
                <img src="assets/images/hub_logo_2025.png" />
            </a>
        </div>

        <div class="header-content-panel">

            <div class="left-side">

                <button class="menu-button" mat-button [matMenuTriggerFor]="menu">
                    <span class="material-icons">menu</span>
                </button>

                <div class="sidebar-toggle-button"
                    [matTooltip]="isSidebarExpanded ? 'Collapse navbar' : 'Expand navbar'" matTooltipPosition="right" matTooltipShowDelay="500" matTooltipHideDelay="200" mat-button>
                    <span *ngIf="isSidebarExpanded" (click)="toggleSidebarExpanded()" class="material-icons">chevron_left</span>
                    <span *ngIf="!isSidebarExpanded" (click)="toggleSidebarExpanded()" class="material-icons">chevron_right</span>
                </div>

                <mat-menu #menu="matMenu">

                    <a mat-menu-item routerLink="/">
                        <img class="hub-icon" src="/assets/images/icons/Home.svg" />
                        <span class="link-title" style="margin: 0 10px;">Home</span>
                    </a>

                    <a mat-menu-item routerLink="/user">
                        <img class="hub-icon" src="/assets/images/icons/Work.svg" />
                        <span class="link-title" style="margin: 0 10px;">Your Work</span>
                    </a>

                    <a mat-menu-item routerLink="/portfolio">
                        <img class="hub-icon" src="/assets/images/icons/Portfolio.svg" />
                        <span class="link-title" style="margin: 0 10px;">Search Portfolio</span>
                    </a>

                    <a mat-menu-item href="#" (click)="requestHubRecordCreateDialog($event)">
                        <img class="hub-icon" src="/assets/images/icons/Create.svg" />
                        <span class="link-title" style="margin: 0 10px;">Create new Record</span>
                    </a>

                    <a mat-menu-item routerLink="/collections">
                        <img class="hub-icon" src="/assets/images/icons/Collections.svg" />
                        <span class="link-title" style="margin: 0 10px;">Collections</span>
                    </a>

                    <a mat-menu-item routerLink="/dashboards">
                        <img class="hub-icon" src="/assets/images/icons/Dashboard.svg" />
                        <span class="link-title" style="margin: 0 10px;">Dashboards</span>
                    </a>

                    <a mat-menu-item routerLink="/administration" *ngIf="shouldAdminMenuShow()">
                        <img class="hub-icon" src="/assets/images/icons/Admin.svg" />
                        <span class="link-title" style="margin: 0 10px;">Administration</span>
                    </a>
                </mat-menu>

                <div *ngIf="isLoggedIn()" class="greeting">
                    Hello, {{firstName}}
                </div>

            </div>

            <div class="right-side">

                <div class="nav-item">
                    <div class="notifications-button" (click)="showNotifications()" *ngIf="isLoggedIn()">
                        <mat-icon>notifications</mat-icon>
                        <div class="notification-button-label">Notifications</div>
                        <div class="new-notification-indicator" *ngIf="hasNotifications"></div>
                    </div>
                    
                </div>

                <div class="nav-item">
                    <a href="https://thenatureconservancy462.sharepoint.com/sites/Conservation/SitePages/The-Hub.aspx" target="_blank" class="navbar-nav-link accesibility-focus"
                        matTooltip="Access the Hub help pages" matTooltipShowDelay="500">
                        <img class="hub-icon" src="/assets/images/icons/help_circle.svg" />
                        <span class="link-title">Get Hub Help</span>
                    </a>
                </div>
                <div class="nav-item">
                    <a *ngIf="isLoggedIn()"  tabindex="0" class="navbar-nav-link accesibility-focus" (click)="logout()" matTooltip="Logout"
                        matTooltipShowDelay="500">
                        <img class="hub-icon" src="/assets/images/icons/logout.svg" />
                        <span class="link-title">Logout</span>
                    </a>
                    <a *ngIf="!isLoggedIn()"  tabindex="0" class="navbar-nav-link accesibility-focus" routerLink="/" matTooltip="Login"
                        matTooltipShowDelay="500">
                        <img class="hub-icon" src="/assets/images/icons/logout.svg" />
                        <span class="link-title">Login</span>
                    </a>

                </div>

                <div class="nav-item">
                    <a class="navbar-nav-link"  tabindex="0" *ngIf="errorService.isErrorIconVisible"
                        (click)="errorService.showAllErrors()" matTooltip="Show errors">
                        <i class="icon-warning">
                        </i>
                        <span>{{errorService.errors.length}}</span>
                    </a>
                </div>

            </div>
        </div>
    </div>
</div>

