import { Component, Input } from '@angular/core';
import { UserDataService } from '../../../core/services/user-data.service';
import { TeamService } from '../../../core/services/team-service';
import { ProjectModel, TeamMemberModel, UserBusinessRoleModel, UserModel } from '../../../../hub_schema/hubTypes';
import { AvataredUserModel } from '../../../../hub_schema/model-extensions/avatared-user-model';

@Component({
    selector: 'app-team-container',
    templateUrl: './team-container.component.html',
    styleUrls: ['./team-container.component.scss'],
})
export class TeamContainerComponent {

    constructor(
        public userDataService: UserDataService,
        private teamService: TeamService) { }

    private _project: ProjectModel;

    public get project(): ProjectModel {
        return this._project;
    }

    public team: TeamMemberModel[];

    @Input()
    public set project(project: ProjectModel) {
        this._project = project;

        if (project) {
            this.team = this.project.team!
                .map((teamMember: TeamMemberModel) => {
                    (teamMember.user as AvataredUserModel)!.avatarObservable = this.getPicture(teamMember.user!.email);
                    teamMember.userBusinessRoles = teamMember.userBusinessRoles.sort((ubr1: UserBusinessRoleModel, ubr2: UserBusinessRoleModel) => {
                        return ubr1.businessRole!.displayOrder - ubr2.businessRole!.displayOrder;
                    });
                    return teamMember;
                })
                .sort((t1: TeamMemberModel, t2: TeamMemberModel) => {
                    const team1RoleDisplayOrders = t1.userBusinessRoles!.map(ubr => ubr.businessRole!.displayOrder);
                    const minBusinessRole1 = Math.min(...team1RoleDisplayOrders);

                    const team2RoleDisplayOrders = t2.userBusinessRoles!.map(ubr => ubr.businessRole!.displayOrder);
                    const minBusinessRole2 = Math.min(...team2RoleDisplayOrders);

                    return minBusinessRole1 - minBusinessRole2;
                });
        }
    }

    public getPicture(userEmail: string) {
        return this.userDataService.getUserProfilePicture(userEmail, null);
    }
}
