import { EventEmitter } from '@angular/core';
import { Outcome2030GoalsMetricFacet } from '../../../portfolio/types/outcome-metric-facet';
import { Facet } from './facet';
import { CheckboxFilter } from './filter-checkbox';
import { LastModifiedDateFacet } from './last-modified-date-facet';

export class AlgoliaApiViewModel {
    private _searchText: string = '';

    public get searchText(): string {
        return this._searchText;
    }

    public set searchText(value: string) {
        this._searchText = value;
        // TODO: setup a type ahead here in the vm
    }

    public facets: Facet[];

    public get visibleFacets(): Facet[] {
        return this.facets.filter((f) => !f.isInvisible);
    }

    constructor() {
        this.facets = [];
    }

    public facetTrackByFunction(facet: Facet): string {
        return facet.key;
    }

    public clearSearchText(): void {
        this.searchText = '';
    }

    public getFacetNames(): string[] {
        return this.facets.map((f) => f.key);
    }

    public synchronize(algoliaFacets: any, facetsToPreserve: Facet[] | null = null): void {
        for (const facet of this.facets) {

            if (facet.filterType !== 'checkbox') {
                continue;
            }

            // if an entire facet is not being returned by Algolia, hide the facet.
            const algoliaFacet = algoliaFacets[facet.key];

            if (!algoliaFacet) {
                facet.isHidden = true;
            }
            else {
                facet.isHidden = false;

                // create filters for new values
                const algoliaFacetKeys: any[] = Object.keys(algoliaFacet);

                for (const key of algoliaFacetKeys) {
                    const existingFilter = facet.getFilter(key);

                    if (!existingFilter) {
                        const filter = new CheckboxFilter(this.unHideAllFilters.bind(this));
                        filter.title = key;
                        filter.numberOfHits = algoliaFacet[key];
                        filter.isChecked = false;
                        facet.filters.push(filter);
                    }
                    else {
                        existingFilter.numberOfHits = algoliaFacet[key];
                    }
                }

                // and remove filters no longer present in results (but not for the facet that we changed )
                if (!facetsToPreserve || !facetsToPreserve.find((f) => f.key === facet.key)) {
                    for (const filter of facet.filters.filter((f) => !algoliaFacetKeys.includes(f.title))) {
                        filter.numberOfHits = 0;
                    }
                }
            }
        }
    }

    public unHideAllFilters() {
        for (const facet of this.facets) {
            if (facet.filterType === 'checkbox') {
                for (const filter of facet.filters) {
                    filter.isHidden = false;
                }
            }
        }
    }

    public getFacetsWithFilters(): Facet[] {
        return this.facets.filter(
            (fac: Facet) => {
                return fac.getActiveFilters().length > 0 || (fac as LastModifiedDateFacet).selection != null;
            }
        );
    }

    public getFilterString(): string {
        const facetFilterClauses = [];
        const facetsWithFilters = this.getFacetsWithFilters();

        for (const facet of facetsWithFilters) {
            facetFilterClauses.push(facet.getFilterString());
        }

        return facetFilterClauses.join(' AND ');
    }

    public clearAllFilters() {
        this.searchText = '';

        for (const facet of this.facets) {
            facet.clearAll();
        }
    }

    public loadSavedFilter(savedFilterConfig): void {
        this.clearAllFilters();
        this.searchText = savedFilterConfig.searchText;

        for (const savedFacet of savedFilterConfig.facets) {
            const existingFacet = this.facets.find((f) => f.key === savedFacet.key);

            if (existingFacet) {
                existingFacet.loadFromSaved(savedFacet);
            }
        }
    }
}
