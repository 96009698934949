<div class="hub-portfolio-container" [ngClass]="{'map-mode': viewMode === ViewModes.map}">

    <div class="portfolio-header">

        <h4>Search Conservation Portfolio</h4>

        <div class="view-modes-panel">

            <div class="button-group">
                <app-help-selector [selector]="1159" style="margin-right: 10px;">
                </app-help-selector>

                <span *ngIf="viewMode !== ViewModes.cards" class="naked-button" (click)="setViewMode(ViewModes.cards)">
                    <i class="icon-three-bars"></i>LIST VIEW
                </span>
                <span *ngIf="viewMode !== ViewModes.map" class="naked-button" (click)="setViewMode(ViewModes.map)">
                    <i class="icon-location4"></i>MAP VIEW
                </span>
            </div>

            <div class="button-group" *ngIf="viewMode === ViewModes.cards" style="margin-left: 0.3125rem">

                <span class="separator">|</span>

                <span class="naked-button cards-button" [ngClass]="{'toggled-on': !showListView}"
                    (click)="toggleViewModeKeys(ViewModeKeys.showListView)"
                    [matTooltip]="(showListView ? 'Show' : 'Hide') +  ' Cards'" matTooltipShowDelay="500">
                    <i class="icon-grid"></i>
                </span>

                <span class="naked-button" [ngClass]="{'toggled-on': showCardDetails}"
                    (click)="toggleViewModeKeys(ViewModeKeys.showCardDetails)"
                    [matTooltip]="(showCardDetails ? 'Hide' : 'Show') +  ' Record Details'" matTooltipShowDelay="500">
                    <i class="icon-insert-template"></i>
                </span>

                <span class="naked-button" [ngClass]="{'toggled-on': showFinanceDetails}"
                    (click)="toggleViewModeKeys(ViewModeKeys.showFinanceDetails)"
                    [matTooltip]="(showFinanceDetails ? 'Hide' : 'Show') + ' Financials'" matTooltipShowDelay="500">
                    <i class="icon-coin-dollar"></i>
                </span>
            </div>

        </div>

    </div>

    <div class="search-and-paginator-area" [ngClass]="{'map-mode': viewMode === ViewModes.map}">

        <div class="search-area">

            <!-- Search Bar-->

            <div class="text-search-panel" [ngClass]="{'filters-are-present': portfolioViewModel?.breadcrumbs.length}">
                <div class="hub-search-textbox">
                    <span class="search-icon">
                        <i class="icon-search4"></i>
                    </span>

                    <input type="text" #searchTextInput id="searchTextInput" [(ngModel)]="portfolioViewModel.searchText"
                        placeholder="Search" />
                    <div [hidden]="!portfolioViewModel.searchText" (click)="clearSearchText()" class="clear-icon"
                        title="Reset">X</div>
                </div>

                <div class="clear-all-button-panel" *ngIf="portfolioViewModel?.breadcrumbs.length">
                    <a href="javascript: void(0)" (click)="clearFilters()">Clear&nbsp;Filters
                    </a>
                </div>

            </div>


            <!-- Search statistics -->
            <div class="search-result-stats"
                [ngClass]="{'filters-are-present': portfolioViewModel?.breadcrumbs.length}">
                {{searchResultStats.getInfo()}}
            </div>

        </div>

        <div class="tokenfield hub-custom-scrollbar" *ngIf="portfolioViewModel?.breadcrumbs.length">
            <div class="token" *ngFor="let breadcrumb of portfolioViewModel?.breadcrumbs">
                <span class="token-label">{{breadcrumb.title}}</span>
                <span class="close" (click)="removeBreadcrumb(breadcrumb)">x</span>
            </div>

        </div>

        <!-- Paginator -->
        <!--todo: turn this into a component-->
        <div class="paginator" *ngIf="viewMode !== ViewModes.map">
            <div class="page-item">
                <button (click)="goToPrevSet($event)" [disabled]="isPrevSetDisabled()"
                    class="page-link legitRipple minWidthNextPrev">
                    ← &nbsp; Prev Set
                </button>
            </div>
            <ul class="pagesContainer pagination align-self-end">
                <li *ngFor="let page of getGroupsArray()" class="page-item"
                    [ngClass]="{active: page === hitPager.currentPageIndex}">
                    <button (click)="goToPage(page, $event)" class="page-link legitRipple">
                        {{page + 1}}
                    </button>
                </li>
            </ul>
            <div class="page-item">
                <button (click)="goToNextSet($event)" [disabled]="isNextSetDisabled()"
                    class="page-link legitRipple minWidthNextPrev">
                    Next Set &nbsp; →
                </button>
            </div>
        </div>
    </div>

    <div class="search-results-area hub-custom-scrollbar" [ngClass]="{'map-mode': viewMode === ViewModes.map}">

        <!-- SEARCH RESULTS CARDS -->
        <div *ngIf="viewMode === ViewModes.cards" class="hub-list-panel" [ngClass]="{'list-view': showListView}">

            <!-- RECORDS LIST -->
            <div class="card-columns">

                <!-- todo (ACE 2/4/2022): again, the cards component should own its own styles.  Move them there, then clean up each component that uses project cards. -->
                <div *ngFor="let hit of hits; trackBy: hitsTrackByFunction" class="card card-record card-shadow status-{{hit.status}}">
                    <app-project-card-algolia [projectInfo]="hit" [showCardDetails]="showCardDetails"
                        [showFinanceDetails]="showFinanceDetails" [isUserCanEdit]="hit.isAllowedToEdit">
                    </app-project-card-algolia>
                </div>
            </div>

        </div>

        <!-- SEARCH RESULTS MAP -->
        <div *ngIf="viewMode === ViewModes.map" class="hub-map-panel">

            <app-map-container #mapContainer [mapType]="'portfolio'" [hits]="hits" (mapLoaded)="mapLoaded()">
            </app-map-container>
        </div>

    </div>

    <div class="hub-filter-container" *ngIf="this.portfolioViewModel?.facets.length">

        <div class="show-hide-button">
            <a href="javascript: void(0)" (click)="toggleFilterDetails()">{{showFilterDetails ? 'Hide' : 'Show'}} Filter
                Panel</a>
        </div>

        <div class="hub-filter-panel"
            [ngClass]="{'not-showing': !showFilterDetails, 'your-filters-expanded': showingYourFiltersPanel}">

            <div class="filters-header">

                <h4>Apply Filters</h4>

                <div class="saved-filters-panel">

                    <div class="your-filters-dropdown-container">

                        <button class="hub-icon-button" (click)="toggleYourFiltersPanel($event)">
                            <b>
                                <mat-icon aria-hidden="false" aria-label="Filter icon">filter_list_alt</mat-icon>
                            </b> Your Filters
                        </button>

                        <div class="your-filters-dropdown-panel" [hidden]="!showingYourFiltersPanel"
                            #savedFilterDropdown>

                            <div *ngFor="let savedFilter of savedFilters; trackBy: savedFiltersTrackByFunction"
                                (click)="loadSavedFilter(savedFilter)" class="prebaked-saved-filter">

                                <i class="icon-filter3"></i>

                                <span>{{savedFilter.name}}</span>

                                <i class="icon-trash" (click)="deletePortfolioFilter(savedFilter, $event)"></i>

                            </div>

                            <a *ngIf="!savedFilters.length" (click)="saveCurrentFilters()" class="saved-filter">
                                <i class="icon-floppy-disk"></i> Save current Filters
                            </a>

                            <hr />

                            <a href="#" (click)="filterForMyWork($event)" class="saved-filter">
                                <i class="icon-filter3"></i> Records where you are on the Team
                            </a>
                            <a href="#" (click)="filterForMyBU($event)" class="saved-filter"
                                [ngClass]="{disabled: isFilterForMyBuDisabled()}">
                                <i class="icon-filter3"></i>Records owned by BU: {{userBusinessUnit?.name}}
                            </a>
                        </div>

                    </div>

                    <button class="hub-icon-button" (click)="saveCurrentFilters()" data-popup="tooltip" title=""
                        data-placement="bottom" data-original-title="Save Current Filter as Favorite" id="SaveFilters">
                        <b>
                            <span class="material-icons-outlined">save</span>
                        </b> Save Filters
                    </button>
                </div>

            </div>


            <!--
                FILTERS
            -->
            <div class="hub-filters">
                <mat-tab-group>
                    <!--
                        BASIC FILTERS
                    -->
                    <mat-tab label="Basic">
                        <div>
                            <div
                                *ngFor="let facet of portfolioViewModel.basicFacets; trackBy: portfolioViewModel.facetTrackByFunction">

                                <mat-expansion-panel [expanded]="facet.isExpanded" (opened)="filterPanelOpened(facet)"
                                    (closed)="filterPanelClosed(facet)">

                                    <mat-expansion-panel-header collapsedHeight="48px" expandedHeight="48px">

                                        <label>{{facet.title}}</label>

                                        <div *ngIf="!facet.isHidden" class="hub-select-clear">
                                            <span *ngIf="facet.isSelectAllAvailable()"
                                                (click)="selectAllFacetFilters(facet, $event)">
                                                Select All
                                            </span>
                                            <span *ngIf="facet.areSelectAllAndClearAllAvailable()"
                                                class="hub-divider">|</span>
                                            <span *ngIf="facet.isClearAllAvailable()"
                                                (click)="clearAllFacetFilters(facet, $event)">
                                                Clear All
                                            </span>
                                        </div>
                                    </mat-expansion-panel-header>

                                    <div
                                        *ngIf="facet.filterType === 'checkbox' && !facet.isHidden && !facet.secondaryFilterType">

                                        <div class="hub-facet-filter" *ngFor="let filter of facet.getFilters()">
                                            <div class="hub-facet-filter-content" *ngIf="filter.numberOfHits">
                                                <div>
                                                    <mat-checkbox [(ngModel)]="filter.isChecked" color="primary"
                                                        (change)="filterChanged(facet, filter)"></mat-checkbox>
                                                </div>
                                                <div class="filter-title">
                                                    {{filter.title}}&nbsp;<span
                                                        class="hub-number-of-hits">{{filter.numberOfHits | number}}</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="hub-show-more" *ngIf="facet.isCondensible">
                                            <div *ngIf="facet.isCondensed" (click)="facetShowMore(facet)">
                                                <i class="material-icons">arrow_drop_down</i>
                                                <span>Show More</span>
                                            </div>

                                            <div *ngIf="!facet.isCondensed" (click)="facetShowLess(facet)">
                                                <i class="material-icons">arrow_drop_up</i>
                                                <span>Show Less</span>
                                            </div>
                                        </div>
                                    </div>
                                </mat-expansion-panel>
                            </div>
                        </div>
                    </mat-tab>

                    <!--
                        ADVANCED FILTERS
                    -->
                    <mat-tab label="Advanced">
                        <div>
                            <div *ngFor="let facet of portfolioViewModel.advancedFacets">
                                <mat-expansion-panel [expanded]="facet.isExpanded" (opened)="filterPanelOpened(facet)"
                                    (closed)="filterPanelClosed(facet)">
                                    <mat-expansion-panel-header>
                                        <label>{{facet.title}}</label>

                                        <div *ngIf="facet.filterType === 'checkbox' && !facet.isHidden"
                                            class="hub-select-clear">
                                            <span *ngIf="facet.isSelectAllAvailable()"
                                                (click)="selectAllFacetFilters(facet, $event)">
                                                Select All
                                            </span>
                                            <span *ngIf="facet.areSelectAllAndClearAllAvailable()"
                                                class="hub-divider">|</span>
                                            <span *ngIf="facet.isClearAllAvailable()"
                                                (click)="clearAllFacetFilters(facet, $event)">
                                                Clear All
                                            </span>
                                        </div>
                                    </mat-expansion-panel-header>

                                    <!-- CHECK BOX -->
                                    <div *ngIf="facet.filterType === 'checkbox' && !facet.isHidden && facet.title.toLowerCase() !== '2030 goals'">
                                        <div class="hub-facet-filter" *ngFor="let filter of facet.getFilters()">
                                            <div class="hub-facet-filter-content" *ngIf="filter.numberOfHits">
                                                <div>
                                                    <mat-checkbox [(ngModel)]="filter.isChecked" color="primary"
                                                        (change)="filterChanged(facet, filter)">
                                                    </mat-checkbox>
                                                </div>
                                                <div class="filter-title">
                                                    {{filter.title}}&nbsp;
                                                    <span class="hub-number-of-hits">{{filter.numberOfHits | number}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- CHECK BOX && HIERARCHICAL MENU -->
                                    <app-outcome-2030-goals-metric-facet-display
                                        *ngIf="!facet.isHidden && facet.title === '2030 Goals'"
                                        [facet]="facet" (filterChanged)="organizationalMetricFilterChanged($event)">
                                    </app-outcome-2030-goals-metric-facet-display>

                                    <!-- LAST MODIFIED DATE -->
                                    <div *ngIf="facet.filterType === 'lastModifiedDate' && !facet.isHidden">
                                        <mat-radio-group [(ngModel)]="facet.selection" (change)="filterChanged(facet)">

                                            <div class="hub-facet-filter" *ngFor="let filter of facet.filters">
                                                <div class="hub-facet-filter-content">
                                                    <div>
                                                        <mat-radio-button color="primary" [value]="filter.title">
                                                        </mat-radio-button>
                                                    </div>
                                                    <div class="filter-title" style="margin-left: 2px;">
                                                        {{filter.title}}
                                                    </div>
                                                </div>
                                            </div>

                                        </mat-radio-group>
                                    </div>

                                </mat-expansion-panel>
                            </div>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>
            <!--
                /FILTERS
            -->

        </div>
    </div>
</div>

<app-modal-spinner *ngIf="isAlgoliaSearching"></app-modal-spinner>
