import { Component, Input, inject } from '@angular/core';
import { OutcomeProgressModel, OutcomeTargetModel, ProjectOutcomeModel } from '../../../../../hub_schema/hubTypes';
import { OutcomesEditService } from '../services/outcomes-edit.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { HubRecordEditorBase } from '../../_hub_record_editor_base';
import { AddEditProjectOutcomeTargetComponent } from './add-edit-project-outcome-target/add-edit-project-outcome-target.component';
import { AddEditOutcomeProgressDialogComponent } from './add-edit-outcome-progress-dialog/add-edit-outcome-progress-dialog.component';
import { DeleteConfirmationDialogComponent } from '../../../../shared/components/delete-confirmation-dialog/delete-confirmation-dialog.component';
import { take } from 'rxjs';
import { ErrorService } from '../../../../core/services/error.service';

@Component({
    selector: 'app-outcome-details',
    templateUrl: './outcome-details.component.html',
    styleUrls: ['./outcome-details.component.scss'],
})
export class OutcomeDetailsComponent extends HubRecordEditorBase {
    private outcomesEditService: OutcomesEditService = inject(OutcomesEditService);
    private errorService: ErrorService;
    private dialogService: MatDialog = inject(MatDialog);

    @Input()
    public projectOutcome: ProjectOutcomeModel;

    // #region Targets

    public openAddEditTargetDialog(target: OutcomeTargetModel | null):void {
        const dialogRef: MatDialogRef<AddEditProjectOutcomeTargetComponent> = this.dialogService.open(AddEditProjectOutcomeTargetComponent, {
            width: '800px',
            data: {
                existingTarget: target,
                projectOutcome: this.projectOutcome,
            }
        });

        dialogRef.afterClosed().pipe(take(1)).subscribe((savedTarget: OutcomeTargetModel) => {
            if (savedTarget) {
                // hack: API dates come in as stings
                savedTarget.targetDate = new Date(savedTarget.targetDate + 'T00:00:00');

                if (!target) {
                    this.projectOutcome.targets?.push(savedTarget);
                    this.projectOutcome.targets.sort((a, b) => {
                        const aDate = new Date(a.targetDate).getTime();
                        const bDate = new Date(b.targetDate).getTime();
                        return bDate - aDate;
                    });
                }
                else {
                    const index = this.projectOutcome.targets!.findIndex(t => t.outcomeTargetId === target.outcomeTargetId);
                    this.projectOutcome.targets![index] = savedTarget;
                }
            }
        });
    }

    public deleteTargetRequested(target: OutcomeTargetModel): void {
        const dialogRef: MatDialogRef<DeleteConfirmationDialogComponent> = this.dialogService.open(DeleteConfirmationDialogComponent, {
            data: {
                warningMessage: 'You will not be able to undo deleting this Target.'
            }
        });

        const deleteConfirmationDialog: DeleteConfirmationDialogComponent = dialogRef.componentInstance;

        deleteConfirmationDialog.actionConfirmed
            .pipe(take(1)).subscribe(() => {
                deleteConfirmationDialog.isBusy = true;

                this.outcomesEditService.deleteTarget(target.outcomeTargetId!).pipe(take(1))
                    .subscribe({
                        next: ()=> {
                            this.removeTarget(target);
                        },
                        error: (err) => {
                            this.errorService.addError(err, true);
                        },
                        complete: () => {
                            dialogRef.close();
                        }
                    });
            });
    }

    private removeTarget(target: OutcomeTargetModel) {
        const index = this.projectOutcome.targets!.findIndex(t => t.outcomeTargetId === target.outcomeTargetId);
        this.projectOutcome.targets!.splice(index, 1);
    }

    // #endregion

    // #region Progress

    public selectedOutcomeHasStart(): boolean {
        if (this.projectOutcome.progress) {
            return this.projectOutcome.progress!.some(p => p.isStart);
        }
        return false;
    }

    public openAddEditProgressDialog(progress: OutcomeProgressModel , isStart: boolean):void {
        const dialogRef = this.dialogService.open(AddEditOutcomeProgressDialogComponent, {
            width: '800px',
            maxHeight: '80%',
            data: {
                projectOutcome: this.projectOutcome,
                existingProgress: progress,
                isStart: isStart
            }
        });

        dialogRef.afterClosed().pipe(take(1)).subscribe((savedProgress: OutcomeProgressModel) => {
            if (savedProgress) {
                // hack: API dates come in as stings
                savedProgress.progressDate = new Date(savedProgress.progressDate + 'T00:00:00');

                if (!progress) {
                    this.projectOutcome.progress!.push(savedProgress);
                    this.projectOutcome.progress.sort((a, b) => {
                        const aDate = new Date(a.progressDate).getTime();
                        const bDate = new Date(b.progressDate).getTime();
                        return bDate - aDate;
                    });

                }
                else {
                    const index = this.projectOutcome.progress!.findIndex(t => t.outcomeProgressId === progress.outcomeProgressId);
                    this.projectOutcome.progress![index] = savedProgress;
                }
            }
        });

    }

    public openDeleteProgressDialog(progress: OutcomeProgressModel, isStartValue = false):void {

        const deletedType = isStartValue ? 'Start' : 'Progress';

        const dialogRef: MatDialogRef<DeleteConfirmationDialogComponent> = this.dialogService.open(DeleteConfirmationDialogComponent, {
            data: {
                warningMessage: `You will not be able to undo deleting this ${deletedType}.`
            }
        });

        const deleteConfirmationDialog: DeleteConfirmationDialogComponent = dialogRef.componentInstance;

        deleteConfirmationDialog.actionConfirmed
            .pipe(take(1)).subscribe(() => {
                deleteConfirmationDialog.isBusy = true;

                this.outcomesEditService.deleteProgress(progress.outcomeProgressId!).pipe(take(1))
                    .subscribe({
                        next: () => {
                            this.removeProgress(progress);
                        },
                        error: (err) => {
                            this.errorService.addError(err, true);
                        },
                        complete: () => {
                            dialogRef.close();
                        }
                    });
            });
    }

    private removeProgress(progress: OutcomeProgressModel) {
        const index = this.projectOutcome.progress!.findIndex(t => t.outcomeProgressId === progress.outcomeProgressId);
        this.projectOutcome.progress!.splice(index, 1);
    }

    // #endregion

    public areDetailsLoaded(): boolean {
        return this.projectOutcome.targets !== null && this.projectOutcome.progress !== null;
    }

    public doDetailsExist(): boolean {
        if (this.projectOutcome.targets?.length || this.projectOutcome.progress?.length) {
            return true;
        }
        return false;
    }

}
