import { Component, Input, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { AddEditIrTargetDialogComponent } from './add-edit-ir-target-dialog/add-edit-ir-target-dialog.component';
import { AddEditIrProgressDialogComponent } from './add-edit-ir-progress-dialog/add-edit-ir-progress-dialog.component';
import { DeleteConfirmationDialogComponent } from '../../../../shared/components/delete-confirmation-dialog/delete-confirmation-dialog.component';

import { IntermediateResultsEditService } from '../services/intermediate-results-edit.service';
import { IntermediateResultProgressModel, IntermediateResultTargetModel, ProjectIntermediateResultModel } from '../../../../../hub_schema/hubTypes';
import { ErrorService } from '../../../../core/services/error.service';

@Component({
  selector: 'app-intermediate-result-detail',
  templateUrl: './intermediate-result-detail.component.html',
  styleUrls: ['./intermediate-result-detail.component.scss'],
})
export class IntermediateResultDetailComponent {
  // Injected Dependencies
  private intermediateResultsEditService: IntermediateResultsEditService = inject(IntermediateResultsEditService);
  private errorService: ErrorService = inject(ErrorService);
  private dialogService: MatDialog = inject(MatDialog);

  @Input()
  public intermediateResult: ProjectIntermediateResultModel;

  @Input()
  public isStrategyIr: boolean;

  public irHasStartMetric(): boolean {
    return this.intermediateResult.irStatistics.hasProgress && this.intermediateResult.progress?.some((p) => p.isStart);
  }

  // #region Targets

  public openAddEditTargetDialog(target: IntermediateResultTargetModel | null) {
    const dialogRef = this.dialogService.open(AddEditIrTargetDialogComponent, {
      width: '800px',
      data: {
        existingTarget: target,
        intermediateResult: this.intermediateResult,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((savedTarget: IntermediateResultTargetModel) => {
        if (savedTarget) {
          // hack: API dates come in as stings
          savedTarget.targetDate = new Date(savedTarget.targetDate + 'T00:00:00');

          if (!target) {
            this.intermediateResult.targets!.push(savedTarget);
            this.intermediateResult.irStatistics.hasTargets = true;
            this.intermediateResult.irStatistics.targetCount++;
            this.intermediateResult.targets = this.intermediateResult.targets!.sort((a, b) => {
              if (a.targetDate < b.targetDate) {
                return 1;
              }
              if (a.targetDate > b.targetDate) {
                return -1;
              }
              return 0;
            });
          } else {
            // find it and replace it
            const index = this.intermediateResult.targets!.findIndex((t) => t.intermediateResultTargetId === target.intermediateResultTargetId);
            this.intermediateResult.targets![index] = savedTarget;
          }
        }
      });
  }

  public deleteIrTargetRequested(target: IntermediateResultTargetModel): void {
    const dialogRef = this.dialogService.open(DeleteConfirmationDialogComponent, {
      data: {
        warningMessage: 'You will not be able to undo deleting this target.',
      },
    });

    const deleteConfirmationDialog: DeleteConfirmationDialogComponent = dialogRef.componentInstance;

    deleteConfirmationDialog.actionConfirmed.pipe(take(1)).subscribe(() => {
      deleteConfirmationDialog.isBusy = true;

      this.intermediateResultsEditService
        .deleteIrTarget(target.intermediateResultTargetId!)
        .pipe(take(1))
        .subscribe({
          next: () => {
            this.removeTarget(target);
          },
          error: (err) => {
            this.errorService.addError(err, true);
          },
          complete: () => {
            dialogRef.close();
          },
        });
    });
  }

  private removeTarget(target: IntermediateResultTargetModel) {
    const index = this.intermediateResult.targets!.findIndex((t) => t.intermediateResultTargetId === target.intermediateResultTargetId);
    this.intermediateResult.targets!.splice(index, 1);
    this.intermediateResult.irStatistics.targetCount--;
  }

  // #endregion Targets

  // #region Progress

  public openAddEditProgressDialog(progress: IntermediateResultProgressModel | null, isStart: boolean = false) {
    const dialogRef = this.dialogService.open(AddEditIrProgressDialogComponent, {
      width: '800px',
      data: {
        existingProgress: progress,
        intermediateResult: this.intermediateResult,
        isStart: isStart,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((savedProgress: IntermediateResultProgressModel) => {
        if (savedProgress) {
          // hack: API dates come in as stings
          savedProgress.progressDate = new Date(savedProgress.progressDate + 'T00:00:00');

          if (!progress) {
            this.intermediateResult.progress!.push(savedProgress);
            this.intermediateResult.irStatistics.progressCount++;
            this.intermediateResult.progress = this.intermediateResult.progress!.sort((a, b) => {
              if (a.progressDate! < b.progressDate!) {
                return 1;
              }
              if (a.progressDate! > b.progressDate!) {
                return -1;
              }
              return 0;
            });
          } else {
            const index = this.intermediateResult.progress!.findIndex((p) => p.intermediateResultProgressId === progress.intermediateResultProgressId);
            this.intermediateResult.progress![index] = savedProgress;
          }
        }
      });
  }

  public deleteIrProgressRequested(progress: IntermediateResultProgressModel) {
    const dialogRef = this.dialogService.open(DeleteConfirmationDialogComponent, {
      data: {
        warningMessage: `You will not be able to undo deleting this ${progress.isStart ? 'start value' : 'progress'}.`,
      },
    });

    const deleteConfirmationDialog: DeleteConfirmationDialogComponent = dialogRef.componentInstance;

    deleteConfirmationDialog.actionConfirmed.pipe(take(1)).subscribe(() => {
      deleteConfirmationDialog.isBusy = true;

      this.intermediateResultsEditService
        .deleteIrProgress(progress.intermediateResultProgressId!)
        .pipe(take(1))
        .subscribe({
          next: () => {
            this.removeProgress(progress);
          },
          error: (err) => {
            this.errorService.addError(err, true);
          },
          complete: () => {
            dialogRef.close();
          },
        });
    });
  }

  private removeProgress(progress: IntermediateResultProgressModel) {
    const index = this.intermediateResult.progress!.findIndex((t) => t.intermediateResultProgressId === progress.intermediateResultProgressId);
    this.intermediateResult.progress!.splice(index, 1);
    this.intermediateResult.irStatistics!.progressCount--;
  }

  // #endregion Progress

  public areDetailsLoaded(): boolean {
    return this.intermediateResult.targets !== null && this.intermediateResult.progress !== null;
  }

  public doDetailsExist(): boolean {
    if (this.intermediateResult.targets?.length || this.intermediateResult.progress?.length) {
      return true;
    }
    return false;
  }
}
