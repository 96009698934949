<div [formGroup]="editTeamMemberForm" *ngIf="editTeamMemberForm && businessRoles" class="team-member-dialog-container">

    <div class="hub-modal-header-text">
        <h5 class="modal-title text-success">
            {{teamMember ? 'Edit Team Member' : 'Add Team Member'}}
        </h5>
    </div>

    <mat-dialog-content class="hub-custom-scrollbar">

        <div class="hub-team-member-dialog content">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <div class="dialog-label">{{teamMember ? 'Team Member' : 'Select Team Member'}}
                                <app-help-selector [selector]="1170"></app-help-selector>
                            </div>

                            <ng-container *ngIf="!teamMember; else readOnlyEmployee">

                                <div class="hub-select-container" *ngIf="!isFinanceContactRoleSelected">
                                    <mat-form-field>
                                        <input type="text" matInput [formControl]="userTypeahead"
                                            [matAutocomplete]="auto" placeholder="Type User Name">
                                    </mat-form-field>

                                    <mat-autocomplete #auto="matAutocomplete"
                                        [displayWith]="displayUser"
                                        (optionSelected)="user_clicked($event)">
                                        <mat-option *ngFor="let user of filteredUsers$ | async" [value]="user" [disabled]="isUserDisabled(user)">
                                            {{user.fullName}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </div>

                            </ng-container>

                            <ng-template #readOnlyEmployee>
                                <div>
                                    {{teamMember.user.fullName}}
                                </div>
                            </ng-template>

                            <div *ngIf="selectedUser">
                                <div>{{selectedUser.businessUnit?.name}}</div>
                                <div class="text-muted">{{selectedUser.office}}</div>
                            </div>

                            <div class="hub-validation-error-message employee-error">
                                {{getEmployeeValidationMessage()}}
                            </div>

                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <div class="dialog-label">Choose Role(s) for Team Member <app-help-selector [selector]="1169">
                                </app-help-selector>
                            </div>

                            <div class="role-list" formArrayName="selectedBusinessRoles">
                                <div *ngFor="let roleGroup of selectableBusinessRoles.controls; let i = index">
                                    <div [formGroupName]="i">
                                        <div [matTooltip]="getRoleTooltip(roleGroup.value)" matTooltipPosition="left" matTooltipShowDelay="300" matTooltipHideDelay="200" >
                                            <mat-checkbox color="primary" formControlName="isSelected">
                                                {{roleGroup.value.businessRole.name}}
                                                <span *ngIf="roleGroup.value.businessRole.isSingleUserPerProject" class="one-user-warning">(limit 1 user)</span>
                                            </mat-checkbox>
                                        </div>
                                    </div>

                                    <div class="text-warning team-member-warning">
                                        <div [innerHTML]="getRoleWarningText(roleGroup.value)"></div>
                                    </div>
                                    <div class="text-warning team-member-warning"
                                        *ngIf="roleGroup.value.businessRole.name === 'Lead' && hasAnchorRecord && isLead()">
                                        Team Lead will update the Insight Anchor Record Project Lead.
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="hub-validation-error-message roles-error">
                    {{getRolesValidationMessage()}}
                </div>

            </div>
        </div>

    </mat-dialog-content>

    <div class="hub-modal-footer-text">
        <button (click)="cancel()" class="cancel-button mr-1 legitRipple">Cancel</button>

        <button *ngIf="teamMember" (click)="submitTeamMember()" class="hub-icon-button"
            [disabled]="editTeamMemberForm.invalid || editTeamMemberForm.pristine">
            <b><i class="icon-checkmark3"></i></b>
            Done
        </button>

        <button *ngIf="!teamMember" (click)="submitTeamMember()" class="hub-icon-button"
            [disabled]="!editTeamMemberForm.valid">
            <b><i class="icon-plus-circle2"></i></b>
            Add to Team
        </button>
    </div>

</div>
