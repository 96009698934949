import { Component, EventEmitter, Input, Output } from "@angular/core";
import { RecordCollectionModel } from "../../../../../hub_schema/hubTypes";

@Component({
    selector: "app-record-collection",
    templateUrl: "./record-collection.component.html",
    styleUrls: ["./record-collection.component.scss"],
})
export class RecordCollectionComponent {
    @Input()
    public recordCollection: RecordCollectionModel;

    @Input()
    private userId: number;

    @Input()
    private isUserAdminOrITAdmin: boolean;

    @Output()
    public deleteRecordCollectionRequested: EventEmitter<RecordCollectionModel> = new EventEmitter<RecordCollectionModel>();

    public canUserDelete(): boolean {
        return this.recordCollection.recordCollectionUsers.some((rcu) => rcu.userId === this.userId) || this.isUserAdminOrITAdmin;
    }

    public deleteRecordCollectionClicked(): void {
        this.deleteRecordCollectionRequested.emit(this.recordCollection);
    }
}
