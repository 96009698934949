<div class="hub-team-content" *ngIf="projectEditForm">

    <div class="team-content-header mt-2">

        <div class="invalid-team-message-panel">
            <div class="hub-invalid-team-message" *ngIf="!isTeamValid">
                A Team Lead and Finance Contact are required.
            </div>
        </div>

        <div class="new-team-member-button-panel">
            <button type="button"
                class="btn btn-outline bg-primary-300 text-primary btn-icon rounded-round ml-2 legitRipple"
                style="max-height: 36px;" tooltip="Add a team member" data-original-title="Add a new Team Member"
                tabindex="0" (click)="openTeamMemberDialog()"> <em class="icon-plus-circle2 mr-2"></em>
                Add Team Member
        </button>
        </div>
    </div>

    <div class="hub-team-panel" @list>

        <div class="hub-team-member team-header">
            <div class="member-info" >Team Member</div>
            <div class="role-info">Role(s)</div>
        </div>

        <div class="hub-team-member" @team-members
            *ngFor="let teamMember of displayTeam; trackBy: trackByTeamMember">

            <div class="member-info">

                <a *ngIf="(teamMember.user.avatarObservable | async) === null || (teamMember.user.avatarObservable | async) === undefined"
                    [routerLink]="'/user/' + teamMember.user.userId" style="height: 35px; width: 35px"
                    class="btn btn-success legitRipple" >
                    {{teamMember.user.initials}}
                </a>

                <a *ngIf="teamMember.user.avatarObservable | async" [routerLink]="'/user/' + teamMember.user.userId"
                    class="legitRipple">
                    <img [src]="teamMember.user.avatarObservable | async" class="rounded-circle" alt="image"
                        style="height: 35px; width: 35px" />
                </a>


                <div class="team-member-info">
                    <div class="team-member-name-line">
                        <a [routerLink]="'/user/' + teamMember.user.userId" class="team-member-name"
                            [ngClass]="{'inactive': !teamMember.user.isActive}">
                            {{teamMember.user.fullName}}
                            <span *ngIf="!teamMember.user.isActive"> (Inactive)</span>
                        </a>
                    </div>
                    <div class="team-member-department">
                        {{teamMember.user.businessUnit ? teamMember.user.businessUnit.name: teamMember.user.businessUnitName}}
                    </div>
                    <div class="team-member-location">{{teamMember.user.office}}</div>
                </div>
            </div>

            <div class="role-info">
                <ul>
                    <li *ngFor="let userBusinessRole of teamMember.userBusinessRoles; trackBy: trackByBusinessRole"
                        [ngClass]="{
                            'is-lead': userBusinessRole.businessRoleId === 1000,
                            'is-hub-data-coordinator': userBusinessRole.businessRoleId === 1011,
                            'is-poc': userBusinessRole.businessRoleId === 1012
                        }">
                        {{userBusinessRole.businessRole.name}}
                    </li>
                </ul>
            </div>

            <div class="team-member-action-buttons">

                <button (click)="openTeamMemberDialog(teamMember)"
                    matTooltip="Edit Team Member"
                    matTooltipShowDelay="500"
                    matTooltipHideDelay="200" >
                    <i class="icon-pencil"></i>
                </button>

                <button (click)="removeTeamMember(teamMember)"
                    matTooltip="Remove {{teamMember.user.fullName}}"
                    matTooltipShowDelay="500"
                    matTooltipHideDelay="200">
                    <i class="icon-trash"></i>
                </button>
            </div>
        </div>

    </div>

</div>

<div *ngIf="!anyTeamPresent()" class="alert alert-info alert-styled-left shadow-0">
    There are no team members defined yet.
</div>
