import { Component, Input, inject } from '@angular/core';
import { OutputLinkModel, OutputProgressModel, ProjectOutputModel } from '../../../../../hub_schema/hubTypes';
import { MatDialog } from '@angular/material/dialog';
import { DeleteConfirmationDialogComponent } from '../../../../shared/components/delete-confirmation-dialog/delete-confirmation-dialog.component';
import { take } from 'rxjs';
import { OutputsEditService } from '../services/outputs-edit.service';
import { CreateUpdateOutputProgressDialogComponent } from './create-update-output-progress-dialog/create-update-output-progress-dialog.component';
import { CreateUpdateOutputLinkDialogComponent } from './create-update-output-link-dialog/create-update-output-link-dialog.component';

enum tabs {
    progressReports = 1,
    fileLinks
}

@Component({
  selector: 'app-output-detail',
  templateUrl: './output-detail.component.html',
  styleUrls: ['./output-detail.component.scss']
})
export class OutputDetailComponent {
    private dialogService: MatDialog = inject(MatDialog);
    private outputsEditService: OutputsEditService = inject(OutputsEditService);

    @Input()
    public projectOutput: ProjectOutputModel;

    // #region tabs
    public tabs = tabs;
    public activeTab: tabs = tabs.progressReports;

    public setTabTo(tab: tabs, e:Event): void {
        this.activeTab = tab;
        e.preventDefault();
    }

    // #endregion tabs

    public isProgressLoaded(): boolean {
        return this.projectOutput.outputProgress !== null;
    }

    public doesProgressExist(): boolean {
        if (this.projectOutput.outputProgress?.length) {
            return true;
        }
        return false;
    }

    public areLinksLoaded(): boolean {
        return this.projectOutput.outputLinks !== null;
    }

    public doLinksExist(): boolean {
        if (this.projectOutput.outputLinks?.length) {
            return true;
        }
        return false;
    }

    // #region Progress Reports

    public openCreateUpdateOutputProgressDialog(outputProgress: OutputProgressModel | null): void {
        const dialogRef = this.dialogService.open(CreateUpdateOutputProgressDialogComponent, {
            width: '800px',
            data: {
                existingProgressReport: outputProgress,
                projectOutputId: this.projectOutput.projectOutputId
            }
        });

        dialogRef.afterClosed().pipe(take(1)).subscribe((savedProgress: OutputProgressModel) => {
            if (savedProgress) {
                savedProgress.progressDate = new Date(savedProgress.progressDate + 'T00:00:00');

                if (outputProgress) {
                    const index = this.projectOutput.outputProgress!.findIndex(p => p.outputProgressId === outputProgress?.outputProgressId);
                    this.projectOutput.outputProgress![index] = savedProgress;
                }
                else {
                    this.projectOutput.outputProgress?.push(savedProgress);                }
            }
        });
    }

    public removeOutputProgress(progress: OutputProgressModel): void {
        const dialogRef = this.dialogService.open(DeleteConfirmationDialogComponent, {
            data: {
                warningMessage: 'You will not be able to undo this action.'
            }
        });

        const deleteConfirmationDialog: DeleteConfirmationDialogComponent = dialogRef.componentInstance;

        deleteConfirmationDialog.actionConfirmed
            .pipe(take(1)).subscribe(() => {
                deleteConfirmationDialog.isBusy = true;

                this.outputsEditService.deleteOutputProgress(progress.outputProgressId!).pipe(take(1)).subscribe((result) => {
                    this.projectOutput.outputProgress = this.projectOutput.outputProgress!.filter((l) => l.outputProgressId !== progress.outputProgressId);
                    dialogRef.close();
                });
            });

    }

    // #endregion Progress Reports

    // #region File Links

    public openCreateUpdateLinkDialog(outputLink: OutputLinkModel | null) {
        const dialogRef = this.dialogService.open(CreateUpdateOutputLinkDialogComponent, {
            width: '600px',
            data: {
                existingOutputLink: outputLink,
                projectOutputId: this.projectOutput.projectOutputId
            }
        });

        dialogRef.afterClosed().pipe(take(1)).subscribe((savedLink: OutputLinkModel) => {
            if (savedLink) {
                if (outputLink) {
                    const index = this.projectOutput.outputLinks!.findIndex(p => p.outputLinkId === outputLink?.outputLinkId);
                    this.projectOutput.outputLinks![index] = savedLink;
                }
                else {
                    this.projectOutput.outputLinks?.push(savedLink);                }
            }
        });

    }

    public removeOutputLink(link: OutputLinkModel): void {
        const dialogRef = this.dialogService.open(DeleteConfirmationDialogComponent, {
            data: {
                warningMessage: 'You will not be able to undo this action.'
            }
        });

        const deleteConfirmationDialog: DeleteConfirmationDialogComponent = dialogRef.componentInstance;

        deleteConfirmationDialog.actionConfirmed
            .pipe(take(1)).subscribe(() => {
                deleteConfirmationDialog.isBusy = true;

                this.outputsEditService.deleteOutputLink(link.outputLinkId!).pipe(take(1)).subscribe(() => {
                    this.projectOutput.outputLinks = this.projectOutput.outputLinks!.filter((l) => l.outputLinkId !== link.outputLinkId);
                    dialogRef.close();
                });
            });

    }

    // #endregion File Links

    public getProgressStatusClass(progressStatusName: string): string {
        const successfulStatuses = ['completed', 'on track', 'planned'];

        if (successfulStatuses.includes(progressStatusName.toLowerCase())) {
            return 'text-success';
        }

        return 'text-warning';
    }

}
