import { Component, inject, Output, EventEmitter } from '@angular/core';
import { HubRecordEditorBase } from '../../_hub_record_editor_base';
import { MatDialog } from '@angular/material/dialog';
import { ProjectPartnerModel } from '../../../../../hub_schema/hubTypes';
import { trigger, transition, style, animate } from '@angular/animations';
import { PartnerDialogComponent } from './partner-dialog/partner-dialog.component';
import { take } from 'rxjs';

@Component({
    selector: 'app-partners-panel',
    templateUrl: './partners-panel.component.html',
    styleUrls: ['./partners-panel.component.scss'],
    animations: [
        trigger('list', [
            transition(':enter', [
            ]),
        ]),
        trigger('partners', [
            transition(':enter', [
                style({ transform: 'scale(0.5)', opacity: 0 }),  // initial
                animate('1s cubic-bezier(.8, -0.6, 0.2, 1.5)',
                    style({ transform: 'scale(1)', opacity: 1 }))  // final
            ]),
            transition(':leave', [
                style({ transform: 'scale(1)', opacity: 1, height: '*' }),
                animate('1s cubic-bezier(.8, -0.6, 0.2, 1.5)',
                    style({
                        transform: 'scale(0.5)', opacity: 0,
                        height: '0px', margin: '0px'
                    }))
            ])
        ])
    ]
})
export class PartnersPanelComponent extends HubRecordEditorBase {
    // dependencies
    private dialogService: MatDialog = inject(MatDialog);

    public get projectPartners(): ProjectPartnerModel[] {
        return this.projectEditForm?.controls.projectPartners.value;
    }

    public openPartnerDialog(existingProjectPartner: ProjectPartnerModel | null): void {
        const dialogRef = this.dialogService.open(PartnerDialogComponent, {
            width: '800px',
            data: {
                projectPartner: existingProjectPartner,
                projectId: this.project.projectId
            }
        });

        dialogRef.afterClosed().pipe(take(1)).subscribe((savedPartner: ProjectPartnerModel) => {
            if (savedPartner) {

                if (existingProjectPartner) {
                    existingProjectPartner.partnerRoleId = savedPartner.partnerRoleId;
                    existingProjectPartner.partnerRole = savedPartner.partnerRole;
                    existingProjectPartner.partnerCategoryId = savedPartner.partnerCategoryId;
                    existingProjectPartner.partnerCategory = savedPartner.partnerCategory;
                    existingProjectPartner.name = savedPartner.name;
                    existingProjectPartner.contactInfo = savedPartner.contactInfo;
                    existingProjectPartner.email = savedPartner.email;
                }
                else {
                    this.projectPartners.push(savedPartner);
                }

                this.projectEditForm?.controls.projectPartners.markAsDirty();
            }
        });

    }

    public removeProjectPartner(projectPartner: ProjectPartnerModel): void {
        const indexToRemove = this.projectPartners.findIndex(p => p === projectPartner);
        this.projectPartners.splice(indexToRemove, 1);
        this.projectEditForm?.controls.projectPartners.markAsDirty();
    }

    public trackByPartner(index: number, projectPartner: ProjectPartnerModel) {
        return projectPartner.name + projectPartner.contactInfo;
    }
}
