import { Injectable, inject } from '@angular/core';
import { HttpService } from './http.service';
import { BulkEditUpdateProjectStatusModel, BulkEditUpdateUserModel } from '../../../hub_schema/hubTypes';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable()
export class BulkEditService {
    private httpService: HttpService = inject(HttpService);

    public GetAffectedBulkEditProjects(businessUnitIds: number[], roleIds: number[]): Observable<number> {
        const url = environment.endpoints.base + '/bulkEdit/affectedBulkEditProjects';
        return this.httpService.post<number>(url, { businessUnitIds, roleIds });
    }

    public GetAffectedProjectCountByUserId(businessUnitIds: number[], businessRolesIds: number[], currentSelectedUserId: number): Observable<number> {
        const url = environment.endpoints.base + '/bulkEdit/affectedProjectCountByUserId';
        return this.httpService.post<number>(url, { businessUnitIds, businessRolesIds, currentSelectedUserId });
    }

    public GetAuditBulkLog(): Observable<any[]> {
        const url = environment.endpoints.base + '/bulkEdit/getAuditBulkLogs'
        //get type model from BE
        return this.httpService.get<any[]>(url);
    }

    public addUpdateUserToBulkEditQueue(editQueue: BulkEditUpdateUserModel): Observable<boolean> {
        const url = environment.endpoints.base + '/bulkEdit/addUpdateUsersToBulkEditQueue';

        return this.httpService.post<any>(url, editQueue);
    }

    public addUpdateProjectStatusToBulkEditQueue(updateStatus: BulkEditUpdateProjectStatusModel): Observable<boolean> {
        const url = environment.endpoints.base + '/bulkEdit/addUpdateProjectStatusToBulkEditQueue';

        return this.httpService.post<any>(url, updateStatus);
    }
}
